import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SettingsPermissionsSection,
  SettingsSection,
} from '@/admin/components';
import { AppContext, ThemeContext } from '@/admin/providers';
import {
  getBlogAccessApi,
  getSpecialtiesAccessApi,
  updateBlogAccessApi,
  updateSpecialtiesAccessApi,
} from '@/admin/utils/helpers-api';
import { SectionAccess } from '@/common/types';

const isDefaultAccess = (
  defaultAccess: SectionAccess,
  currentAccess: SectionAccess
): boolean => JSON.stringify(defaultAccess) === JSON.stringify(currentAccess);

const invertValues = (data: SectionAccess): SectionAccess => {
  return {
    broker: !data.broker,
    company: !data.company,
    office: !data.office,
  };
};

export const SettingsPermissions = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(ThemeContext);

  const {
    selectedUser: { networkId },
    getAccessToken,
    setIsBlogAccessChanged,
    setIsSpecialtiesAccessChanged,
  } = useContext(AppContext);

  const isMPH = networkId === 6;

  const [defaultBlogAccess, setDefaultBlogAccess] = useState<SectionAccess>({
    broker: true,
    office: true,
    company: true,
  });

  const [blogAccess, setBlogAccess] =
    useState<SectionAccess>(defaultBlogAccess);

  const [defaultSpecialtiesAccess, setDefaultSpecialtiesAccess] =
    useState<SectionAccess>({
      broker: isMPH,
      office: isMPH,
      company: isMPH,
    });

  const [specialtiesAccess, setSpecialtiesAccess] = useState<SectionAccess>(
    defaultSpecialtiesAccess
  );

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessToken();
        const { data: blogAccess } = await getBlogAccessApi(token, networkId);
        const { data: specialtiesAccess } = await getSpecialtiesAccessApi(
          token,
          networkId
        );

        const defaultBlogAccess = invertValues(blogAccess);
        setDefaultBlogAccess(defaultBlogAccess);
        setBlogAccess(defaultBlogAccess);

        const defaultSpecialtiesAccess = invertValues(specialtiesAccess);
        setDefaultSpecialtiesAccess(defaultSpecialtiesAccess);
        setSpecialtiesAccess(defaultSpecialtiesAccess);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    setIsUpdateButtonDisabled(
      isDefaultAccess(defaultBlogAccess, blogAccess) &&
        isDefaultAccess(defaultSpecialtiesAccess, specialtiesAccess)
    );
  }, [blogAccess, specialtiesAccess]);

  const handleUpdatePermissions = async () => {
    try {
      const token = await getAccessToken();

      let blogStatus, specialtiesStatus;

      const isUserChangedBlogAccess = !isDefaultAccess(
        defaultBlogAccess,
        blogAccess
      );
      const isUserChangedSpecialtiesAccess = !isDefaultAccess(
        defaultSpecialtiesAccess,
        specialtiesAccess
      );

      if (isUserChangedBlogAccess) {
        blogStatus = await updateBlogAccessApi(token, networkId, blogAccess);

        if (blogStatus === 201) setIsBlogAccessChanged(true);
      }

      if (isUserChangedSpecialtiesAccess) {
        specialtiesStatus = await updateSpecialtiesAccessApi(
          token,
          networkId,
          specialtiesAccess
        );

        if (specialtiesStatus === 201) setIsSpecialtiesAccessChanged(true);
      }

      const isUpdateSuccessful =
        isUserChangedBlogAccess && isUserChangedSpecialtiesAccess
          ? blogStatus === 201 && specialtiesStatus === 201
          : isUserChangedBlogAccess
          ? blogStatus === 201
          : specialtiesStatus === 201;

      if (isUpdateSuccessful) {
        openSnackbar(t('accessUpdateSuccess'));
        setDefaultBlogAccess(blogAccess);
        setDefaultSpecialtiesAccess(specialtiesAccess);
        setIsUpdateButtonDisabled(true);
      } else {
        openSnackbar(t('accessUpdateFailed'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      openSnackbar(t('accessUpdateFailed'));
    }
  };

  return (
    <SettingsSection
      disabledUpdateButton={isUpdateButtonDisabled}
      isValidateButtonHidden={true}
      title={t('permissions')}
      handleUpdate={handleUpdatePermissions}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SettingsPermissionsSection
            section="blog"
            sectionAccess={blogAccess}
            setSectionAccess={setBlogAccess}
          />
        </Grid>

        <Grid item xs={6}>
          <SettingsPermissionsSection
            section="specialties"
            sectionAccess={specialtiesAccess}
            setSectionAccess={setSpecialtiesAccess}
          />
        </Grid>
      </Grid>
    </SettingsSection>
  );
};
