import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Error,
  SpecialtiesFilters,
  SpecialtiesSkeletonFilters,
  SpecialtiesSkeletonTable,
  SpecialtiesSkeletonTop,
  SpecialtiesTable,
  SpecialtiesTop,
  TableContainer,
} from '@/admin/components';
import { DEFAULT_SPECIALTIES_FILTERS } from '@/admin/consts';
import { useIsFirstRender } from '@/admin/hooks';
import { AppContext, SpecialtiesContext } from '@/admin/providers';
import { getLocalizedDate } from '@/admin/utils/helpers';
import { getSpecialtiesApi } from '@/admin/utils/helpers-api';
import {
  ISpecialtiesFilters,
  SortingOrderSpecialties,
  SpecialtiesFiltersNames,
  SpecialtyStatus,
} from '@/common/types';

export const Specialties = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    selectedUser: { userType },
    selectedUserId,
    getAccessToken,
  } = useContext(AppContext);

  const { specialties, setSpecialties } = useContext(SpecialtiesContext);
  const { isFirstRender } = useIsFirstRender();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [order, setOrder] = useState<SortingOrderSpecialties>('dateDesc');
  const [filters, setFilters] = useState<ISpecialtiesFilters>(
    DEFAULT_SPECIALTIES_FILTERS
  );

  const [noSpecialties, setNoSpecialties] = useState<boolean>(false);
  const [noSpecialtiesByFilters, setNoSpecialtiesByFilters] =
    useState<boolean>(false);

  useEffect(() => {
    fetchSpecialties();
  }, [filters, order, selectedUserId]);

  useEffect(() => {
    const isDefaultFilters =
      Object.entries(filters).toString() ===
      Object.entries(DEFAULT_SPECIALTIES_FILTERS).toString();

    if (!isLoading) {
      if (specialties && specialties.length === 0) {
        if (isDefaultFilters || noSpecialties) {
          setNoSpecialties(true);
          setNoSpecialtiesByFilters(false);
        } else {
          setNoSpecialties(false);
          setNoSpecialtiesByFilters(true);
        }
      } else {
        setNoSpecialties(false);
        setNoSpecialtiesByFilters(false);
      }
    }
  }, [filters, isLoading, specialties, selectedUserId]);

  const fetchSpecialties = async () => {
    try {
      setIsLoading(true);

      const token = await getAccessToken();

      const { data, status } = await getSpecialtiesApi(
        token,
        {
          ...filters,
          userId: selectedUserId,
          userType,
        },
        order
      );

      if (status === 200) {
        data.forEach((specialty) => {
          specialty.dateUpdated = getLocalizedDate(
            language,
            specialty.dateUpdated as number
          );
        });

        setSpecialties(data);
      } else if (status !== 404) {
        setIsError(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleFiltersChange = (
    filter: SpecialtiesFiltersNames,
    value: string
  ) => {
    setFilters({ ...filters, [filter]: value });
  };

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  return (
    <>
      {isLoading && isFirstRender ? (
        <SpecialtiesSkeletonTop />
      ) : (
        <SpecialtiesTop lang={filters.language as string} />
      )}

      <TableContainer>
        {isLoading && isFirstRender ? (
          <SpecialtiesSkeletonFilters />
        ) : (
          <SpecialtiesFilters
            language={filters.language as string}
            order={order}
            status={filters.status as SpecialtyStatus}
            handleFiltersChange={handleFiltersChange}
            setOrder={setOrder}
          />
        )}

        {isLoading ? (
          <SpecialtiesSkeletonTable />
        ) : (
          <SpecialtiesTable
            noSpecialties={noSpecialties}
            noSpecialtiesByFilters={noSpecialtiesByFilters}
            fetchSpecialties={fetchSpecialties}
          />
        )}
      </TableContainer>
    </>
  );
};
