import { IUser, IBlogsFilters } from '@/common/types';

export const DEFAULT_USER: IUser = {
  name: '',
  nameFr: '',
  networkId: 0,
  originalNetworkId: 0,
  userType: 'broker',
};

export const DEFAULT_OFFICE: IUser = {
  ...DEFAULT_USER,
  userType: 'office',
};

export const DEFAULT_COMPANY: IUser = {
  ...DEFAULT_USER,
  userType: 'company',
};

export const DEFAULT_BLOGS_FILTERS: IBlogsFilters = {
  ...DEFAULT_USER,
  blogName: '',
  language: '',
  status: '',
  userId: 0
};

export const DEFAULT_BLOGS_PAGE = 1;

export const IMAGE_FILE_TYPE = ['.jpeg', '.jpg', '.png'];

export const SNACKBAR_TITLE_MAX_LENGTH = 35;
