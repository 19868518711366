import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import { Icon } from '@/admin/components';

type EmptyStateProps = {
  title: string;
  button?: string;
  buttonVariant?: 'contained' | 'outlined' | 'text';
  icon?: string;
  iconFill?: 0 | 1;
  message?: string;
  to?: string;
  onButtonClick?: () => void;
};

export const EmptyState = ({
  button,
  buttonVariant = 'contained',
  icon = 'article',
  iconFill = 1,
  to,
  message,
  title,
  onButtonClick,
}: EmptyStateProps) => {
  const CustomButton = ({ onClick }: { onClick?: () => void }) => (
    <Button
      color="primary"
      variant={buttonVariant}
      startIcon={<Icon symbol="add" size="md" />}
      onClick={onClick}
    >
      {button}
    </Button>
  );

  return (
    <StyledEmptyState alignItems="center" spacing={3}>
      <Icon fill={iconFill} symbol={icon as string} size="xlg" />

      <Stack spacing={2}>
        <Typography variant="h3">{title}</Typography>
        {message && <Typography variant="body1">{message}</Typography>}
      </Stack>

      {button && (
        <>
          {to && (
            <Link to={to}>
              <CustomButton />
            </Link>
          )}

          {onButtonClick && <CustomButton onClick={onButtonClick} />}
        </>
      )}
    </StyledEmptyState>
  );
};
const StyledEmptyState = styled(Stack)(({ theme }) => ({
  boxSizing: 'content-box',
  margin: '0 auto',
  maxWidth: 360,
  padding: theme.spacing(4),
  textAlign: 'center',
}));
