import { ChangeEvent, FC } from 'react';

import { REACT_APP_AZURE_IMG_URL } from '@/admin/config/variables';
import { IMAGE_FILE_TYPE } from '@/admin/consts/blogs';
import { META_DESCRIPTION_MAX_LENGTH, baseLinksByThemeName, blogPath } from '@/admin/consts/common';
import {
  FacebookIcon,
  GoogleIcon,
  InvisIcon,
  MultiPretsIcon,
  MortgageAllianceIcon,
  MortgageIntelligenceIcon,
} from '@/admin/icons';
import { IDragDropTab, NetworkId, ThemeName } from '@/admin/types/common';
import {
  AnnouncementsTitle,
  Env,
  ISlugData,
  Lang,
  AgentType,
} from '@/common/types';

export const env = (process.env.REACT_APP_ENVIRONMENT || 'development') as Env;

export const getNetworkId = (networkId: number): number => {
  switch (networkId) {
    case NetworkId.MortgageAllianceCompanyOfCanada:
    case NetworkId.MortgageAllianceFranchising:
    case NetworkId.MeridianFinancialServicesRevenue:
    case NetworkId.MeridianFinancialServicesFlatFee:
      // there is no reason why this network id is returned. just a random number to unite all of them
      return NetworkId.MortgageAllianceCompanyOfCanada;

    case NetworkId.MultiPretsMortgages:
      return NetworkId.MultiPretsMortgages;

    case NetworkId.MortgageIntelligence:
      return NetworkId.MortgageIntelligence;

    case NetworkId.Invis:
      return NetworkId.Invis;
    default:
      return 0;
  }
};

export const getBrand = (networkId: number): ThemeName => {
  switch (networkId) {
    case NetworkId.MortgageAllianceCompanyOfCanada:
    case NetworkId.MortgageAllianceFranchising:
    case NetworkId.MeridianFinancialServicesRevenue:
    case NetworkId.MeridianFinancialServicesFlatFee:
      return 'mortgagealliance';

    case NetworkId.MultiPretsMortgages:
      return 'multi-prets';

    case NetworkId.MortgageIntelligence:
      return 'mortgageintelligence';

    case NetworkId.Invis:
      return 'invis';

    default:
      return 'multi-prets';
  }
};

export const getFileType = (fileName: string) =>
  fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

export const getShortenedText = (
  value: string,
  length: number,
  ellipsis?: boolean
): string => {
  return value?.length < length
    ? value
    : `${value?.slice(0, length - 1)}${ellipsis && '\u2026'}`;
};

export const getSubString = (string1: string, string2: string) =>
  string1.split(string2)[1];

export const getDescriptionText = (value: string): string => {
  const description =
    value
      .match(/<p\b[^>]*>(.*?)<\/p>/g) // Find <p> tags
      ?.map((text) => text.replace(/(<([^>]+)>)/gi, '')) // Remove all tags in the text
      .filter((text) => text) // Clean empty string
      .join(' ')
      .trim() ?? '';

  return getShortenedText(description, META_DESCRIPTION_MAX_LENGTH, true);
};

export const getLocalizedDate = (
  lang: string,
  date?: string | number
): string => {
  const localeCA = `${lang}-CA`;
  let newDate = new Date();

  if (date && typeof date === 'string')
    newDate = new Date(date.replace(/-/g, '/'));
  if (date && typeof date === 'number') newDate = new Date(date);

  return newDate.toLocaleString(localeCA, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
};

// get the displayed value depending on the locale
export const getLocalizedValue = (
  lang: string | undefined,
  valueEn: string = '',
  valueFr: string = '',
  prefix: string = ''
) => {
  // the order is important
  if (lang === 'en' && valueEn) return `${prefix}${valueEn}`;
  if (lang === 'fr' && valueFr) return `${prefix}${valueFr}`;

  if (lang === 'fr' && valueEn) return `${prefix}${valueEn}`;
  if (lang === 'en' && valueFr) return `${prefix}${valueFr}`;

  return '';
};

export const getSlugURL = (
  language: string,
  networkId: number,
  userType: string,
  siteName: string,
  siteNameFr: string,
  subPath: string
): string | undefined => {
  const brand = getBrand(networkId);
  const lang = language as Lang;

  const type =
    userType === 'company'
      ? brand === 'multi-prets'
        ? 'team'
        : 'franchise'
      : userType;

  const url = `${baseLinksByThemeName[env][brand][lang]}${
    blogPath[type as AgentType][lang]
  }${getUrlSiteName(
    brand,
    getLocalizedValue(lang, siteName, siteNameFr)
  )}/${subPath}/`;

  return url;
};

export const getIncrementedString = (text: string): string => {
  if (/\d+$/.test(text)) {
    //regex will check if we have any amount of numbers at the end of the string
    // increment number on the end of string
    const newSlug = text.replace(/\d+$/, (num) => {
      let number = Number(num);
      ++number;
      return number.toString();
    });
    return newSlug;
  }

  // add -1 to the end on slug
  return `${text}-1`;
};

export const isSlugUnique = (
  slugsData: ISlugData[],
  slug: string,
  id: string | undefined
): boolean =>
  slugsData
    .filter((data) => data.slug === slug)
    .every((data) => data.id === id);

export const getSlugText = (text: string) => {
  //normalize removes accents and ligatures
  const normalized = getNormalizedString(text);
  const slug = normalized
    .toLowerCase()
    // only accept letters, digits and dash
    .replace(/[^a-z0-9 -]*/g, '')
    .trim()
    // clean multiple spaces
    .replace(/\s\s+/g, ' ')
    // change space to dash
    .replace(/\s+/g, '-')
    // clean multiple dashes
    .replace(/--+/g, '-');

  return slug;
};

export const getNormalizedString = (text: string) => {
  //ligatures with their corresponding letters. 'œ' becomes 'oe'
  const string = text
    .replace(/[ø]/g, 'o')
    .replace(/[Ø]/g, 'O')
    .replace(/[æ]/g, 'ae')
    .replace(/[Æ]/g, 'AE')
    .replace(/[ə]/g, 'e')
    .replace(/[Œ]/g, 'OE')
    .replace(/[œ]/g, 'oe')
    .replace(/[ß]/g, 'ss')
    .replace(/[§]/g, 's')
    .replace(/[łı]/g, 'l')
    .replace(/[đ]/g, 'd')
    .replace(/[Đ]/g, 'D')
    .replace(/[þ]/g, 'p');

  //accented letters are normalized and returned without accents
  return string.normalize('NFKD').replace(/[^\w\s-.-_]/g, '');
};

export const getUniqueStringsFromArray = (arr: string[]): string[] =>
  Array.from(new Set(arr));

export const getUrlSiteName = (
  host: string | undefined,
  siteName: string | undefined | string[]
) => {
  const newSiteName = typeof siteName === 'string' ? siteName : '';
  if (host?.includes('multi-prets')) {
    return newSiteName.replace(
      /^mortgage-broker-|courtier-hypothecaire-|office-|bureau-|team-|equipe-/i,
      ''
    );
  }
  if (host?.match(/mortgagealliance|invis|mortgageintelligence/)) {
    return siteName;
  }
  if (!host?.match(/mortgagealliance|invis|mortgageintelligence|multi-prets/)) {
    return siteName;
  }
};

export const brandIcons: Record<ThemeName, FC> = {
  invis: InvisIcon,
  mortgagealliance: MortgageAllianceIcon,
  mortgageintelligence: MortgageIntelligenceIcon,
  'multi-prets': MultiPretsIcon,
};

// export current cdn URL
export const img_url =
  REACT_APP_AZURE_IMG_URL ||
  'https://brokerwebsitesimage.azureedge.net/m3brokerimages1/next';

export const isNumber = (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  string: string
) => {
  if (/^[0-9+()-]*$/.test(string)) return true;
  else e.preventDefault();
};

export const langByLangId: Record<number, Lang> = { 1: 'en', 2: 'fr' };

export const reorderDragDropTabs = (
  list: IDragDropTab[],
  startIndex: number,
  endIndex: number
) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

export const extractImgSrcFromDom = (dom: Document) =>
  Array.from(dom.getElementsByTagName('img')).map((img) => img.src);

export const parseDomFromString = (htmlString: string) => {
  const parser = new DOMParser();
  return parser.parseFromString(htmlString, 'text/html');
};

export const sourceIcons: Record<string, FC> = {
  facebook: FacebookIcon,
  google: GoogleIcon,
};

export const urlToObject = async (imageURL: string) => {
  const response = await fetch(imageURL);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: blob.type });
  return file;
};

export const verifyImageFileSize = (fileSize: number): boolean => {
  const MAX_FILE_SIZE = 25600; // 25MB
  const fileSizeKiloBytes = fileSize / 1024;
  return fileSizeKiloBytes <= MAX_FILE_SIZE;
};

export const verifyImageFileType = (fileName: string) => {
  const fileType = getFileType(fileName);
  return IMAGE_FILE_TYPE.find((type) => type === fileType);
};
export const getItemFromLocalStorage = (
  key: string,
  isObj?: boolean
): unknown =>
  isObj
    ? JSON.parse(localStorage.getItem(key) as string)
    : localStorage.getItem(key);

export const getItemFromSessionStorage = (
  key: string,
  isObj?: boolean
): unknown =>
  isObj
    ? JSON.parse(sessionStorage.getItem(key) as string)
    : sessionStorage.getItem(key);

export const removeItemFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const removeItemFromSessionStorage = (key: string) =>
  sessionStorage.removeItem(key);

export const setItemToLocalStorage = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  isObj?: boolean
) => localStorage.setItem(key, isObj ? JSON.stringify(value) : value);

export const setItemToSessionStorage = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  isObj?: boolean
) => sessionStorage.setItem(key, isObj ? JSON.stringify(value) : value);

export const defaultBlogsAccessByBrand: Record<ThemeName, boolean> = {
  invis: true,
  mortgagealliance: true,
  mortgageintelligence: true,
  'multi-prets': false,
};

export const getAnnouncementKey = (langId: number, index: number) =>
  `${langId === 0 ? 'en' : 'fr'}${index}` as AnnouncementsTitle;

export const isDomainName = (str: string) => {
  // Regular expression for validating a domain name
  const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Test the string against the regular expression
  return domainPattern.test(str);
};
