import { styled } from '@mui/material';
import {
  KeyboardEvent,
  RefObject,
  useEffect,
  useState,
  forwardRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarBlockFormatDropdownItem } from '@/admin/components/editor/editorPlugins';
import { BLOCKTYPE_TO_BLOCKNAME } from '@/admin/consts/editor';

type Ref = HTMLDivElement;
type ToolbarBlockFormatDropDownProps = {
  blockType: keyof typeof BLOCKTYPE_TO_BLOCKNAME;
  formatType: (headingSize: keyof typeof BLOCKTYPE_TO_BLOCKNAME) => void;
  onClose: () => void;
};

export const ToolbarBlockFormatDropdown = forwardRef<
  Ref,
  ToolbarBlockFormatDropDownProps
>(({ blockType, formatType, onClose }, ref) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<RefObject<HTMLButtonElement>[]>();
  const [highlightedItem, setHighlightedItem] =
    useState<RefObject<HTMLButtonElement>>();

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0]);
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus();
    }
  }, [items, highlightedItem]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!items) return;

    const key = event.key;

    if (['Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
      event.preventDefault();
    }

    if (key === 'Escape' || key === 'Tab') {
      onClose();
    } else if (key === 'ArrowUp') {
      setHighlightedItem((prev) => {
        if (!prev) return items[0];
        const index = items.indexOf(prev) - 1;
        return items[index === -1 ? items.length - 1 : index];
      });
    } else if (key === 'ArrowDown') {
      setHighlightedItem((prev) => {
        if (!prev) return items[0];
        return items[items.indexOf(prev) + 1];
      });
    }
  };

  const registerItem = useCallback(
    (itemRef: RefObject<HTMLButtonElement>) => {
      setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]));
    },
    [setItems]
  );

  return (
    <StyledDropdown ref={ref} onKeyDown={handleKeyDown}>
      {Object.keys(BLOCKTYPE_TO_BLOCKNAME).map((type) => {
        const textType = type as keyof typeof BLOCKTYPE_TO_BLOCKNAME;
        return (
          <ToolbarBlockFormatDropdownItem
            key={textType}
            buttonText={t(BLOCKTYPE_TO_BLOCKNAME[textType])}
            icon={`format_${textType}`}
            textType={textType}
            onClick={formatType}
            registerItem={registerItem}
            className={blockType === textType ? 'active' : ''}
          />
        );
      })}
    </StyledDropdown>
  );
});

ToolbarBlockFormatDropdown.displayName = 'ToolbarBlockFormatDropdown';

const StyledDropdown = styled('div')(({ theme }) => ({
  zIndex: 10,
  display: 'block',
  position: 'fixed',
  boxShadow:
    '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
  borderRadius: '8px',
  minHeight: '40px',
  backgroundColor: theme.palette.common.white,
}));
