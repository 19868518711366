import { TextareaAutosize, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { META_TITLE_MAX_LENGTH } from '@/admin/consts';
import { getShortenedText, getSlugText } from '@/admin/utils/helpers';
import { IBlogData } from '@/common/types';

export const BlogContentHeaderBlogName = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IBlogData>();

  const handleBlogNameFocus = () => {
    const isSEODefault = getValues('isSEODefault');

    // seting default values for slug and title depending on blogName
    // once the user changed the value directly, it won't change anymore
    if (isSEODefault) {
      const blogName = getValues('blogName');
      const newSlug = getSlugText(blogName);

      setValue('slug', newSlug, { shouldDirty: true });
      setValue(
        'title',
        getShortenedText(blogName, META_TITLE_MAX_LENGTH, true)
      );
    }
  };

  return (
    <Controller
      name="blogName"
      render={({ field: { onChange, ...field } }) => (
        <TextField
          className="new-article-title"
          error={!!errors.blogName}
          helperText={errors.blogName?.message}
          id="blogName"
          placeholder={t('untitledBlog')}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: { maxRows: 2 },
          }}
          onChange={(e) => {
            const value = e.target.value.replace(/(\r\n|\n|\r)/gm, '');
            onChange(value);
            setValue('blogNameLowerCased', value.toLowerCase());
            handleBlogNameFocus();
          }}
          {...field}
        />
      )}
    />
  );
};
