import {
  ANNOUNCEMENTS_PATH,
  BLOGS_PATH,
  DASHBOARD_PATH,
  PRIVACY_POLICIES_PATH,
  TESTIMONIALS_PATH,
} from '@/admin/consts';
import { INavLink } from '@/admin/types/common';

export const navLinks: INavLink[] = [
  { id: 'dashboard', icon: 'home', title: 'dashboard', to: DASHBOARD_PATH },
  { id: 'blogs', icon: 'article', title: 'blogs', to: BLOGS_PATH },
  {
    id: 'testimonials',
    icon: 'reviews',
    title: 'testimonials',
    to: TESTIMONIALS_PATH,
  },
  {
    id: 'announcements',
    icon: 'campaign',
    title: 'announcements',
    to: ANNOUNCEMENTS_PATH,
  },
  {
    id: 'privacyPolicies',
    icon: 'shield',
    title: 'privacyPolicies',
    to: PRIVACY_POLICIES_PATH,
  },
];

export const navSubLinks: INavLink[] = [
  { id: 'homepage', title: 'homepage', to: 'homepage' },
  { id: 'specialties', title: 'mortgageServices', to: 'mortgageServices' },
];
