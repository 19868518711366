import { ArrowBackIosNew } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Stack,
  Toolbar,
  Box,
  styled,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PrivacyPoliciesContext } from '@/admin/providers';

type HeaderProps = {
  title: string;
  children: ReactNode;
  languageTab?: boolean;
  to?: string;
};

export const Header = ({ children, languageTab, title, to }: HeaderProps) => {
  const { t } = useTranslation();
  const { tabLanguage, setTabLanguage } = useContext(PrivacyPoliciesContext);

  const handleChange = (e: SyntheticEvent, value: number) => {
    setTabLanguage(value);
  };

  return (
    <StyledBox>
      <StyledAppBar position="static">
        <StyledToolbar>
          {to && (
            <Button startIcon={<ArrowBackIosNew />} component={Link} to={to}>
              {title}
            </Button>
          )}

          {!to && <Typography variant="h1">{title}</Typography>}

          {languageTab && (
            <StyledTabs value={tabLanguage} onChange={handleChange} centered>
              <StyledTab disableRipple label={t('english')} />
              <StyledTab disableRipple label={t('french')} />
            </StyledTabs>
          )}

          <Stack spacing={2} direction="row">
            {children}
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: `0px 2px 4px ${theme.palette.shadow?.dark}`,
  minWidth: '800px',
  position: 'sticky',
  top: 0,
  zIndex: 10,
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 0,
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.text.primary,
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  boxShadow: 'none',
  margin: theme.spacing(2, 0, -2, 0),
}));

const StyledTab = styled(Tab)(() => ({
  borderBottom: 'none',
  boxShadow: 'none',
  fontSize: '12px',

  '&.Mui-selected': {
    boxShadow: 'none',
    borderBottom: 'none',
  },
}));
