import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
} from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Dialog } from '@/admin/components';
import { useModal } from '@/admin/hooks';
import {
  AppContext,
  ThemeContext,
  useStorageBlobCleanContext,
} from '@/admin/providers';
import { IAction } from '@/admin/types/common';
import {
  deleteSpecialtyApi,
  updateSpecialtyApi,
} from '@/admin/utils/helpers-api';
import { ISpecialtyValues } from '@/common/types';

type SpecialtiesTableActionsProps = {
  specialty: ISpecialtyValues;
  fetchSpecialties: () => void;
};

export const SpecialtiesTableActions = ({
  specialty,
  fetchSpecialties,
}: SpecialtiesTableActionsProps) => {
  const { t } = useTranslation();
  const { modalContent, showModal, handleModalClose } = useModal();
  const { removeAllImageSrc } = useStorageBlobCleanContext();
  const { openSnackbar } = useContext(ThemeContext);

  const {
    userInfo: { name },
    getAccessToken,
  } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const onPublishClick = () => {
    showModal({
      message: t('publishSpecialtyMessage'),
      title: t('publishDialogTitle'),
      handleAgree: publishSpecialty,
      handleClose: closeDialog,
    });
  };

  const publishSpecialty = async () => {
    try {
      const updatedData: ISpecialtyValues = {
        ...specialty,
        status: 'published',
        updatedBy: name,
      };
      
      const token = await getAccessToken();
      const { status } = await updateSpecialtyApi(updatedData, token);

      if (status === 200) {
        handleSuccessAction(t('specialtyPublishSnackbarMessage'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onDeleteClick = () => {
    showModal({
      message: t('deleteSpecialtyDialogMessage'),
      title: t('deleteDialogTitle'),
      handleAgree: deleteSpecialty,
      handleClose: closeDialog,
    });
  };

  const deleteSpecialty = async () => {
    try {
      const token = await getAccessToken();
      const status = await deleteSpecialtyApi(specialty.id as string, token);

      if (status === 200) {
        removeAllImageSrc(token);
        handleSuccessAction(t('specialtySnackbarDeleteMessage'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const closeDialog = () => {
    handleModalClose();
    closePopover();
  };

  const handleSuccessAction = (snackbarMessage: string) => {
    fetchSpecialties();
    closeDialog();
    openSnackbar(snackbarMessage);
  };

  const actions: IAction[] = [
    { id: 'edit', title: t('edit'), to: specialty.id },
    { id: 'delete', title: t('delete'), onClick: onDeleteClick },
  ];

  if (specialty.status === 'draft') {
    actions.splice(1, 0, {
      id: 'publish',
      title: t('publish'),
      onClick: onPublishClick,
    });
  }

  return (
    <>
      <div>
        <IconButton onClick={openPopover}>
          <MoreHorizIcon />
        </IconButton>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <StyledList>
            {actions.map(({ id, title, to, onClick }) => (
              <ListItem disablePadding key={id}>
                {/* link */}
                {to && (
                  <ListItemButton>
                    <ListItemText>
                      <Link to={to}>{title}</Link>
                    </ListItemText>
                  </ListItemButton>
                )}

                {/* button */}
                {!to && (
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={title} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </StyledList>
        </Popover>
      </div>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />
    </>
  );
};

const StyledList = styled(List)({ width: 120 });
