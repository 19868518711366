import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StatusChip } from '@/admin/components';
import { Status } from '@/common/types';

type SidebarDetailsProps = {
  dateCreated: string;
  dateUpdated: string | null;
  lastSaved: string | null;
  status: Status;
  title: string;
  updatedBy: string;
};

export const SidebarDetails = ({
  dateCreated,
  dateUpdated,
  lastSaved,
  status,
  title,
  updatedBy,
}: SidebarDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack spacing={3}>
        <Typography variant="h4">{title}</Typography>

        <div>
          <Typography variant="caption">{`${t('dateCreated')}:`}</Typography>
          <Typography>{dateCreated}</Typography>
        </div>

        <div>
          <Typography variant="caption">{`${t('lastSaved')}:`}</Typography>
          <Typography>
            {lastSaved || t('unsaved')}
            {dateUpdated && updatedBy && ` ${t('by')} ${updatedBy}`}
          </Typography>
        </div>

        <Stack alignItems="flex-start" spacing={1}>
          <Typography variant="caption">{`${t('status')}:`}</Typography>
          <StatusChip status={status} />
        </Stack>
      </Stack>
    </Box>
  );
};
