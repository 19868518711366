import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Link,
  Pagination,
  SpecialtiesTableActions,
  StatusChip,
  TableEmptyState,
} from '@/admin/components';
import { ADD_PATH, ACTIONS_COLUMN_WIDTH } from '@/admin/consts';
import { SpecialtiesContext } from '@/admin/providers';
import { ISpecialtiesTableColumn } from '@/admin/types/common';

const columns: ISpecialtiesTableColumn[] = [
  { id: 'header', label: 'mortgageServices' },
  { id: 'status', label: 'status' },
  { id: 'dateUpdated', label: 'lastModified' },
  { id: 'actions', label: 'actions' },
];

type SpecialtiesTableProps = {
  noSpecialties: boolean;
  noSpecialtiesByFilters: boolean;
  fetchSpecialties: () => void;
};

export const SpecialtiesTable = ({
  noSpecialties,
  noSpecialtiesByFilters,
  fetchSpecialties,
}: SpecialtiesTableProps) => {
  const { t } = useTranslation();
  const { currentPage, currentSpecialties, pagesCount, setCurrentPage } =
    useContext(SpecialtiesContext);

  const handleChangePage = (e: unknown, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCellHead key={column.id}>
                  {t(column.label)}
                </StyledTableCellHead>
              ))}
            </TableRow>
          </TableHead>

          {noSpecialties && (
            <TableEmptyState
              linkText={t('addSpecialty')}
              linkTo={ADD_PATH}
              message={t('noSpecialtiesToReviewMessage')}
              title={t('noSpecialtiesToReview')}
            />
          )}

          {noSpecialtiesByFilters && (
            <TableEmptyState
              message={t('pleaseTryAnotherSearchTerm')}
              title={t('noSpecialtiesToReview')}
            />
          )}

          {!noSpecialties && !noSpecialtiesByFilters && (
            <TableBody>
              {currentSpecialties.map((specialty, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(({ id }) => {
                    if (id === 'header') {
                      return (
                        <TableCell key={id}>
                          <Link
                            className="max-two-lines-text"
                            to={specialty.id}
                          >
                            {specialty[id]}
                          </Link>
                        </TableCell>
                      );
                    }

                    if (id === 'status') {
                      return (
                        <TableCell key={id}>
                          <StatusChip status={specialty.status} />
                        </TableCell>
                      );
                    }

                    if (id === 'actions') {
                      return (
                        <StyledTableCell key={id}>
                          <SpecialtiesTableActions
                            specialty={specialty}
                            fetchSpecialties={fetchSpecialties}
                          />
                        </StyledTableCell>
                      );
                    }

                    return (
                      <TableCell key={id}>
                        <span>{specialty[id]}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {pagesCount > 1 && (
        <StyledPagination>
          <Pagination
            page={currentPage}
            count={pagesCount}
            handleChangePage={handleChangePage}
          />
        </StyledPagination>
      )}
    </>
  );
};

const StyledTableCellHead = styled(TableCell)({
  width: `expression( 100% - ${ACTIONS_COLUMN_WIDTH}px) / 3)`, // 4 = columns.length - 1

  '&:last-child': { width: `${ACTIONS_COLUMN_WIDTH}px` },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
}));

const StyledPagination = styled('div')(({ theme }) => ({
  alignSelf: 'flex-end',
  paddingTop: theme.spacing(2),
}));
