import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  Icon,
  ReadOnlyTextField,
  SettingsSection,
  TextField,
} from '@/admin/components';
import {
  REACT_APP_WEBAPP_ID,
  REACT_APP_WEBAPP_IP,
  REACT_APP_WEBAPP_URL
} from '@/admin/config/variables';
import { useModal } from '@/admin/hooks';
import { AppContext, ThemeContext } from '@/admin/providers';
import { isDomainName } from '@/admin/utils/helpers';
import {
  checkDomainApi,
  createDomainApi,
  deleteDomainApi,
  getDomainApi,
  updateDomainApi,
} from '@/admin/utils/helpers-api';
import { IDomainData, IDomainValues } from '@/common/types';

type RuleProps = {
  subdomain: string
}

const DisplayRules = ({subdomain}: RuleProps) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="secondary">{t('type')}</TableCell>
            <TableCell className="secondary">{t('host')}</TableCell>
            <TableCell className="secondary">{t('value')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow role="checkbox" tabIndex={-1}>
            <TableCell className="secondary">{subdomain ? 'CNAME' : 'A'}</TableCell>
            <TableCell className="secondary">{subdomain || '@'}</TableCell>
            <TableCell className="secondary">{subdomain ? REACT_APP_WEBAPP_URL: REACT_APP_WEBAPP_IP }</TableCell>
          </TableRow>
          <TableRow role="checkbox" tabIndex={-1}>
            <TableCell className="secondary">TXT</TableCell>
            {subdomain ? <TableCell className="secondary">{`asuid.${subdomain}`}</TableCell> : <TableCell className="secondary">asuid</TableCell>}
            <TableCell className="secondary word-break">
              {REACT_APP_WEBAPP_ID}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getSubdomain = (url: string) => {
  try {
    const parts = url.split('.');

    if (parts.length > 2) {
      return parts.slice(0, -2).join('.'); 
    } else {
      return ''; 
    }
  } catch (error) {
    console.error('Invalid URL:', error);
    return ''; 
  }
};

export const SettingsDomain = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(ThemeContext);
  const {
    displayedUserInfo: { employeeId, siteName, siteNameFr, userType },
    isAdmin,
    selectedUserId,
    userInfo: { name },
    getAccessToken,
  } = useContext(AppContext);
  const { modalContent, showModal, handleModalClose } = useModal();

  const [domainData, setDomainData] = useState<IDomainData | null>(null);
  const [domain, setDomain] = useState<string>('');
  const [isIncorrectDomain, setIsIncorrectDomain] = useState<boolean>(false);
  const [displayRules, setDisplayRules] = useState<boolean>(false);
  const [isValidatedDomain, setIsValidatedDomain] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subdomain, setSubdomain] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessToken();
        const data = await getDomainApi(token, selectedUserId, userType);

        if (data) {
          setDomainData(data);
          setDomain(data.domain);
          setIsValidatedDomain(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  const handleUpdate = async () => {
    if (!isDomainName(domain)) {
      setIsIncorrectDomain(true);
      return;
    }

    try {
      const token = await getAccessToken();

      setIsLoading(true);
      setIsIncorrectDomain(false);

      if (domainData) {
        const values: IDomainData = {
          ...domainData,
          domain,
          siteNameLowerCased: siteName.toLowerCase(),
          updatedBy: name,
        };
        const { data, status } = await updateDomainApi(values, token);
        handleSuccessUpdate(data, status);
        setIsLoading(false);
      } else {
        const values: IDomainValues = {
          createdBy: name,
          domain,
          siteName,
          siteNameFr,
          siteNameLowerCased: siteName.toLowerCase(),
          updatedBy: name,
          userId: isAdmin ? selectedUserId : employeeId,
          userType: userType,
        };

        const { data, status } = await createDomainApi(values, token);
        handleSuccessUpdate(data, status);
        setIsLoading(false);
      }
    } catch (error) {
      openSnackbar(t('settingsFailed'));
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSuccessUpdate = (data: IDomainData, status: number) => {
    if (status === 201 || status === 200) setDomainData(data);
    handleOpenSnackBar(status);
  };

  const handleOpenDeleteDialog = () => {
    showModal({
      message: t('deleteDomainDialogMessage'),
      title: t('confirmRestoreDefault'),
      handleAgree: () => handleDelete(),
    });
  };

  const handleDelete = async () => {
    if (domainData) {
      try {
        setIsLoading(true);
        const token = await getAccessToken();
        const status = await deleteDomainApi(domainData.id, token);

        if (status === 200) {
          setDomain('');
          setDomainData(null);
          openSnackbar(t('deleteDomainSnackbarMessage'));
          setIsValidatedDomain(false);
          handleModalClose();
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);

        openSnackbar(t('settingsFailed'));
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const handleOpenSnackBar = (status: number) => {
    openSnackbar(
      status === 201 || status === 200
        ? t('settingsConfirm')
        : t('settingsFailed')
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDomain(value);
    setIsIncorrectDomain(false);
    setIsValidatedDomain(false);
    setDisplayRules(false);
  };

  const handleCheck = async () => {
    if (process.env.NODE_ENV === 'development' && domain.includes('.local')) {
      setIsValidatedDomain(true);
      openSnackbar(t('domainValidatedSuccess'));
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      if (!isDomainName(domain)) {
        setIsIncorrectDomain(true);
        setIsLoading(false);
      } else {
        setIsIncorrectDomain(false);
        const subDomain = getSubdomain(domain);
        setSubdomain(subDomain);
        const token = await getAccessToken();

        const dataRes = await checkDomainApi(domain, token);

        if (dataRes.status === 409) {
          setDisplayRules(false);
          setIsValidatedDomain(false);
          openSnackbar(t('domainExist'));
          setIsLoading(false);
        } else if (dataRes.data) {
          const { customDomainVerificationTest } = dataRes.data;

          if (customDomainVerificationTest === 'Passed') {
            setIsValidatedDomain(true);
            openSnackbar(t('domainValidatedSuccess'));
            setIsLoading(false);
          } else {
            setDisplayRules(true);
            setIsValidatedDomain(false);
            openSnackbar(t('domainValidatedFailed'));
            setIsLoading(false);
          }
        } else {
          setDisplayRules(true);
          setIsValidatedDomain(false);
          openSnackbar(t('domainValidatedFailed'));
          setIsLoading(false);
        }
      }
    } catch (error) {
      openSnackbar(t('domainValidatedError'));
      // eslint-disable-next-line no-console
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsSection
        title={t('domainDetails')}
        handleUpdate={handleUpdate}
        isUpdateButtonHidden={!isValidatedDomain}
        disabledValidateButton={
          isValidatedDomain || domainData?.domain === domain || domain === ''
        }
        isValidateButtonHidden={isValidatedDomain}
        handleValidate={handleCheck}
        isLoading={isLoading}
        disabledUpdateButton={domainData?.domain === domain || domain === ''}
        handleRestoreDomain={handleOpenDeleteDialog}
        domainData={domainData}
      >
        <Stack spacing={2}>
          {!isValidatedDomain && displayRules ? (
            <>
              <ReadOnlyTextField
                label={t('domainValidationLabel')}
                value={t('domainValidation')}
              />
              <DisplayRules subdomain={subdomain}/>
              <StyledButton onClick={() => setDisplayRules(false)}>
                <Icon size="md" symbol="close" />
              </StyledButton>
            </>
          ) : isValidatedDomain ? (
            <>
              {t('domainValidated')} <Icon size="md" symbol="check" />{' '}
            </>
          ) : (
            ''
          )}
          <TextField
            error={isIncorrectDomain}
            helperText={isIncorrectDomain ? t('incorrectDomainName') : ''}
            label={t('domain')}
            value={domain}
            onChange={handleChange}
          />
        </Stack>
      </SettingsSection>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
        isLoading={isLoading}
        disableCloseButton={isLoading}
      />
    </>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingLeft: 0,
  paddingRight: 0,
}));
