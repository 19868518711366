import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BlogInfoSeoEditModal, SidebarSeo } from '@/admin/components';
import { AppContext } from '@/admin/providers';
import {
  getIncrementedString,
  getSlugText,
  isSlugUnique,
} from '@/admin/utils/helpers';
import { getBlogSlugsApi } from '@/admin/utils/helpers-api';
import { IBlogData, ISEOData, ISlugData } from '@/common/types';

export const BlogInfoSeo = () => {
  const { t } = useTranslation();

  const {
    getAccessToken,
    displayedUserInfo: { employeeId },
  } = useContext(AppContext);
  const { getValues, setValue, watch } = useFormContext<IBlogData>();

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [slugsData, setSlugsData] = useState<ISlugData[]>([]);

  const metaDescription = watch('description');
  const slug = watch('slug');
  const metaTitle = watch('title');
  const blogId = getValues('id');

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleSaveEditModal = async ({
    slug,
    title,
    description,
  }: ISEOData) => {
    const newSlug =
      slug || getSlugText(getValues('blogName')) || t('untitledBlogSlug');
    setValue('slug', newSlug, { shouldDirty: true });
    setValue('title', title || t('untitledBlog'), { shouldDirty: true });
    setValue('description', description, { shouldDirty: true });
    setValue('isSEODefault', false);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const token = await getAccessToken();
      try {
        const slugsRes = await getBlogSlugsApi(token, employeeId);
        setSlugsData(slugsRes);
      } catch (error) {
        /*eslint-disable-next-line no-console*/
        console.error(error);
      }
    })();
  }, [employeeId]);

  useEffect(() => {
    if (!isSlugUnique(slugsData, slug, blogId)) {
      setValue('slug', getIncrementedString(slug), { shouldDirty: false });
    }
  }, [slug]);

  return (
    <SidebarSeo
      metaDescriptionLabel={t('metaDescription')}
      metaDescriptionValue={metaDescription}
      metaTitleLabel={t('metaTitle')}
      metaTitleValue={metaTitle}
      seoEditModal={
        <BlogInfoSeoEditModal
          open={isEditModalOpen}
          slugsData={slugsData}
          handleCloseEditModal={handleCloseEditModal}
          handleSaveEditModal={handleSaveEditModal}
        />
      }
      slugLabel={t('slug')}
      slugValue={slug}
      handleOpenEditModal={handleOpenEditModal}
    />
  );
};
