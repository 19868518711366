import { Box, Stack, Typography } from '@mui/material';

import { InputFile } from '@/admin/components';
import { InputFileError } from '@/admin/types/common';

type SidebarImageProps = {
  error: InputFileError | null;
  imageURL: string;
  isLoading: boolean;
  title: string;
  handleDeleteFile: () => Promise<void>;
  handleUploadFile: (files: FileList) => Promise<void>;
};

export const SidebarImage = ({
  error,
  imageURL,
  isLoading,
  title,
  handleDeleteFile,
  handleUploadFile,
}: SidebarImageProps) => (
  <Box>
    <Stack spacing={2}>
      <Typography variant="h4">{title}</Typography>

      <InputFile
        error={error}
        image={imageURL}
        isLoading={isLoading}
        handleDeleteImage={handleDeleteFile}
        handleUploadImage={handleUploadFile}
      />
    </Stack>
  </Box>
);
