import { LoadingButton } from '@mui/lab';
import { Grid, Paper, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IDomainData } from '@/common/types';

type SettingsSectionProps = {
  children: ReactNode;
  disabled?: boolean;
  disabledUpdateButton?: boolean;
  disabledValidateButton?: boolean;
  domainData?: IDomainData | null;
  isButtonHidden?: boolean;
  isLoading?: boolean;
  isUpdateButtonHidden?: boolean;
  isValidateButtonHidden?: boolean;
  title: string;
  handleUpdate: () => void;
  handleRestoreDomain?: () => void;
  handleValidate?: () => void;
};

export const SettingsSection = ({
  children,
  disabledUpdateButton,
  disabledValidateButton,
  domainData,
  isUpdateButtonHidden,
  isValidateButtonHidden,
  isLoading,
  title,
  handleValidate,
  handleRestoreDomain,
  handleUpdate,
}: SettingsSectionProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={4}>
      <StyledPaper>
        <StyledTypography variant="h3">{title}</StyledTypography>

        <div>{children}</div>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          {!isUpdateButtonHidden && (
            <Grid item>
              <StyledOutlinedButton
                disabled={disabledUpdateButton}
                loading={isLoading}
                variant={
                  handleRestoreDomain && !domainData ? 'contained' : 'outlined'
                }
                onClick={handleUpdate}
              >
                {t(handleRestoreDomain && !domainData ? 'save' : 'update')}
              </StyledOutlinedButton>
            </Grid>
          )}
          {!isValidateButtonHidden && (
            <Grid item>
              <StyledOutlinedButton
                disabled={disabledValidateButton}
                variant="outlined"
                onClick={handleValidate}
                loading={isLoading}
              >
                {t('validate')}
              </StyledOutlinedButton>
            </Grid>
          )}

          {handleRestoreDomain && (
            <Grid item>
              <StyledButton
                disabled={!domainData}
                onClick={handleRestoreDomain}
              >
                {t('restoreDefault')}
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </StyledPaper>
    </Grid>
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3, 5),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledOutlinedButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(-2),
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(1),
}));
