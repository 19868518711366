import { styled } from '@mui/material';
import { ReactNode } from 'react';

type EditorContentProps = {
  children: ReactNode;
  className: string;
};

export const EditorContent = ({ children, className }: EditorContentProps) => (
  <StyledEditorContent className={className}>{children}</StyledEditorContent>
);

const StyledEditorContent = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  border: '1px solid rgba(0, 0, 0, 0.26)',
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  borderTopStyle: 'none',
  cursor: 'text',
  display: 'block',
  position: 'relative',

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    borderTopStyle: 'none',
  },

  '&.readOnly': {
    background: 'none',
    border: 'none',
  },

  '& .editor-placeholder': {
    color: theme.palette.text.secondary,
    left: theme.spacing(3),
    position: 'absolute',
    top: theme.spacing(2),
  },
}));
