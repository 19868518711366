import { alpha, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  // TODO: investigate how to avoid sharing ThemeOptions, PaletteOptions and etc. across all repos in the monorepo
  // Issue: have to make 'shadow' optinal due to packages/websites themes use the same theme interfaces
  interface Palette {
    shadow?: Palette['primary'];
  }
  interface PaletteColor {
    lighter?: string;
    lightest?: string;
    lightAlt?: string;
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lightest?: string;
    lightAlt?: string;
    darker?: string;
  }
  interface PaletteOptions {
    shadow?: PaletteOptions['primary'];
  }
}

const baseTheme = createTheme({
  palette: {
    error: {
      dark: '#D10000',
      light: '#FFF4F4',
      lighter: '#FFCBC4',
      main: '#FF463F',
    },
    grey: {
      50: '#F0F0F0',
      100: '#F9F9F9',
      200: '#EFEFEF',
      300: '#E6E6E6',
      400: '#CCCCCC',
      500: '#D4D4D4',
      700: '#4C4F62',
    },
    primary: {
      dark: '#3C4ED9',
      light: '#DFE3FF',
      lighter: '#F8F9FF',
      main: '#475CFF',
    },
    secondary: {
      main: '#1F233C',
      light: '#2A43FF',
    },
    shadow: {
      dark: 'rgba(0, 0, 0, 0.15)',
      light: 'rgba(0, 0, 0, 0.05)',
      main: 'rgba(139, 139, 139, 0.25)',
    },
    success: {
      lighter: '#D8FFC0',
      light: '#9BC788',
      lightest: '#47AC0A1A',
      lightAlt: '#43801D4D',
      main: '#1B5E20',
    },
    text: {
      primary: '#1F233C',
      secondary: '#737373',
    },
    warning: {
      main: '#D4C646',
      lighter: '#FBF9EC',
      light: '#D6C855',
      dark: '#EDE6AC',
      darker: '#0D1022',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Archivo',
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: '40px',
    },
    h3: {
      fontFamily: 'Archivo',
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    h4: {
      fontFamily: 'Archivo',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: '16px',
      letterSpacing: '0.05em',
      lineHeight: '22px',
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.05em',
      lineHeight: '16px',
    },
    caption: {
      fontFamily: 'Archivo',
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1366,
      xl: 1920,
    },
  },
});

export const theme = createTheme(baseTheme, {
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          backgroundColor: baseTheme.palette.error.light,
          fontSize: '10px',
          fontWeight: 600,
          lineHeight: '14px',
          padding: baseTheme.spacing(0, 1),

          '&.MuiAlert-standardError': {
            color: baseTheme.palette.error.dark,
          },
          '&.MuiAlert-standardWarning': {
            color: baseTheme.palette.warning.darker,
            backgroundColor: baseTheme.palette.warning.lighter,
            borderColor: baseTheme.palette.warning.dark,
            borderRadius: '5px',
            fontWeight: 400,
            fontSize: '14px',

            '& .MuiAlert-icon': {
              color: baseTheme.palette.warning.light,
            },

            '& .MuiAlert-action': {
              paddingRight: baseTheme.spacing(1),
            },
          },

          '& .MuiAlert-icon': {
            color: baseTheme.palette.error.dark,
            marginRight: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            color: baseTheme.palette.text.secondary,

            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: baseTheme.palette.text.secondary,
              },

              '& .MuiIconButton-root': {
                transform: 'none',
              },
            },

            '&.Mui-disabled': {
              backgroundColor: baseTheme.palette.grey[50],

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: baseTheme.palette.text.secondary,
              },
            },
          },

          '& .MuiIconButton-root': {
            color: baseTheme.palette.primary.main,
          },
        },
        popper: {
          padding: baseTheme.spacing(1, 0),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      variants: [
        {
          props: { color: 'primary', variant: 'contained' },
          style: {
            boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.dark}`,
            fontSize: '16px',
            fontWeight: 400,
            height: '40px',
            lineHeight: '40px',
            padding: '0 30px',
            textTransform: 'none',

            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              backgroundColor: baseTheme.palette.grey[400],
              color: baseTheme.palette.grey[600],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'outlined' },
          style: {
            borderColor: baseTheme.palette.primary.main,
            boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.dark}`,
            fontSize: '16px',
            fontWeight: 400,
            height: '40px',
            lineHeight: '40px',
            padding: '0 30px',
            textTransform: 'none',

            '&:hover': {
              backgroundColor: baseTheme.palette.primary.lighter,
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            borderColor: baseTheme.palette.common.white,
            boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.dark}`,
            color: baseTheme.palette.common.white,
            fontSize: '16px',
            fontWeight: 400,
            height: '40px',
            lineHeight: '40px',
            padding: '0 30px',
            textTransform: 'none',

            '&:hover': {
              backgroundColor: alpha(baseTheme.palette.grey[100], 0.25),
              borderColor: baseTheme.palette.common.white,
            },

            '&:disabled': {
              backgroundColor: alpha(baseTheme.palette.grey[100], 0.25),
              borderColor: baseTheme.palette.grey[600],
              color: baseTheme.palette.grey[600],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'text' },
          style: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            padding: '0',
            textTransform: 'none',
            transition: 'all, 0.3s',

            '&:hover': {
              backgroundColor: 'transparent',
              fontWeight: 700,
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[100],
          borderColor: baseTheme.palette.grey[400],
          color: baseTheme.palette.text.primary,
          fontSize: 16,
          lineHeight: '24px',
          padding: baseTheme.spacing(0.5, 1, 0.5, 1.5),

          '&.MuiChip-outlinedSuccess': {
            backgroundColor: alpha(baseTheme.palette.success.light, 0.25),
            borderColor: baseTheme.palette.success.light,
          },

          '&.MuiChip-outlinedError': {
            backgroundColor: baseTheme.palette.error.light,
            borderColor: baseTheme.palette.error.lighter,
          },

          '&.MuiChip-outlinedInfo': {
            backgroundColor: alpha(baseTheme.palette.warning.main, 0.1),
            borderColor: alpha(baseTheme.palette.warning.main, 0.5),
          },

          '&.MuiChip-sizeSmall': {
            padding: baseTheme.spacing(0, 0, 0, 0.5),
          },
        },

        icon: {
          margin: 0,

          '&.MuiChip-iconColorDefault': {
            color: baseTheme.palette.grey[400],
          },

          '&.MuiChip-iconColorSuccess': {
            color: baseTheme.palette.success.light,
          },

          '&.MuiChip-iconColorError': {
            color: baseTheme.palette.error.lighter,
          },

          '&.MuiChip-iconColorInfo': {
            color: alpha(baseTheme.palette.warning.main, 0.5),
          },
        },

        label: { padding: baseTheme.spacing(0, 1) },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: baseTheme.palette.grey[100],
          display: 'flex',
          height: '100%',
          overflow: 'hidden',

          '& > #root': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',

            '& > .content': {
              display: 'flex',
              flexGrow: 1,
              overflow: 'hidden',

              '& > main': {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'auto',
              },
            },
          },
        },
        a: {
          color: 'inherit',
          cursor: 'pointer',
          textDecoration: 'none',
        },
        button: {
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          padding: 0,
        },
        ul: {
          listStyleType: 'none',
          margin: 0,
          padding: 0,
        },

        '& .MuiAutocomplete-popper .MuiPaper-root': {
          border: `1px solid ${baseTheme.palette.text.secondary}`,
          boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.light}`,

          '& .MuiAutocomplete-option': {
            color: baseTheme.palette.text.secondary,
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',

            '&:hover': {
              backgroundColor: baseTheme.palette.primary.lighter,
            },
            '&.Mui-focused': {
              backgroundColor: baseTheme.palette.primary.lighter,
            },
          },
        },

        '.max-two-lines-text': {
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '2',
        },

        '.new-article-title': {
          flexGrow: 1,

          '& textarea': {
            fontFamily: 'Archivo',
            fontSize: 40,
            fontWeight: 700,
            lineHeight: '44px',
            padding: baseTheme.spacing(1.1, 3),
            resize: 'none',
            wordBreak: 'break-all',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            padding: baseTheme.spacing(2, 2, 3),

            '&.MuiDialog-paperFullScreen': {
              padding: 0,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '&>:not(:first-of-type)': {
            marginLeft: baseTheme.spacing(3),
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.primary,
          fontSize: '16px',
          lineHeight: '22px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Archivo',
          fontSize: '40px',
          fontWeight: 500,
          lineHeight: '44px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          textTransform: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.secondary,
          height: '40px',

          '&:hover': {
            backgroundColor: baseTheme.palette.primary.lighter,
          },

          '&.Mui-selected': {
            backgroundColor: baseTheme.palette.primary.light,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${baseTheme.palette.text.secondary}`,
          fontFamily: 'Archivo',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '32px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.secondary,

          '&:hover': {
            backgroundColor: baseTheme.palette.primary.lighter,
          },

          '&.Mui-selected': {
            backgroundColor: 'transparent',

            '& .selected-icon': {
              display: 'block',
            },
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          // nagigation bar sub menu
          '&.navigation': {
            '& .MuiPaper-root': {
              borderColor: baseTheme.palette.grey[400],
              boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.light}`,
              marginLeft: baseTheme.spacing(1),
              width: '265px',
            },

            '& .MuiList-root': {
              padding: 0,
            },

            '& .MuiMenuItem-root': {
              padding: 0,

              '& > a': {
                borderLeft: '4px solid transparent',
                color: baseTheme.palette.text.primary,
                lineHeight: '48px',
                height: '48px',
                padding: baseTheme.spacing(0, 2, 0, 4),
                width: '100%',

                '&.active': {
                  backgroundColor: baseTheme.palette.primary.light,
                  borderColor: baseTheme.palette.primary.main,
                },
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.default,
          paddingRight: 0,

          '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.text.secondary,
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.grey[400],
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },

          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.text.secondary,
          },

          '& input::placeholder, textarea::placeholder': {
            color: baseTheme.palette.text.secondary,
            opacity: 1,
          },

          '& .MuiIconButton-root': {
            color: baseTheme.palette.primary.main,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&:not(.MuiPaginationItem-ellipsis)': {
            boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.light}`,
            fontSize: '16px',
            lineHeight: '22px',
            overflow: 'hidden',
          },

          '&.Mui-selected': {
            backgroundColor: baseTheme.palette.primary.light,
            position: 'relative',

            '&::after': {
              backgroundColor: baseTheme.palette.primary.main,
              bottom: 0,
              content: '""',
              height: '4px',
              left: 0,
              position: 'absolute',
              right: 0,
            },
          },

          '&.MuiPaginationItem-previousNext': {
            backgroundColor: baseTheme.palette.primary.main,
            color: baseTheme.palette.common.white,

            '&:disabled': {
              backgroundColor: baseTheme.palette.grey[400],
              color: baseTheme.palette.grey[600],
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${baseTheme.palette.grey[50]}`,
          boxShadow: 'none',

          '&.MuiPopover-paper': {
            border: `1px solid ${baseTheme.palette.text.secondary}`,
            boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.light}`,
            margin: baseTheme.spacing(1, 0),
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: baseTheme.palette.grey[700],
          fontSize: '16px',
          letterSpacing: '0.05em',
          lineHeight: '22px',
          padding: baseTheme.spacing(1),

          '& .MuiTooltip-arrow': {
            color: baseTheme.palette.grey[700],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flex: 1,
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: '0.05em',
          lineHeight: '22px',
          padding: baseTheme.spacing(1, 2.5),
          textTransform: 'none',
          minHeight: '40px',
          maxWidth: '100%',

          '&.Mui-selected': {
            color: baseTheme.palette.secondary.main,
            fontWeight: 600,
          },
        },
        textColorPrimary: {
          color: baseTheme.palette.secondary.main,

          '&.Mui-selected': {
            color: baseTheme.palette.secondary.main,
          },
        },
        textColorSecondary: {
          color: baseTheme.palette.common.white,

          '&.Mui-selected': {
            color: baseTheme.palette.common.white,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.dark}`,
          minHeight: '40px',
          padding: '0 !important',
        },
        indicator: {
          height: '4px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.common.white,
          borderColor: baseTheme.palette.text.secondary,
          color: baseTheme.palette.text.secondary,

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.text.secondary,
          },

          '&.Mui-disabled': {
            backgroundColor: baseTheme.palette.grey[50],

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: baseTheme.palette.text.secondary,
            },
          },
        },
        popper: {
          padding: baseTheme.spacing(1, 0),
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.secondary.main,
          boxShadow: 'none',
          fontSize: '16px',
          letterSpacing: '0.5px',
          lineHeight: '22px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            color: baseTheme.palette.primary.main,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 40,
          padding: 0,
        },
        switchBase: {
          padding: 5,
          '&.Mui-checked': {
            left: 'calc(100% - 40px)',
            transform: 'none',
          },
          // to cover the whole switch no matter of the switch size
          '& .MuiSwitch-input': {
            left: '-250px',
            width: '500px',
          },
        },
        thumb: {
          width: 30,
          height: 30,
          backgroundColor: '#4e802f',
          '.Mui-checked > .MuiSwitch-input + &': {
            backgroundColor: '#cc1d05',
          },
        },
        track: {
          color: '#737373',
          backgroundColor: '#fff',
          opacity: '1 !important',
          borderRadius: 20,
          border: `1px solid ${baseTheme.palette.grey[400]}`,
          position: 'relative',
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: '#fff !important',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${baseTheme.palette.grey[50]}`,
          borderRadius: '4px',
          overflow: 'hidden',

          '& table': {
            tableLayout: 'fixed',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: baseTheme.palette.grey[50],
          fontSize: '16px',
          lineHeight: '22px',
          letterSpacing: '0.05em',

          '&.secondary': {
            fontSize: '12px',
            lineHeight: '16px',
            paddingBottom: baseTheme.spacing(1),
            paddingTop: baseTheme.spacing(1),
          },

          '&.MuiTableCell-head': {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.palette.common.white,
            fontFamily: 'Archivo',
            fontSize: '12px',
            fontWeight: 500,
            height: '48px',
            lineHeight: '48px',
            paddingBottom: 0,
            paddingTop: 0,
            textTransform: 'uppercase',

            '& .MuiIconButton-root': {
              color: baseTheme.palette.common.white,
            },

            '&.secondary': {
              backgroundColor: baseTheme.palette.grey[200],
              color: baseTheme.palette.text.primary,
              height: '30px',
              lineHeight: '30px',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.default,

          '&:last-child .MuiTableCell-root': {
            borderColor: 'transparent',
          },

          '&.MuiTableRow-hover:hover': {
            backgroundColor: baseTheme.palette.primary.lighter,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.default,
          borderColor: `${baseTheme.palette.grey[400]} !important`,
          color: baseTheme.palette.text.primary,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: '0.05em',
          lineHeight: '22px',
          overflow: 'hidden',
          padding: baseTheme.spacing(1, 2.5),
          textTransform: 'none',

          '&:hover': {
            backgroundColor: baseTheme.palette.primary.lighter,
          },

          '&:disabled': {
            backgroundColor: 'transparent',
          },

          '&.Mui-selected': {
            backgroundColor: baseTheme.palette.primary.light,
            color: baseTheme.palette.text.primary,
            position: 'relative',

            '&::after': {
              backgroundColor: baseTheme.palette.primary.main,
              bottom: 0,
              content: '""',
              height: '4px',
              left: 0,
              position: 'absolute',
              right: 0,
            },
          },

          '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderBottomLeftRadius: 0,
          },

          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: `0px 4px 4px ${baseTheme.palette.shadow?.light}`,

          '& .MuiToggleButton-root.Mui-selected': {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
});
