import {
  AutoEmbedOption,
  EmbedConfig,
  LexicalAutoEmbedPlugin,
} from '@lexical/react/LexicalAutoEmbedPlugin';
import { styled } from '@mui/material';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import {
  VideoInsertModal,
  AutoEmbedMenu,
} from '@/admin/components/editor/editorPlugins';
import { videoEmbedConfig } from '@/admin/components/editor/editorPlugins/autoEmbedPlugin/VideoEmbedConfig';

export interface IEmbedConfig extends EmbedConfig {
  contentName: string;
  exampleUrl: string;
  icon: string;
}

export const embedConfigs = [videoEmbedConfig];

export const AutoEmbedPlugin = () => {
  const { t } = useTranslation();

  const [activeEmbedConfig, setActiveEmbedConfig] =
    useState<IEmbedConfig | null>(null);

  const getMenuOptions = (
    activeEmbedConfig: IEmbedConfig,
    embedFn: () => void,
    dismissFn: () => void
  ) => {
    return [
      new AutoEmbedOption(t('dismiss'), {
        onSelect: dismissFn,
      }),
      new AutoEmbedOption(t(`embed${activeEmbedConfig.contentName}`), {
        onSelect: embedFn,
      }),
    ];
  };

  const handleOpenEmbedModal = (embedConfig: IEmbedConfig) => {
    setActiveEmbedConfig(embedConfig);
  };

  const handleCloseEmbedModal = () => {
    setActiveEmbedConfig(null);
  };

  return (
    <>
      <LexicalAutoEmbedPlugin<IEmbedConfig>
        embedConfigs={embedConfigs}
        onOpenEmbedModalForConfig={handleOpenEmbedModal}
        getMenuOptions={getMenuOptions}
        menuRenderFn={(
          anchorElementRef,
          {
            selectedIndex,
            options,
            selectOptionAndCleanUp,
            setHighlightedIndex,
          }
        ) =>
          anchorElementRef.current
            ? createPortal(
                <StyledContainer
                  className="typeahead-popover auto-embed-menu"
                  style={{
                    marginLeft: anchorElementRef.current.style.width,
                  }}
                >
                  <AutoEmbedMenu
                    options={options}
                    selectedItemIndex={selectedIndex}
                    onOptionClick={(option: AutoEmbedOption, index: number) => {
                      setHighlightedIndex(index);
                      selectOptionAndCleanUp(option);
                    }}
                    onOptionMouseEnter={(index: number) => {
                      setHighlightedIndex(index);
                    }}
                  />
                </StyledContainer>,
                anchorElementRef.current
              )
            : null
        }
      />

      <VideoInsertModal
        embedConfig={activeEmbedConfig}
        open={activeEmbedConfig?.type === 'video'}
        onClose={handleCloseEmbedModal}
      />
    </>
  );
};

const StyledContainer = styled('div')({
  background: '#fff',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  marginTop: '25px',
  width: '150px',
});
