import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
  AppError,
  AppPlaceholder,
  AppSessionTime,
  AdminToolbar,
  Loader,
  Nav,
  Error,
} from '@/admin/components';
import { REACT_APP_AUTH0_AUDIENCE } from '@/admin/config/variables';
import { DEFAULT_USER, faviconByBrand } from '@/admin/consts';
import {
  ADD_PATH,
  ANNOUNCEMENTS_PATH,
  BLOGS_PATH,
  DASHBOARD_PATH,
  HOMEPAGE_PATH,
  PRIVACY_POLICIES_PATH,
  SETTINGS_PATH,
  SPECIALTIES_PATH,
  TESTIMONIALS_PATH,
} from '@/admin/consts/paths';
import {
  AddBlogPage,
  AnnouncementsPage,
  BlogsPage,
  DashboardPage,
  EditBlogPage,
  EditPrivacyPoliciesPage,
  HomepagePage,
  // NotFoundPage,
  PrivacyPoliciesPage,
  SettingsPage,
  SpecialtiesPage,
  AddSpecialtyPage,
  TestimonialsPage,
  EditSpecialtyPage,
} from '@/admin/pages';
import { AppProvider, ThemeProvider } from '@/admin/providers';
import { DEFAULT_USER_INFO } from '@/admin/providers/app/default-values';
import { NavLinkType } from '@/admin/types/common';
import {
  getBrand,
  getItemFromLocalStorage,
  img_url,
  setItemToLocalStorage,
} from '@/admin/utils/helpers';
import { getUserInfoApi } from '@/admin/utils/helpers-api';
import { IUser, IUserInfo, UserRole, UserType } from '@/common/types';

import '@/admin/styles/globals.css';

const queryString = window.location.search;
const searchParams = new URLSearchParams(queryString);

const withNav = searchParams.get('noNav') !== '1';
const withToken = searchParams.get('withToken') !== null;

const ProtectedRoute = ({
  component,
  hidden,
}: {
  component: ComponentType;
  hidden?: boolean;
}) => {
  const Component = component;

  return hidden ? (
    <AppPlaceholder
      label="unavailable"
      message="unavailableMessage"
      icon="door_front"
    />
  ) : (
    <Component />
  );
};

export function App() {
  const {
    error,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  // user information
  const [userInfo, setUserInfo] = useState<IUserInfo>(DEFAULT_USER_INFO);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  // user selected by the admin
  const [selectedUser, setSelectedUser] = useState<IUser>(
    (getItemFromLocalStorage(
      withToken ? 'selectedUser-Boss' : 'selectedUser',
      true
    ) as IUser) || DEFAULT_USER
  );
  // states
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNoUserError, setIsNoUserError] = useState<boolean>(false);
  // navigation
  const [isRoutesVisible, setIsRoutesVisible] = useState(true);
  const [hiddenNavLinks, setHiddenNavLinks] = useState<NavLinkType[]>([]);
  const [hiddenSubNavLinks, setHiddenSubNavLinks] = useState<NavLinkType[]>([]);

  // hide all routes for the admins until they select the user
  useEffect(() => {
    if (isAdmin) {
      setIsRoutesVisible(Boolean(selectedUser.name));
    }
  }, [selectedUser.name]);

  // update favicon and meta title
  useEffect(() => {
    const { role, networkId } = userInfo;
    const isM3TechAdmin = role === 'm3Tech admin';
    const favicon =
      isM3TechAdmin || !networkId
        ? faviconByBrand['m3tech']
        : faviconByBrand[getBrand(networkId)];

    const faviconEl = document.getElementById('favicon') as HTMLLinkElement;
    faviconEl.href = `${img_url}/favicon/${favicon}`;

    document.title = t('appTitle');
  }, [language, userInfo]);

  // redirect if the user is not authenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !withToken) loginWithRedirect();
  }, [isAuthenticated, isLoading]);

  // get the user info
  useEffect(() => {
    (async () => {
      setIsLoadingUserInfo(true);
      const token = await getAccessToken();

      if (token) {
        try {
          let userInfo;
          if (withToken) {
            const userIdFromParam = searchParams.get(
              'userId'
            ) as unknown as number;
            const userTypeFromParam = searchParams
              .get('userType')
              ?.toLowerCase() as unknown as UserType;

            userInfo = (await getUserInfoApi(
              token,
              userIdFromParam || undefined,
              userTypeFromParam || undefined
            )) as IUserInfo;

            // Added to set selected user for BOSS CMP fingerprints
            if (
              userInfo.role !== 'm3Tech admin' &&
              userInfo.role !== 'network admin'
            ) {
              const newSelectedUser: IUser = {
                blogAccess: userInfo.blogAccess,
                employeeId: userInfo.employeeId,
                name: userInfo.name,
                nameFr: userInfo.nameFr,
                networkId: userInfo.networkId,
                originalNetworkId: userInfo.originalNetworkId,
                specialtiesAccess: userInfo.specialtiesAccess,
                userType: userInfo.userType,
              };

              setSelectedUser(newSelectedUser);
              setItemToLocalStorage('selectedUser-Boss', newSelectedUser, true);
            }
          } else {
            userInfo = (await getUserInfoApi(token)) as IUserInfo;
          }

          if (!userInfo) {
            setIsError(true);
            setIsLoadingUserInfo(false);
            return;
          }

          const { blogAccess, specialtiesAccess, role } = userInfo;
          const isAdmin = role.includes('admin');
          const {
            name,
            userType,
            blogAccess: selectedUserBlogAccess,
            specialtiesAccess: selectedUserSpecialtiesAccess,
          } = selectedUser;

          if (name) {
            hideNavLinks(
              selectedUserBlogAccess,
              selectedUserSpecialtiesAccess,
              userType
            );
          } else {
            hideNavLinks(blogAccess, specialtiesAccess, role);
          }

          if (role === 'm3Tech admin') {
            setIsRoutesVisible(Boolean(name));
          } else if (role === 'network admin') {
            setIsRoutesVisible(userType === 'network' ? true : Boolean(name));
          } else if (role === 'company admin' || role === 'office admin') {
            setIsRoutesVisible(userType === 'broker' ? true : Boolean(name));
          }

          setUserInfo(userInfo);
          setIsAdmin(isAdmin);
          setIsLoadingUserInfo(false);
        } catch (e) {
          setIsError(true);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, []);

  // hide links for the selected user
  useEffect(() => {
    (async () => {
      const { blogAccess, specialtiesAccess, userType } = selectedUser;
      hideNavLinks(blogAccess, specialtiesAccess, userType);
    })();
  }, [selectedUser]);

  const getAccessToken = async (ignoreCache: boolean = false) => {
    // ignoreCache:false will return the existing access token
    // ignoreCache:true will return the newly issued access token
    try {
      if (withToken) {
        return searchParams.get('withToken') !== ''
          ? (searchParams.get('withToken') as string)
          : '0';
      }

      const token = await getAccessTokenSilently({
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:posts',
        ignoreCache,
      });

      return token;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('getAccessTokenSilently:', error);

      try {
        const token = await getAccessTokenWithPopup({
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          scope: 'read:posts',
          ignoreCache,
        });
        return token;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('getAccessTokenWithPopup:', e);
      }

      // eslint-disable-next-line no-console
      console.error(e);
      return '';
    }
  };

  const hideNavLinks = (
    blogAccess: boolean = false,
    specialtiesAccess: boolean = false,
    userTypeOrRole: UserRole | UserType
  ) => {
    const hiddenLinks: NavLinkType[] = [];
    const hiddenSubLinks: NavLinkType[] = [];
    const isMPH = selectedUser?.networkId === 6;

    const isCompany =
      userTypeOrRole === 'company admin' || userTypeOrRole === 'company';

    const isNetwork =
      userTypeOrRole === 'network admin' || userTypeOrRole === 'network';

    const isOfficeAdmin =
      userTypeOrRole === 'office admin' ||
      (userTypeOrRole === 'office' && isAdmin);

    // hide privacy policies link for MPH, or if not a company, or network, or office admin
    if (isMPH || !(isCompany || isNetwork || isOfficeAdmin)) {
      hiddenLinks.push('privacyPolicies');
    }

    if (isNetwork) {
      // hide all links for networks but the announcements and Privacy Policies pages
      hiddenLinks.push('blogs', 'dashboard', 'testimonials');
      hiddenSubLinks.push('homepage', 'specialties');
    } else {
      // hide announcements link for all user types but network
      hiddenLinks.push('announcements');
      // hide blogs pages for users without access
      if (!blogAccess) hiddenLinks.push('blogs');
      // hide the specialties page for users without access
      if (!specialtiesAccess) {
        withToken
          ? hiddenLinks.push('specialties')
          : hiddenSubLinks.push('specialties');
      }
    }

    setHiddenNavLinks(hiddenLinks);
    setHiddenSubNavLinks(hiddenSubLinks);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  const isRouteHidden = (path: NavLinkType, isSubLink?: boolean): boolean => {
    const links = isSubLink ? hiddenSubNavLinks : hiddenNavLinks;
    return links.includes(path);
  };

  return (
    <AppProvider
      isAdmin={isAdmin}
      isNoUserError={isNoUserError}
      selectedUser={selectedUser}
      userInfo={userInfo}
      withToken={withToken}
      getAccessToken={getAccessToken}
      handleLogout={handleLogout}
      setIsNoUserError={setIsNoUserError}
      setSelectedUser={setSelectedUser}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
          <CssBaseline />

          {error || isError ? <AppError /> : isLoadingUserInfo && <Loader />}

          {!isLoadingUserInfo && (
            <>
              {!error && !isError && (
                <>
                  {!withToken && <AppSessionTime />}

                  <BrowserRouter>
                    {isAdmin && <AdminToolbar />}

                    {isNoUserError && (
                      <Error
                        message={t('errorNoUserMessage')}
                        title={t('errorNoUserTitle')}
                      />
                    )}

                    {!isRoutesVisible && (
                      <AppPlaceholder
                        label="searchUser"
                        message="searchUserMessage"
                        icon="search"
                      />
                    )}

                    {isRoutesVisible && !isNoUserError && (
                      <div className="content">
                        {withNav && (
                          <Nav
                            hiddenLinks={hiddenNavLinks}
                            hiddenSubLinks={hiddenSubNavLinks}
                          />
                        )}

                        <main>
                          <Routes>
                            <Route
                              path={DASHBOARD_PATH}
                              element={
                                <ProtectedRoute
                                  component={DashboardPage}
                                  hidden={isRouteHidden('dashboard')}
                                />
                              }
                            />

                            <Route
                              path={BLOGS_PATH}
                              element={
                                <ProtectedRoute
                                  component={BlogsPage}
                                  hidden={isRouteHidden('blogs')}
                                />
                              }
                            />

                            <Route
                              path={`${BLOGS_PATH}/${ADD_PATH}`}
                              element={
                                <ProtectedRoute
                                  component={AddBlogPage}
                                  hidden={isRouteHidden('blogs')}
                                />
                              }
                            />

                            <Route
                              path={`${BLOGS_PATH}/:blogId`}
                              element={
                                <ProtectedRoute
                                  component={EditBlogPage}
                                  hidden={isRouteHidden('blogs')}
                                />
                              }
                            />

                            <Route
                              path={TESTIMONIALS_PATH}
                              element={
                                <ProtectedRoute
                                  component={TestimonialsPage}
                                  hidden={isRouteHidden('testimonials')}
                                />
                              }
                            />

                            <Route
                              path={ANNOUNCEMENTS_PATH}
                              element={
                                <ProtectedRoute
                                  component={AnnouncementsPage}
                                  hidden={isRouteHidden('announcements')}
                                />
                              }
                            />

                            <Route
                              path={HOMEPAGE_PATH}
                              element={
                                <ProtectedRoute
                                  component={HomepagePage}
                                  hidden={isRouteHidden('homepage', true)}
                                />
                              }
                            />

                            <Route
                              path={SPECIALTIES_PATH}
                              element={
                                <ProtectedRoute
                                  component={SpecialtiesPage}
                                  hidden={isRouteHidden('specialties')}
                                />
                              }
                            />

                            <Route
                              path={`${SPECIALTIES_PATH}/${ADD_PATH}`}
                              element={
                                <ProtectedRoute
                                  component={AddSpecialtyPage}
                                  hidden={isRouteHidden('specialties')}
                                />
                              }
                            />

                            <Route
                              path={`${SPECIALTIES_PATH}/:id`}
                              element={
                                <ProtectedRoute
                                  component={EditSpecialtyPage}
                                  hidden={isRouteHidden('specialties')}
                                />
                              }
                            />

                            <Route
                              path={PRIVACY_POLICIES_PATH}
                              element={
                                <ProtectedRoute
                                  component={PrivacyPoliciesPage}
                                  hidden={isRouteHidden('privacyPolicies')}
                                />
                              }
                            />

                            <Route
                              path={`${PRIVACY_POLICIES_PATH}/:privacyPolicyId`}
                              element={
                                <ProtectedRoute
                                  component={EditPrivacyPoliciesPage}
                                  hidden={isRouteHidden('privacyPolicies')}
                                />
                              }
                            />

                            <Route
                              path={SETTINGS_PATH}
                              element={
                                <ProtectedRoute component={SettingsPage} />
                              }
                            />

                            <Route
                              path="*"
                              element={
                                <AppPlaceholder
                                  label="unavailable"
                                  message="unavailableMessage"
                                  icon="door"
                                />
                              }
                            />
                          </Routes>
                        </main>
                      </div>
                    )}
                  </BrowserRouter>
                </>
              )}
            </>
          )}
        </ThemeProvider>
      </LocalizationProvider>
    </AppProvider>
  );
}
