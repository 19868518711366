import { LexicalEditor, UNDO_COMMAND, REDO_COMMAND } from 'lexical';

import { Icon } from '@/admin/components';
import { ToolbarButton } from '@/admin/components/editor/editorPlugins';

type ToolbarHistoryProps = {
  canRedo: boolean;
  canUndo: boolean;
  editor: LexicalEditor;
};

export const ToolbarHistory = ({
  canRedo,
  canUndo,
  editor,
}: ToolbarHistoryProps) => {
  const handleRedoClick = () => {
    editor.dispatchCommand(REDO_COMMAND, undefined);
  };

  const handleUndoClick = () => {
    editor.dispatchCommand(UNDO_COMMAND, undefined);
  };

  return (
    <>
      <ToolbarButton
        ariaLabel="Undo"
        onClick={handleUndoClick}
        disabled={!canUndo}
      >
        <Icon size="sm" symbol="undo" />
      </ToolbarButton>

      <ToolbarButton
        ariaLabel="Redo"
        onClick={handleRedoClick}
        disabled={!canRedo}
      >
        <Icon size="sm" symbol="redo" />
      </ToolbarButton>
    </>
  );
};
