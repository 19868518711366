import { Search } from '@mui/icons-material';
import {
  Alert,
  Button,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  Stack,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';
import { material_symbols } from '@/admin/consts';

const PICKER_SIZE = 72;
const ICON_SIZE = 36;
const VISIBLE_ICONS_NUMBER = 42;
const ICONS_PER_ROW = 6;
const ROW_SPACE = 8;

type IconPickerProps = {
  icon: string;
  handleIconChange: (icon: string) => void;
  errorMessage?: string;
  title?: string;
};

export const IconPicker = ({
  errorMessage,
  icon,
  title,
  handleIconChange,
}: IconPickerProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [iconListEl, setIconListEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [visibleIcons, setVisibleIcons] = useState<string[]>(
    material_symbols.slice(0, VISIBLE_ICONS_NUMBER)
  );

  const open = Boolean(anchorEl);

  const handleScroll = (elem: HTMLElement) => {
    const scrollTop = elem.scrollTop;

    if (scrollTop > ICON_SIZE + ROW_SPACE) {
      const addedRows = Math.trunc(scrollTop / (ICON_SIZE + ROW_SPACE));
      const icons = searchResult.length ? searchResult : material_symbols;

      setVisibleIcons(
        icons.slice(0, VISIBLE_ICONS_NUMBER + ICONS_PER_ROW * addedRows)
      );
    }
  };

  useEffect(() => {
    if (iconListEl && visibleIcons.length >= VISIBLE_ICONS_NUMBER) {
      const scroll = () => handleScroll(iconListEl);
      iconListEl.addEventListener('scroll', scroll);
      return () => {
        iconListEl.removeEventListener('scroll', scroll);
      };
    }
  }, [iconListEl, visibleIcons, handleScroll]);

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        setIconListEl(document.getElementById('icon-list'));
      }, 100);
    } else {
      setIconListEl(null);
    }
  }, [anchorEl]);

  const handleOpenDialog = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
    setVisibleIcons(material_symbols.slice(0, VISIBLE_ICONS_NUMBER));
    setSearchValue('');
    setSearchResult([]);
  };

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    const iconsCopy = [...material_symbols];
    const searchResult = iconsCopy.filter((icon) =>
      icon.includes(value.toLowerCase())
    );

    setSearchValue(value);
    setSearchResult(searchResult);
    setVisibleIcons(searchResult.slice(0, VISIBLE_ICONS_NUMBER));
  };

  const handleOnIconClick = (icon: string) => {
    handleCloseDialog();
    handleIconChange(icon);
  };

  const handleDelete = () => {
    handleIconChange('');
  };

  return (
    <Stack spacing={1}>
      <Typography variant="caption">{`${title || t('icon')}:`}</Typography>

      <Stack alignItems="stretch" direction="row" spacing={1}>
        {Boolean(!icon) && (
          <>
            <StyledButton
              aria-controls={open ? 'icon-picker' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className={open ? 'is-open' : ''}
              id="icon-picker-button"
              onClick={handleOpenDialog}
            >
              <Typography variant="subtitle2">{t('browseIcons')}</Typography>
            </StyledButton>

            <Fade in={Boolean(errorMessage)}>
              <StyledAlert severity="error">{errorMessage}</StyledAlert>
            </Fade>
          </>
        )}

        {Boolean(icon) && (
          <>
            <StyledIcon>
              <Icon size="xlg" symbol={icon} />
            </StyledIcon>

            <Stack alignItems="flex-start" justifyContent="space-between">
              <Button onClick={handleOpenDialog}>{t('change')}</Button>

              <StyledDeleteButton color="primary" onClick={handleDelete}>
                <Icon size="md" symbol="delete" />
              </StyledDeleteButton>
            </Stack>
          </>
        )}
      </Stack>

      <StyledMenu
        anchorEl={anchorEl}
        id="icon-picker"
        open={open}
        MenuListProps={{ 'aria-labelledby': 'icon-picker-button' }}
        onClose={handleCloseDialog}
      >
        <StyledStack>
          <StyledSearch>
            <StyledTextField
              fullWidth
              helperText={
                searchValue &&
                t('searchResults', { number: searchResult.length })
              }
              placeholder={t('searchIcons')}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearch(e)}
            />
          </StyledSearch>

          <StyledMenuContent id="icon-list">
            {Boolean(!visibleIcons.length) && (
              <StyledEmptyState>{t('iconPickerEmptyState')}</StyledEmptyState>
            )}

            {Boolean(visibleIcons.length) && (
              <div>
                <StyledGrid container spacing={1}>
                  {visibleIcons.map((icon) => (
                    <Grid key={icon} item xs={2}>
                      <StyledIconButton onClick={() => handleOnIconClick(icon)}>
                        <Icon size="md" symbol={icon} />
                      </StyledIconButton>
                    </Grid>
                  ))}
                </StyledGrid>
              </div>
            )}
          </StyledMenuContent>

          <StyledMenuFooter>
            <Button onClick={handleCloseDialog}>{t('close')}</Button>
          </StyledMenuFooter>
        </StyledStack>
      </StyledMenu>
    </Stack>
  );
};

const StyledButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  border: `1px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
  borderRadius: 4,
  color: theme.palette.primary.main,
  display: 'block',
  height: PICKER_SIZE,
  textDecoration: 'underline',
  transition: 'all 0.3s',
  width: PICKER_SIZE,

  '&:hover': {
    borderColor: theme.palette.primary.main,
    fontWeight: 500,
  },

  '&.is-open': { opacity: 0 },
}));

const StyledIcon = styled('div')(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 4,
  display: 'flex',
  height: PICKER_SIZE,
  justifyContent: 'center',
  width: PICKER_SIZE,
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  margin: theme.spacing(0, 0, -0.5, -0.5),
  padding: theme.spacing(0.5),
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& > .MuiPaper-root': {
    borderColor: `${theme.palette.grey[400]} !important`,
    borderRadius: 10,
    boxShadow: `0px 4px 4px 0px ${theme.palette.shadow?.main}`,
    height: 424,
    marginTop: `${-PICKER_SIZE}px !important`,
    overflow: 'hidden',
    width: 300,
  },

  '& .MuiList-root': {
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(0),
  },
}));

const StyledStack = styled(Stack)({
  height: '100%',
  overflow: 'hidden',
});

const StyledSearch = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  margin: theme.spacing(0, 2, 1),
  padding: theme.spacing(1.5, 0, 3),
}));

const StyledMenuContent = styled('div')({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
});

const StyledEmptyState = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1.5),
  },

  '& .MuiFormHelperText-root': {
    position: 'absolute',
    right: 0,
    top: '100%',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  padding: theme.spacing(1, 2),
}));

const StyledIconButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 2,
  height: ICON_SIZE,
  width: '100%',
  transition: 'transform 0.25s',

  '&:hover': { transform: 'scale(1.05)' },
}));

const StyledMenuFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 0.5, 2),
  textAlign: 'right',
}));

const StyledAlert = styled(Alert)({ alignSelf: 'flex-start' });
