import { EmbedMatchResult } from '@lexical/react/LexicalAutoEmbedPlugin';
import { LexicalEditor } from 'lexical';

import {
  INSERT_VIDEO_COMMAND,
  IEmbedConfig,
} from '@/admin/components/editor/editorPlugins';
import { getFileType } from '@/admin/utils/helpers';

export const videoEmbedConfig: IEmbedConfig = {
  contentName: 'Video',
  exampleUrl: 'https://www.youtube.com/watch?v=3AdkqOkcFQ4',
  icon: 'movie',
  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_VIDEO_COMMAND, result.url);
  },
  parseUrl: (url: string) => {
    const youtubeMatch =
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
      ) ||
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
      ) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#]*).*/);

    if (youtubeMatch && youtubeMatch[2].length === 11)
      return {
        id: youtubeMatch[2],
        url: `https://www.youtube.com/embed/${youtubeMatch[2]}`,
      };

    const vimeoMatch = url.match(
      /^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/
    );

    if (vimeoMatch)
      return {
        id: vimeoMatch[2],
        url: `https://player.vimeo.com/video/${vimeoMatch[2]}`,
      };

    const urlFileType = getFileType(url);
    const urlMatch = ['.mp4', '.3gp'].includes(urlFileType);

    if (urlMatch) return { id: urlFileType, url };

    return null;
  },

  type: 'video',
};
