import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components';
import { Status } from '@/common/types';

type ChipColor =
  | 'error'
  | 'default'
  | 'info'
  | 'success'
  | 'primary'
  | 'secondary'
  | 'warning';

type StatusChipProps = {
  status: Status;
};

export const StatusChip = ({ status }: StatusChipProps) => {
  const { t } = useTranslation();

  const iconByStatus: Record<Status, string> = {
    draft: 'draft',
    hidden: 'visibility_off',
    published: 'visibility',
  };

  const colorByStatus: Record<Status, ChipColor> = {
    draft: 'info',
    hidden: 'error',
    published: 'success',
  };

  return (
    <Chip
      icon={<Icon size="md" symbol={iconByStatus[status]} />}
      color={colorByStatus[status]}
      label={t(status)}
      variant="outlined"
    />
  );
};
