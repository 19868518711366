import {
  Sidebar,
  SpecialtySidebarDetails,
  SpecialtySidebarHomepage,
  SpecialtySidebarSeo,
} from '@/admin/components';

export const SpecialtySidebar = () => (
  <Sidebar>
    <SpecialtySidebarHomepage />
    <SpecialtySidebarSeo />
    <SpecialtySidebarDetails />
  </Sidebar>
);
