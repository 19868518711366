import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AppContext, defaultSettingValues } from '@/admin/providers';
import { FC } from '@/admin/types/common';
import { IReviewIntegData } from '@/common/types';

export const SettingsFormProvider = ({ children }: FC) => {
  const { displayedUserInfo } = useContext(AppContext);

  const { pageId, appId, userLongToken: userToken } = displayedUserInfo;

  const reviewIntegSchema = yup.object().shape({
    pageId: yup.string().max(200, 'formPageIdMax').optional(),
    appId: yup.string().max(200, 'formAppIdMax').optional(),
    userToken: yup.string().max(500, 'formTokenMax').optional(),
  });

  useEffect(() => {
    // update default values as user data
    methods.reset({ pageId, appId, userToken });
  }, [pageId, appId, userToken]);

  const methods = useForm<IReviewIntegData>({
    mode: 'onTouched',
    resolver: yupResolver(reviewIntegSchema),
    defaultValues: defaultSettingValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
