import { Specialties } from '@/admin/components';
import {
  SpecialtiesProvider,
  StorageBlobCleanProvider,
} from '@/admin/providers';

export const SpecialtiesPage = () => (
  <SpecialtiesProvider>
    <StorageBlobCleanProvider>
      <Specialties />
    </StorageBlobCleanProvider>
  </SpecialtiesProvider>
);
