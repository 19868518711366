import { ElementType, ReactNode } from 'react';

import { BLOCK_TYPES, MERGE_TAG_TYPES } from '../consts';

export type NavLinkType =
  | 'announcements'
  | 'blogs'
  | 'dashboard'
  | 'homepage'
  | 'privacyPolicies'
  | 'specialties'
  | 'testimonials';

export interface IBlogsPageColumn {
  id: 'blogName' | 'dateUpdated' | 'status' | 'actions';
  label: string;
  align?: 'right';
}

export interface IPrivacyPoliciesPageColumn {
  id: 'userType' | 'provinces' | 'dateUpdated' | 'status' | 'actions';
  label: string;
  align?: 'right';
}

export interface IDashboardBlogsColumn {
  id: 'blogName' | 'dateUpdated';
  label: string;
}

export interface ISpecialtiesTableColumn {
  id: 'header' | 'dateUpdated' | 'status' | 'actions';
  label: string;
}

export interface IElementMUI {
  component: ElementType;
}

export interface INavLink {
  id: NavLinkType;
  title: string;
  to: string;
  icon?: string;
}
export interface ISelectOption {
  label: string;
  value: string;
}

export interface IUserTypeOption {
  label: string;
  value: string;
  isAutocompleteHidden?: boolean;
}

export interface ITestimonial {
  title: string;
}

export type FC = {
  children: ReactNode;
};

export type ThemeName =
  | 'invis'
  | 'mortgagealliance'
  | 'mortgageintelligence'
  | 'multi-prets';

export enum NetworkId {
  MortgageAllianceCompanyOfCanada = 5,
  MultiPretsMortgages = 6,
  MortgageAllianceFranchising = 7,
  MeridianFinancialServicesRevenue = 8,
  MeridianFinancialServicesFlatFee = 9,
  Invis = 11,
  MortgageIntelligence = 12,
  Verico = 13,
}

export enum UserNetworkId {
  MortgageAlliance = 5,
  MultiPretsMortgages = NetworkId.MultiPretsMortgages,
  Invis = NetworkId.Invis,
  MortgageIntelligence = NetworkId.MortgageIntelligence,
}

export interface IDialogArgs {
  message: string;
  title: string;
  handleAgree: () => void;
  buttonAgree?: string;
  buttonClose?: string;
  disableAgreeButton?: boolean;
  open?: boolean;
  handleClose?: () => void;
}

export type ImageState = 'addImage' | 'editImage' | 'previewImage';

export type InputFileError =
  | 'fileSize'
  | 'fileType'
  | 'multipleFiles'
  | 'removing'
  | 'uploading';

export interface IDragDropTab {
  id: string;
  title: string;
  isChildrenVisible?: boolean;
  isHidden?: boolean;
  subTitle?: string;
}

export interface ISelectedUser {
  name: string;
  networkId: number;
  type: string;
}

export interface IOpenSessionTimeoutModal {
  open: boolean;
  path: 'announcements' | 'blogs' | 'privacypolicies' | 'specialties' | null;
  autoSave: boolean;
  callback: () => void;
}

export type Provinces =
  | 'AB'
  | 'BC'
  | 'MB'
  | 'NB'
  | 'NL'
  | 'NT'
  | 'NS'
  | 'NU'
  | 'ON'
  | 'PE'
  | 'QC'
  | 'SK'
  | 'YT';

export type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type SelectType = keyof (typeof BLOCK_TYPES & typeof MERGE_TAG_TYPES);

export interface IAction {
  id: string;
  title: string;
  to?: string; // the action is a link
  onClick?: () => void; // the action is a button
}

export type ArticleType = 'blog' | 'mortgageServices';
