import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import {
  META_DESCRIPTION_MAX_LENGTH,
  META_TITLE_MAX_LENGTH,
} from '@/admin/consts';
import { defaultTextEditorContent } from '@/admin/providers';
import { IBlogData, IBlogValues } from '@/common/types';

type BlogFormProviderProps = {
  children: ReactNode;
  defaultValues: IBlogValues | IBlogData;
  isNew?: boolean;
};

export const BlogFormProvider = ({
  children,
  defaultValues,
  isNew = false,
}: BlogFormProviderProps) => {
  const blogSchema = yup.object().shape({
    authorName: yup.string().required('formAuthorNameRequired'),
    blogContent: yup
      .string()
      .test(
        'is-default-content',
        'formBlogContentRequired',
        (value) => value !== defaultTextEditorContent
      )
      .required('formBlogContentRequired'),
    blogName: yup.string().required('formBlogNameRequired'),
    blogNameLowerCased: yup.string().required('formBlogNameRequired'),
    brand: yup.string().optional(),
    description: yup
      .string()
      .max(META_DESCRIPTION_MAX_LENGTH, 'formDescriptionMax')
      .optional(),
    imageURL: yup.string().optional(),
    language: yup.string().required('formLanguageRequired'),
    slug: yup
      .string()
      .matches(/^$|^[a-z0-9-]+$/, 'formSlugMatch')
      .optional(),
    title: yup.string().max(META_TITLE_MAX_LENGTH, 'formTitleMax').optional(),
    userType: yup.string().required('formAuthorTypeRequired'),
  });

  const methods = useForm<IBlogData>({
    mode: 'onTouched',
    resolver: yupResolver(blogSchema),
    defaultValues,
  });

  useEffect(() => {
    // update default values as fetched blog data
    if (!isNew) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <StyledForm>{children}</StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
