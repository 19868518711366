import { FormHelperText, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BlogEditor } from '@/admin/components/';

export const BlogContentBody = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={2}>
      <BlogEditor />

      <FormHelperText error={!!errors.blogContent}>
        {t(errors.blogContent?.message as string)}
      </FormHelperText>
    </Stack>
  );
};
