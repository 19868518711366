import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconPicker, TextField } from '@/admin/components';
import {
  HOMEPAGE_DESCRIPTION_MAX_LENGTH,
  HOMEPAGE_TITLE_MAX_LENGTH,
} from '@/admin/consts';
import { SpecialtyContext } from '@/admin/providers';
import { ISpecialtyValues } from '@/common/types';

export const SpecialtySidebarHomepage = () => {
  const { t } = useTranslation();

  const { setIsDefaultHomepageDescription, setIsDefaultHomepageHeader } =
    useContext(SpecialtyContext);

  const {
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useFormContext<ISpecialtyValues>();

  const handleIconChange = (icon: string) => {
    setValue('icon', icon);
    clearErrors('icon');
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Typography variant="h4">{t('homepageContent')}</Typography>

        <IconPicker
          errorMessage={t(errors?.icon?.message as string)}
          icon={getValues('icon')}
          handleIconChange={handleIconChange}
        />

        <Controller
          name="homepageHeader"
          render={({ field: { onChange, value, ...field } }) => (
            <TextField
              error={!!errors?.homepageHeader}
              helperText={errors?.homepageHeader?.message as string}
              label={t('header')}
              caption={t('maxCharacters', {
                number: `${value.length}/${HOMEPAGE_TITLE_MAX_LENGTH}`,
              })}
              placeholder={t('enterHomepageHeader')}
              {...field}
              value={value}
              onChange={(e) => {
                const value = e.target.value.replace(/(\r\n|\n|\r)/gm, '');

                if (value.length <= HOMEPAGE_TITLE_MAX_LENGTH) {
                  onChange(value);
                  setIsDefaultHomepageHeader(false);
                }
              }}
              onPaste={(e) => {
                const text = e.clipboardData.getData('text');

                if (text.length > HOMEPAGE_TITLE_MAX_LENGTH) {
                  setValue(
                    'homepageHeader',
                    text.slice(0, HOMEPAGE_TITLE_MAX_LENGTH)
                  );
                  setIsDefaultHomepageHeader(false);
                }
              }}
            />
          )}
        />

        <Controller
          name="homepageDescription"
          render={({ field: { onChange, value, ...field } }) => (
            <TextField
              error={!!errors?.homepageDescription}
              helperText={errors?.homepageDescription?.message as string}
              label={t('description')}
              caption={t('maxCharacters', {
                number: `${value.length}/${HOMEPAGE_DESCRIPTION_MAX_LENGTH}`,
              })}
              multiline
              placeholder={t('enterHomepageDescription')}
              {...field}
              value={value}
              onChange={(e) => {
                const value = e.target.value.replace(/(\r\n|\n|\r)/gm, '');
                if (value.length <= HOMEPAGE_DESCRIPTION_MAX_LENGTH) {
                  onChange(value);
                  setIsDefaultHomepageDescription(false);
                }
              }}
              onPaste={(e) => {
                const text = e.clipboardData.getData('text');

                if (text.length > HOMEPAGE_DESCRIPTION_MAX_LENGTH) {
                  setValue(
                    'homepageDescription',
                    text.slice(0, HOMEPAGE_DESCRIPTION_MAX_LENGTH)
                  );
                  setIsDefaultHomepageDescription(false);
                }
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
