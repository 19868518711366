import {
  IBlogsFilters,
  ISpecialtiesFilters,
  ITestimonialsFilters,
} from '@/common/types';

export const getHeaders = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export const getFiltersQuery = (
  filters: IBlogsFilters | ITestimonialsFilters | ISpecialtiesFilters
) => {
  let filtersQuery = '';

  Object.keys(filters).forEach((key) => {
    const value = filters[key as keyof typeof filters];
    if (value) filtersQuery += `&${key}=${value}`;
  });

  return filtersQuery;
};
