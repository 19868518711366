import { ReactNode, createContext, useState } from 'react';

interface ISpecialtyContext {
  isDefaultHomepageDescription: boolean;
  isDefaultHomepageHeader: boolean;
  isNewSpecialty: boolean;
  setIsDefaultHomepageDescription: (
    isDefaultHomepageDescription: boolean
  ) => void;
  setIsDefaultHomepageHeader: (isDefaultHomepageHeader: boolean) => void;
}

interface ISpecialtyProvider {
  children: ReactNode;
  isNewSpecialty: boolean;
}

const initialContext: ISpecialtyContext = {
  isDefaultHomepageDescription: true,
  isDefaultHomepageHeader: true,
  isNewSpecialty: false,
  setIsDefaultHomepageDescription: () => {},
  setIsDefaultHomepageHeader: () => {},
};

export const SpecialtyContext =
  createContext<ISpecialtyContext>(initialContext);

export const SpecialtyProvider = ({
  children,
  isNewSpecialty,
}: ISpecialtyProvider) => {
  const [isDefaultHomepageDescription, setIsDefaultHomepageDescription] =
    useState<boolean>(true);
  const [isDefaultHomepageHeader, setIsDefaultHomepageHeader] =
    useState<boolean>(true);

  return (
    <SpecialtyContext.Provider
      value={{
        isDefaultHomepageDescription,
        isDefaultHomepageHeader,
        isNewSpecialty,
        setIsDefaultHomepageDescription,
        setIsDefaultHomepageHeader,
      }}
    >
      {children}
    </SpecialtyContext.Provider>
  );
};
