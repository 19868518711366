import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/admin/providers';
import { ThemeName } from '@/admin/types/common';
import { getBrand, getLocalizedDate } from '@/admin/utils/helpers';

const accentColors: Record<ThemeName, string> = {
  invis: '#900028',
  mortgagealliance: '#7bc246',
  mortgageintelligence: '#004b8d',
  'multi-prets': '#003f7e',
};

const textColors: Record<ThemeName, string> = {
  invis: '#333',
  mortgagealliance: '#333',
  mortgageintelligence: '#222',
  'multi-prets': '#333',
};

type PreviewBodyProps = {
  articleName: string;
  content: string;
  date: number;
  language: string;
  sectionName: string;
};

export const PreviewBody = ({
  articleName,
  content,
  date,
  language,
  sectionName,
}: PreviewBodyProps) => {
  const { t } = useTranslation();
  const {
    userInfo: { networkId },
  } = useContext(AppContext);

  const brand = getBrand(networkId);

  return (
    <StyledBody className={brand} brand={brand}>
      <h1 className="blogName">{articleName}</h1>

      <div className="breadcrumbs">
        <div className="link">{t('home', { lng: language })}</div>

        <div className="delimiter">
          <ArrowForwardIosIcon />
        </div>

        <div className="link">{t(sectionName, { lng: language })}</div>

        <div className="delimiter">
          <ArrowForwardIosIcon />
        </div>

        <div className="current">{articleName}</div>
      </div>

      <div className="date">{getLocalizedDate(language, date)}</div>

      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </StyledBody>
  );
};

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'brand',
})<{ brand: ThemeName }>(({ theme, brand }) => ({
  fontFamily: 'Montserrat',
  color: textColors[brand],
  margin: '0 auto',
  maxWidth: 1218,
  padding: theme.spacing(4, 0),
  width: '100%',
  wordBreak: 'break-word',

  // delete editor styles
  '& .editor_listItem': { margin: 0 },
  '& .embedBlockFocus': { outline: 'none' },

  // common for all brands styles
  '& .blogName': {
    fontSize: '3em',
    color: accentColors[brand],
    marginBottom: '1rem',
    textAlign: 'center',
  },

  '& .breadcrumbs': {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
    justifyContent: 'center',
    padding: '20px 0',
    textAlign: 'center',
    marginBottom: '10px',

    '& .link': {
      color: accentColors[brand],
      textDecoration: 'underline',
    },

    '& .delimiter': {
      fontSize: 12,
      lineHeight: 0,
      margin: '0 4px',

      '& > svg': { fontSize: '1em' },
    },

    '& .current': { fontWeight: 700 },
  },

  '& .date': {
    color: '#666',
    fontSize: '.85em',
    marginBottom: '2rem',
    textAlign: 'center',
  },

  '& p': {
    fontSize: '1.1em',
    lineHeight: '1.5em',
    marginBottom: '1.5rem',
  },

  '& h1': {
    fontSize: '3em',
    fontWeight: 700,
    color: accentColors[brand],
    marginBottom: '2rem',
  },

  '& h2': {
    color: accentColors[brand],
    fontSize: '2em',
    fontWeight: 700,
    marginBottom: '2rem',
    textTransform: 'none',
  },

  '& h3': {
    fontSize: '1.5em',
    fontWeight: 700,
    color: accentColors[brand],
    marginBottom: '2rem',
    textTransform: 'none',
  },

  '& a': {
    color: accentColors[brand],
    textDecoration: 'underline',
  },

  '& img': {
    height: 'auto',
    width: '100%',
  },

  '& ul': {
    border: '1px solid #5a5a5a',
    listStyle: 'none',
    marginBottom: '2rem',
    padding: '3rem',
    position: 'relative',
    width: 'fit-content',

    '& li': {
      '&::before': {
        content: '"\\2022"',
        left: '2.5rem',
        position: 'absolute',
      },
    },
  },

  '& ol': {
    border: '1px solid #5a5a5a',
    listStyle: 'demical',
    marginBottom: '2rem',
    padding: '3rem',
    width: 'fit-content',
  },

  '& li': {
    fontSize: '1.2rem',
    lineHeight: '1.4',
    marginBottom: '0.5rem',
    paddingLeft: '10px',
  },

  // custom brand styles
  '&.mortgagealliance': {
    fontFamily: 'Avenir Next Regular',

    '& .blogName': {
      fontSize: '2.25em',
      fontWeight: 400,
      color: textColors[brand],
    },

    '& .breadcrumbs': {
      '& .link': { color: '#706d72' },
      '& .current': { fontWeight: 400 },
    },

    '& p': { fontSize: '1em' },

    '& h1': {
      fontSize: '2.25em',
      fontWeight: 400,
      color: textColors[brand],
    },

    '& h2': {
      fontSize: '1.75em',
      fontWeight: 400,
      color: textColors[brand],
    },

    '& h3': {
      fontSize: '1.25em',
      fontWeight: 400,
      color: textColors[brand],
    },

    '& a': { color: '#706d72' },
  },

  '&.multi-prets': {
    fontFamily: 'Gilmer',

    '& .blogName': {
      fontFamily: 'Gilmer Light',
      fontWeight: 400,
    },

    '& .breadcrumbs': {
      '& .link': { color: '#84929b' },
      '& .current': { fontWeight: 400 },
    },

    '& h1': {
      fontFamily: 'Gilmer Light',
      fontWeight: 400,
    },

    '& h2': {
      fontFamily: 'Gilmer Light',
      fontWeight: 400,
    },

    '& h3': {
      fontFamily: 'Gilmer Light',
      fontWeight: 400,
    },

    '& a': { color: '#84929b' },

    '& ul': {
      borderColor: '#74af55',

      '& li': {
        '&::before': {
          content: '"\\27A4"',
          left: '2rem',
        },
      },
    },

    '& ol': { borderColor: '#74af55' },

    '& strong': { fontFamily: 'Gilmer Bold' },
  },
}));
