import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
  children: ReactNode;
  to: string;
  className?: string;
  onClick?: () => void;
};

export const Link = ({ children, className, to, onClick }: LinkProps) => (
  <StyledLink className={className} to={to} onClick={onClick}>
    {children}
  </StyledLink>
);

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  transition: 'font-weight, 0.3s',

  '&:hover': { fontWeight: 700 },
}));
