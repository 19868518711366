import { styled } from '@mui/material';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SettingsSection, TextField } from '@/admin/components';
import { AppContext, ThemeContext } from '@/admin/providers';
import { updateUserInfoApi } from '@/admin/utils/helpers-api';
import { IReviewIntegData } from '@/common/types';

export const SettingsIntegrations = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(ThemeContext);
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useFormContext<IReviewIntegData>();
  const {
    displayedUserInfo,
    selectedUserId,
    getAccessToken,
    setDisplayedUserInfo,
  } = useContext(AppContext);

  const { appId, employeeId, pageId, userLongToken } = displayedUserInfo;

  const handleUpdateUserData = (type: 'google' | 'facebook') => {
    handleSubmit(async (data: IReviewIntegData) => {
      const values =
        type === 'google'
          ? // only send new values for google if google update button is pressed
            {
              userId: selectedUserId || employeeId,
              appId,
              pageId,
              userToken: userLongToken,
            }
          : //if facebook update button is pressed, only send new values for facebook
            {
              userId: selectedUserId || employeeId,
              appId: data.appId,
              pageId: data.pageId,
              userToken: data.userToken,
            };

      try {
        const token = await getAccessToken();
        const res = await updateUserInfoApi(token, values);

        if (res === 201) {
          const { userToken, ...rest } = values;
          setDisplayedUserInfo({
            ...displayedUserInfo,
            ...rest,
            userLongToken: userToken,
          });
        }

        openSnackbar(res === 201 ? t('settingsConfirm') : t('settingsFailed'));
      } catch (e) {
        openSnackbar(t('settingsFailed'));
        // eslint-disable-next-line no-console
        console.log(e);
      }
    })();
  };

  return (
    <>
      <SettingsSection
        title={t('facebookReviewIntegration')}
        handleUpdate={() => handleUpdateUserData('facebook')}
        isValidateButtonHidden={true}
        disabledUpdateButton={
          !dirtyFields.pageId && !dirtyFields.appId && !dirtyFields.userToken
        }
      >
        <StyledField>
          <Controller
            name="pageId"
            render={({ field }) => (
              <TextField
                error={!!errors.pageId}
                helperText={errors.pageId?.message as string}
                label={`${t('pageID')}`}
                placeholder={t('pageID')}
                {...field}
              />
            )}
          />
        </StyledField>

        <StyledField>
          <Controller
            name="appId"
            render={({ field }) => (
              <TextField
                error={!!errors.appId}
                helperText={errors.appId?.message as string}
                label={`${t('appID')}`}
                placeholder={t('appID')}
                {...field}
              />
            )}
          />
        </StyledField>

        <StyledField>
          <Controller
            name="userToken"
            render={({ field }) => (
              <TextField
                error={!!errors.userToken}
                helperText={errors.userToken?.message as string}
                label={`${t('userLongToken')}`}
                placeholder={t('userLongToken')}
                {...field}
              />
            )}
          />
        </StyledField>
      </SettingsSection>
    </>
  );
};

const StyledField = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),

  '&:last-child': {
    marginBottom: 0,
  },
}));
