import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import {
  FETCH_SPECIALTIES_API,
  FETCH_SPECIALTIES_SLUGS_URL,
} from '@/admin/consts';
import { getFiltersQuery, getHeaders } from '@/admin/utils/helpers-api';
import {
  ISlugData,
  ISpecialtiesFilters,
  ISpecialtyValues,
  SortingOrderSpecialties,
  UserType,
} from '@/common/types';

export const createSpecialtyApi = async (
  values: ISpecialtyValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(values),
  });

  const { data, status }: { data: ISpecialtyValues; status: number } =
    await res.json();

  return { status, data };
};

export const updateSpecialtyApi = async (
  values: ISpecialtyValues,
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(values),
  });

  const { data, status }: { data: ISpecialtyValues; status: number } =
    await res.json();

  return { status, data };
};

export const updateSpecialtiesApi = async (
  values: ISpecialtyValues[],
  token: string
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}/list`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(values),
  });

  const { data, status }: { data: ISpecialtyValues; status: number } =
    await res.json();

  return { status, data };
};

export const deleteSpecialtyApi = async (id: string, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}/${id}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { method: 'DELETE', headers });
  const { status } = await res.json();

  return status as number;
};

export const getSpecialtyByIdApi = async (
  id: string,
  token: string,
  userId?: number,
  userType?: UserType
) => {
  const queries = userId ? `?userId=${userId}&userType=${userType}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}/${id}${queries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data, status }: { data: ISpecialtyValues; status: number } =
    await res.json();

  return { data, status };
};

export const getSpecialtiesApi = async (
  token: string,
  filters?: ISpecialtiesFilters,
  order?: SortingOrderSpecialties
) => {
  const filtersQuery = filters ? getFiltersQuery(filters) : '';
  const orderQuery = order ? `&order=${order}` : '';
  const queries = `${orderQuery}${filtersQuery}`;
  const urlQueries = queries ? `?${queries.substring(1)}` : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_API}${urlQueries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data, status }: { data: ISpecialtyValues[]; status: number } =
    await res.json();

  return { data, status };
};

export const getSpecialtySlugsApi = async (
  token: string,
  userId?: number,
  userType?: UserType
) => {
  const queries = userId ? `?userId=${userId}&userType=${userType}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_SPECIALTIES_SLUGS_URL}${queries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data } = (await res.json()) as { data: ISlugData[] };

  return data;
};
