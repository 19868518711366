import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/admin/components/common';
import { ToolbarButton } from '@/admin/components/editor/editorPlugins/toolbarPlugin';
import { ToolbarSelectDropdown } from '@/admin/components/editor/editorPlugins/toolbarPluginPolicy';
import { SelectType } from '@/admin/types/common';

type ToolbarSelectProps<T extends SelectType> = {
  selectedType: T | null;
  types: Record<T, string>;
  formatType: (type: T) => void;
  defaultType?: string;
};

export const ToolbarSelect = ({
  selectedType,
  defaultType = '',
  types,
  formatType,
}: ToolbarSelectProps<SelectType>) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    const button = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (showDropDown && button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect();
      dropDown.style.top = `${top + 36}px`;
      dropDown.style.left = `${Math.min(
        left,
        window.innerWidth - dropDown.offsetWidth - 20
      )}px`;
    }
  }, [dropDownRef, buttonRef, showDropDown]);

  useEffect(() => {
    const button = buttonRef.current;

    if (button !== null && showDropDown) {
      const handle = (event: MouseEvent) => {
        const target = event.target;

        if (!button.contains(target as Node)) {
          setShowDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, buttonRef, showDropDown]);

  const handleClick = () => {
    setShowDropDown(!showDropDown);
  };

  const handleClose = () => {
    setShowDropDown(false);

    if (buttonRef?.current) {
      buttonRef.current.focus();
    }
  };

  return (
    <>
      <ToolbarButton
        ariaLabel={`Format ${t(
          selectedType ? types[selectedType] : defaultType
        )}`}
        className="blockType"
        ref={buttonRef}
        onClick={handleClick}
      >
        <span className="buttonText">
          {t(selectedType ? types[selectedType] : defaultType)}
        </span>
        <Icon symbol="expand_more" size="sm" />
      </ToolbarButton>

      {showDropDown &&
        createPortal(
          <ToolbarSelectDropdown
            selectedType={selectedType}
            types={types}
            ref={dropDownRef}
            onClose={handleClose}
            formatType={formatType}
          />,
          document.body
        )}
    </>
  );
};
