import { Box, Grid, Stack, styled } from '@mui/material';

import {
  StyledBox,
  StyledGrid,
  StyledGridContainer,
  StyledAppBar,
  StyledToolbar,
  Skeleton,
} from '@/admin/components';

export const ArticleSkeleton = () => (
  <>
    <StyledContainer>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Skeleton width={181} height={32} />

          <Stack direction="row" justifyContent="space-between">
            <Skeleton width={85} height={40} />
            <Skeleton width={124} height={40} />
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
    </StyledContainer>

    <StyledGridContainer container>
      <StyledGrid item xs={12} lg={9}>
        <Stack direction="row">
          <Skeleton width={169} height={60} />
          <Skeleton width={112} height={60} />
        </Stack>

        <Skeleton height={706} />
      </StyledGrid>

      <Grid item xs={12} lg={3}>
        <StyledBox>
          <Stack>
            <Skeleton width={49} height={26} />
            <Skeleton height={82} />
            <Skeleton height={62} />
            <Skeleton height={200} />
          </Stack>

          <Stack>
            <Skeleton width={134} height={32} />
            <Skeleton height={290} />
          </Stack>

          <Stack>
            <Skeleton width={128} height={32} />
            <Skeleton width={222} height={32} />
            <Skeleton width={293} height={32} />
            <Skeleton width={49} height={32} />
          </Stack>
        </StyledBox>
      </Grid>
    </StyledGridContainer>
  </>
);

const StyledContainer = styled(Box)({ zIndex: 10, minWidth: 800 });
