import { Stack, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@/admin/components';
import { AppContext } from '@/admin/providers';
import { SectionAccess, SectionAccessUserType } from '@/common/types';

type SettingsPermissionsSectionProps = {
  section: 'blog' | 'specialties';
  sectionAccess: SectionAccess;
  setSectionAccess: (sectionAccess: SectionAccess) => void;
};

export const SettingsPermissionsSection = ({
  section,
  sectionAccess,
  setSectionAccess,
}: SettingsPermissionsSectionProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    selectedUser: { networkId },
  } = useContext(AppContext);

  const width = language === 'en' ? 84 : 110;

  const handleChange = (name: SectionAccessUserType) => {
    setSectionAccess({ ...sectionAccess, [name]: !sectionAccess[name] });
  };

  return (
    <StyledStack spacing={2}>
      <StyledTypography variant="h4">{t(`${section}Access`)}</StyledTypography>

      <Stack spacing={3}>
        <Switch
          ariaLabel={`brokers ${section} access`}
          checked={sectionAccess.broker}
          label="brokers"
          textChecked="off"
          textDefault="on"
          width={width}
          onChange={() => handleChange('broker')}
        />

        <Switch
          ariaLabel={`companies ${section} access`}
          checked={sectionAccess.company}
          label={networkId === 6 ? 'teams' : 'companies'}
          textChecked="off"
          textDefault="on"
          width={width}
          onChange={() => handleChange('company')}
        />

        <Switch
          ariaLabel={`offices ${section} access`}
          checked={sectionAccess.office}
          label="offices"
          textChecked="off"
          textDefault="on"
          width={width}
          onChange={() => handleChange('office')}
        />
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({ height: '100%' });

const StyledTypography = styled(Typography)({ flexGrow: 1 });
