import {
  BlogInfoDetails,
  BlogInfoImage,
  BlogInfoSeo,
  Sidebar,
} from '@/admin/components';

type BlogInfoProps = {
  handleDisableAction: (disabled: boolean) => void;
};

export const BlogInfo = ({ handleDisableAction }: BlogInfoProps) => (
  <Sidebar>
    <BlogInfoSeo />
    <BlogInfoImage handleDisableAction={handleDisableAction} />
    <BlogInfoDetails />
  </Sidebar>
);
