import { Button, Divider } from '@mui/material';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Header, Preview } from '@/admin/components';
import { SPECIALTIES_PATH } from '@/admin/consts';
import { SpecialtyContext } from '@/admin/providers';
import { ISpecialtyValues } from '@/common/types';

type SpecialtyHeaderProps = {
  onDeleteClick: () => void;
  onPublishClick: () => void;
  onSaveClick: (sessionTimeout: boolean) => void;
};

export const SpecialtyHeader = ({
  onDeleteClick,
  onPublishClick,
  onSaveClick,
}: SpecialtyHeaderProps) => {
  const { t } = useTranslation();
  const { isNewSpecialty } = useContext(SpecialtyContext);
  const { formState, getFieldState, getValues } =
    useFormContext<ISpecialtyValues>();

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const status = getValues('status');

  const openPreview = () => {
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  return (
    <>
      <Header title={t('allSpecialties')} to={SPECIALTIES_PATH}>
        <Button onClick={onDeleteClick}>{t('delete')}</Button>

        <Divider orientation="vertical" flexItem />

        <Button onClick={openPreview}>{t('preview')}</Button>

        <Divider orientation="vertical" flexItem />

        {status === 'draft' && (
          <Button variant="outlined" onClick={() => onSaveClick(false)}>
            {isNewSpecialty ? t('save') : t('update')}
          </Button>
        )}

        <Button variant="contained" onClick={onPublishClick}>
          {status === 'draft' ? t('publish') : t('update')}
        </Button>
      </Header>

      <Preview
        articleName={getValues('header')}
        articleType="mortgageServices"
        content={getValues('description')}
        date={getValues('datePublished') as number}
        language={getValues('language')}
        linkBack={t('editSpecialty')}
        isOpen={isPreviewOpen}
        isSlugDirty={getFieldState('slug', formState).isDirty}
        sectionName="mortgageServices"
        slug={getValues('slug')}
        status={getValues('status')}
        title={t('specialtyPreview')}
        handleClose={closePreview}
      />
    </>
  );
};
