import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ArticleError, ArticleSkeleton, Specialty } from '@/admin/components';
import {
  AppContext,
  SpecialtyFormProvider,
  StorageBlobCleanProvider,
  defaultSpecialtyValues,
} from '@/admin/providers';
import { getSpecialtyByIdApi } from '@/admin/utils/helpers-api';
import { ISpecialtyValues } from '@/common/types';

export const EditSpecialtyPage = () => {
  const { id } = useParams();

  const {
    selectedUser: { userType },
    selectedUserId,
    getAccessToken,
  } = useContext(AppContext);

  const [defaultValues, setDefaultValues] = useState<ISpecialtyValues>(
    defaultSpecialtyValues
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const token = await getAccessToken();
          const { status, data } = await getSpecialtyByIdApi(
            id,
            token,
            selectedUserId,
            userType
          );

          if (status === 200) {
            setDefaultValues(data);
          } else {
            setIsError(true);
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          setIsError(true);
        }
      })();
    }
  }, [id]);

  if (isLoading) return <ArticleSkeleton />;

  if (isError) return <ArticleError />;

  return (
    <SpecialtyFormProvider defaultValues={defaultValues}>
      <StorageBlobCleanProvider>
        <Specialty />
      </StorageBlobCleanProvider>
    </SpecialtyFormProvider>
  );
};
