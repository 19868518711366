import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { SidebarSeoEditModal } from '@/admin/components';
import { metaDataSchema } from '@/admin/consts';
import { defaultMetaDataValues } from '@/admin/providers';
import { isSlugUnique } from '@/admin/utils/helpers';
import { ISEOData, ISlugData } from '@/common/types';

type BlogInfoSeoEditModalProps = {
  open: boolean;
  slugsData: ISlugData[];
  handleCloseEditModal: () => void;
  handleSaveEditModal: (values: ISEOData) => void;
};

export const BlogInfoSeoEditModal = ({
  open,
  slugsData,
  handleCloseEditModal,
  handleSaveEditModal,
}: BlogInfoSeoEditModalProps) => {
  const { getValues } = useFormContext();

  const [errors, setErrors] = useState(defaultMetaDataValues);
  const [values, setValues] = useState(defaultMetaDataValues);
  const [isSlugError, setIsSlugError] = useState<boolean>(false);

  const blogId = getValues('id');

  useEffect(() => {
    if (open) {
      setValues({
        slug: getValues('slug'),
        title: getValues('title'),
        description: getValues('description'),
      });
    }
  }, [open]);

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, slug: e.target.value });
    validateInputValue('slug', e.target.value);

    setIsSlugError(!isSlugUnique(slugsData, e.target.value, blogId));
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, title: e.target.value });
    validateInputValue('title', e.target.value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, description: e.target.value });
    validateInputValue('description', e.target.value);
  };

  const onClose = () => {
    handleCloseEditModal();
    setErrors(defaultMetaDataValues);
  };

  const onSave = () => {
    const hasNoError = !Object.values(errors).filter((err) => err).length;

    if (hasNoError) {
      handleSaveEditModal(values);
    }
  };

  const validateInputValue = (key: string, value: string) => {
    metaDataSchema
      .validate({ ...values, [key]: value }, { abortEarly: false })
      .then(() => {
        setErrors(defaultMetaDataValues);
      })
      .catch((err) => {
        const newErrors: ISEOData = { ...defaultMetaDataValues };

        err.inner.map((e: yup.ValidationError) => {
          if (e.path) {
            newErrors[e.path as keyof ISEOData] = e.message;
          }
        });

        setErrors(newErrors);
      });
  };

  return (
    <SidebarSeoEditModal
      errors={errors}
      isSlugError={isSlugError}
      language={getValues('language')}
      open={open}
      slugSubPath="blog"
      values={values}
      handleChangeDescription={handleChangeDescription}
      handleChangeSlug={handleChangeSlug}
      handleChangeTitle={handleChangeTitle}
      handleClose={onClose}
      handleSave={onSave}
    />
  );
};
