import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import {
  HOMEPAGE_DESCRIPTION_MAX_LENGTH,
  HOMEPAGE_TITLE_MAX_LENGTH,
  META_DESCRIPTION_MAX_LENGTH,
  META_TITLE_MAX_LENGTH,
} from '@/admin/consts';
import { ISpecialtyValues } from '@/common/types';

type SpecialtyFormProviderProps = {
  children: ReactNode;
  defaultValues: ISpecialtyValues;
};

export const SpecialtyFormProvider = ({
  children,
  defaultValues,
}: SpecialtyFormProviderProps) => {
  const specialtySchema = yup.object({
    createdBy: yup.string().required(),
    description: yup.string().required('specialtyDescriptionRequired'),
    header: yup.string().required('specialtyHeaderRequired'),
    homepageDescription: yup
      .string()
      .max(HOMEPAGE_DESCRIPTION_MAX_LENGTH, 'specialtyDescriptionLimitError'),
    homepageHeader: yup
      .string()
      .max(HOMEPAGE_TITLE_MAX_LENGTH, 'specialtyHeaderLimitError')
      .required('specialtyHeaderRequired'),
    icon: yup.string().required('requiredIcon'),
    language: yup.string().required(),
    metaDescription: yup
      .string()
      .max(META_DESCRIPTION_MAX_LENGTH, 'formDescriptionMax')
      .optional(),
    metaTitle: yup
      .string()
      .max(META_TITLE_MAX_LENGTH, 'formTitleMax')
      .optional(),
    position: yup.number().nullable(true).required(),
    slug: yup
      .string()
      .matches(/^$|^[a-z0-9-]+$/, 'formSlugMatch')
      .optional(),
    status: yup.string().required(),
    updatedBy: yup.string().required(),
    userId: yup.number().nullable(true).required(),
    userType: yup.string().required(),
  });

  const methods = useForm<ISpecialtyValues>({
    mode: 'onTouched',
    resolver: yupResolver(specialtySchema),
    defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
