import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Specialty } from '@/admin/components';
import {
  AppContext,
  SpecialtyFormProvider,
  SpecialtyProvider,
  StorageBlobCleanProvider,
  defaultSpecialtyValues,
} from '@/admin/providers';
import { getSlugText } from '@/admin/utils/helpers';
import { ISpecialtyValues, Lang } from '@/common/types';

export const AddSpecialtyPage = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const {
    displayedUserInfo: { employeeId, userType },
    isAdmin,
    selectedUserId,
    userInfo: { name },
  } = useContext(AppContext);

  const defaultHeader = t('newSpecialty');

  const defaultValues: ISpecialtyValues = {
    ...defaultSpecialtyValues,
    createdBy: name,
    language: language as Lang,
    metaTitle: defaultHeader,
    slug: getSlugText(defaultHeader),
    updatedBy: name,
    userType: userType,
    userId: isAdmin ? selectedUserId : employeeId,
  };

  return (
    <SpecialtyFormProvider defaultValues={defaultValues}>
      <SpecialtyProvider isNewSpecialty>
        <StorageBlobCleanProvider>
          <Specialty />
        </StorageBlobCleanProvider>
      </SpecialtyProvider>
    </SpecialtyFormProvider>
  );
};
