import { Stack, TextField, TextareaAutosize, styled } from '@mui/material';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, Slug } from '@/admin/components';
import {
  HOMEPAGE_TITLE_MAX_LENGTH,
  META_TITLE_MAX_LENGTH,
  languages,
} from '@/admin/consts';
import { SpecialtyContext } from '@/admin/providers';
import { getSlugText } from '@/admin/utils/helpers';
import { ISpecialtyValues } from '@/common/types';

export const SpecialtyMainHeader = () => {
  const { t } = useTranslation();
  const { isDefaultHomepageHeader } = useContext(SpecialtyContext);
  const { formState, getFieldState, getValues, setValue, watch } =
    useFormContext<ISpecialtyValues>();

  const { errors } = formState;

  const handleHeaderChange = (header: string) => {
    // set the default values for the slug and the meta title depending on the header
    // once the user changed the value directly, it won't change anymore
    if (getValues('isSEODefault')) {
      setValue('slug', getSlugText(header), { shouldDirty: true });
      setValue('metaTitle', header.slice(0, META_TITLE_MAX_LENGTH));
    }

    if (isDefaultHomepageHeader) {
      setValue('homepageHeader', header.slice(0, HOMEPAGE_TITLE_MAX_LENGTH));
    }
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={3}>
        <Controller
          name="header"
          render={({ field: { onChange, ...field } }) => (
            <TextField
              className="new-article-title"
              error={!!errors.header}
              helperText={t(errors.header?.message)}
              id="header"
              placeholder={t('newSpecialty')}
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: { maxRows: 2 },
              }}
              onChange={(e) => {
                const value = e.target.value.replace(/(\r\n|\n|\r)/gm, '');
                onChange(value);
                handleHeaderChange(value);
              }}
              onPaste={(e) => {
                handleHeaderChange(
                  e.clipboardData.getData('text').replace(/(\r\n|\n|\r)/gm, '')
                );
              }}
              {...field}
            />
          )}
        />

        <StyledLanguage>
          <Controller
            name="language"
            render={({ field: { ref, ...field } }) => (
              <Select
                ariaLabel="Language"
                error={!!errors.language}
                helperText={t(errors.language?.message as string)}
                label={`${t('language')}:`}
                options={languages}
                ref={ref}
                {...field}
              />
            )}
          />
        </StyledLanguage>
      </Stack>

      <Slug
        articleName={watch('header')}
        articleType="mortgageServices"
        isSlugDirty={getFieldState('slug', formState).isDirty}
        language={watch('language')}
        slug={watch('slug')}
        status={getValues('status')}
      />
    </Stack>
  );
};

const StyledLanguage = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-0.5),
  width: 140,

  [theme.breakpoints.up('xl')]: { width: 160 },
}));
