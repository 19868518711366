import { styled } from '@mui/material';
import { ReactNode } from 'react';

type EditorScrollerProps = {
  children: ReactNode;
  onRef: (_floatingAnchorElem: HTMLDivElement) => void;
};

export const EditorScroller = ({ children, onRef }: EditorScrollerProps) => (
  <StyledEditorScroller>
    <StyledEditor ref={onRef}>{children}</StyledEditor>
  </StyledEditorScroller>
);

const StyledEditorScroller = styled('div')({
  cursor: 'text',
  display: 'block',
  height: 500,
  outline: 0,
  overflow: 'hidden auto',
  position: 'relative',
  resize: 'none',
});

const StyledEditor = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'relative',

  '& .contentEditable': {
    border: 0,
    fontSize: 15,
    display: 'block',
    minHeight: 'calc(100% - 16px)',
    outline: 0,
    padding: theme.spacing(2, 3, 3),
    position: 'relative',
    tabSize: 1,
    zIndex: 2,
  },

  '& .readOnly': { padding: '0px' },
}));
