import { Stack, styled } from '@mui/material';

import { Skeleton } from '@/admin/components';

export const SpecialtiesSkeletonTop = () => (
  <StyledTop>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={6}
    >
      <Skeleton width={125} height={56} />

      <Stack direction="row" justifyContent="space-between">
        <Skeleton width={162} height={40} />
        <Skeleton width={162} height={40} />
      </Stack>
    </Stack>
  </StyledTop>
);

export const SpecialtiesSkeletonFilters = () => (
  <Stack alignItems="center" direction="row" justifyContent="space-between">
    <Stack direction="row">
      <Skeleton width={220} height={59} />
      <Skeleton width={160} height={59} />
    </Stack>

    <Skeleton width={191} height={48} />
  </Stack>
);

export const SpecialtiesSkeletonTable = () => <Skeleton height={640} />;

export const SpecialtiesLayoutSkeleton = () => (
  <StyledStackLayout>
    <Skeleton width={256} height={48} />
    <Skeleton height={260} />
  </StyledStackLayout>
);

const StyledTop = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(5, 8, 0, 6),
}));

const StyledStackLayout = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4, 0, 1),
}));
