import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, Select, ToggleButtonGroup } from '@/admin/components';
import { AppContext } from '@/admin/providers';
import { IUserTypeOption } from '@/admin/types/common';
import { brandIcons, getBrand } from '@/admin/utils/helpers';
import { UserType, IUser } from '@/common/types';

const DISPLAYED_OPTIONS_COUNT = 3;

const filterOptions = createFilterOptions({
  stringify: (option: IUser) => option.name,
  limit: DISPLAYED_OPTIONS_COUNT,
});

type AdminToolbarSearchProps = {
  user: IUser;
  userOptions: IUser[];
  userType: string;
  handleUserChange: (newUser: IUser) => void;
  title?: string;
  userTypeOptions?: IUserTypeOption[];
  handleUserTypeChange?: (newUserType: UserType) => void;
};

export const AdminToolbarSearch = ({
  title,
  user,
  userOptions = [],
  userType,
  userTypeOptions,
  handleUserChange,
  handleUserTypeChange,
}: AdminToolbarSearchProps) => {
  const { t } = useTranslation();
  const {
    userInfo: { role },
  } = useContext(AppContext);

  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAutocompleteHidden, setIsAutocompleteHidden] =
    useState<boolean>(false);

  const isMultipleUserTypes = userTypeOptions && handleUserTypeChange;
  const caption = title ? `${title}:` : `${t('account')}:`;

  // toggle the autocomplete dropdown visibility
  useEffect(() => {
    setIsOpen(Boolean(isInputFocused && inputValue));
  }, [inputValue, isInputFocused]);

  // toggle the autocomplete visibility
  useEffect(() => {
    const currentUserType = userTypeOptions?.find(
      (userTypeOption) => userTypeOption.value === userType
    );

    if (currentUserType) {
      setIsAutocompleteHidden(
        Boolean(
          currentUserType?.isAutocompleteHidden ||
            (userOptions && userOptions.length === 1)
        )
      );
    }
  }, [userOptions, userType, userTypeOptions]);

  const handleOnInputBlur = () => {
    setIsInputFocused(false);
    setInputValue('');
  };

  const handleOnInputFocus = () => setIsInputFocused(true);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);

  const handleOnChange = (e: SyntheticEvent, value: IUser) => {
    handleUserChange(value);
    setIsOpen(false);
    setInputValue('');
  };

  return (
    <StyledSearchByUser>
      {/* user type select for the large screens */}
      {isMultipleUserTypes && (
        <StyledToggleButtonGroup
          className={isAutocompleteHidden ? '' : 'has-autocomplete'}
        >
          <ToggleButtonGroup
            ariaLabel={t('userType')}
            buttons={userTypeOptions}
            title={caption}
            value={userType}
            onChange={(e, user) => handleUserTypeChange(user as UserType)}
          />
        </StyledToggleButtonGroup>
      )}

      {!isAutocompleteHidden && (
        <>
          {/* user type select for the small screens */}
          {isMultipleUserTypes && (
            <StyledSelect>
              <Select
                ariaLabel={t('userType')}
                label={caption}
                options={userTypeOptions}
                value={userType}
                onChange={(e) =>
                  handleUserTypeChange(e.target.value as UserType)
                }
                renderValue={(type) =>
                  type ? undefined : <div>{t('all')}</div>
                }
              />
            </StyledSelect>
          )}

          <StyledAutocomplete
            className={isMultipleUserTypes ? 'is-multiple-user-types' : ''}
          >
            {!isMultipleUserTypes && (
              <Typography variant="caption">{caption}</Typography>
            )}

            <Autocomplete
              aria-label={t('userName')}
              disableClearable
              filterOptions={filterOptions}
              getOptionLabel={(option: IUser) => option.name}
              groupBy={
                userType ? undefined : (option: IUser) => t(option.userType)
              }
              noOptionsText={t('noResultsFound')}
              open={isOpen}
              options={userOptions.sort(
                (a, b) => -b.userType.localeCompare(a.userType)
              )}
              popupIcon={<Search />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('searchName', {
                    userType: t(userType).toLowerCase(),
                  })}
                  size="small"
                  onBlur={handleOnInputBlur}
                  onChange={handleOnInputChange}
                  onFocus={handleOnInputFocus}
                />
              )}
              renderOption={(props, option) => {
                const { name, networkId, userType } = option;
                const key = name + Math.random().toString(16);
                const brand = getBrand(networkId);
                const Icon = brandIcons[brand];

                if (role === 'm3Tech admin')
                  return (
                    <Box
                      component="li"
                      sx={{ display: 'flex !important' }}
                      {...props}
                      key={key}
                    >
                      <StyledIcon>
                        <IconSvg>
                          <Icon />
                        </IconSvg>
                      </StyledIcon>

                      <div>
                        <div>{name}</div>
                        {userType !== 'network' && (
                          <Typography variant="subtitle2">
                            {t(brand)}
                          </Typography>
                        )}
                      </div>
                    </Box>
                  );

                return (
                  <Box component="li" {...props} key={key}>
                    {name}
                  </Box>
                );
              }}
              value={user}
              onChange={handleOnChange}
            />
          </StyledAutocomplete>
        </>
      )}
    </StyledSearchByUser>
  );
};

const StyledSearchByUser = styled('div')({
  alignItems: 'flex-end',
  display: 'flex',
});

const StyledToggleButtonGroup = styled('div')(({ theme }) => ({
  '& .MuiToggleButtonGroup-root': {
    boxShadow: 'none',
  },

  '& .MuiToggleButtonGroup-grouped:first-of-type': {
    borderBottomLeftRadius: '4px !important',
  },

  '& .MuiToggleButtonGroup-grouped:last-of-type': {
    borderBottomRightRadius: '4px !important',
  },

  '&.has-autocomplete': {
    '& .MuiToggleButtonGroup-root': {
      borderRadius: '4px 0 0 4px',
    },

    '& .MuiToggleButtonGroup-grouped:last-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: '0 !important',
    },

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

const StyledSelect = styled('div')(({ theme }) => ({
  display: 'none',
  flexShrink: 0,
  width: '169px',

  '& .MuiOutlinedInput-root': { borderRadius: '4px 0 0 4px' },

  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}));

const StyledAutocomplete = styled('div')({
  flexGrow: 1,

  '&.is-multiple-user-types': {
    '& .MuiOutlinedInput-root': { borderRadius: '0 4px 4px 0' },
    '& .MuiOutlinedInput-notchedOutline': { borderLeft: 'none' },
  },
});

const StyledIcon = styled('div')(({ theme }) => ({
  fontSize: '28px',
  lineHeight: 0,
  marginRight: theme.spacing(2),
}));
