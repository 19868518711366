import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  PrivacyPolicyPost,
  EditPrivacyPoliciesSkeleton,
  ArticleError,
} from '@/admin/components';
import {
  defaultPrivacyPolicyValues,
  PrivacyPoliciesContext,
} from '@/admin/providers';
import { PrivacyPolicyFormProvider } from '@/admin/providers/form/PrivacyPolicyFormProvider';
import { IPrivacyPolicyValues } from '@/common/types';

export const EditPrivacyPolicy = () => {
  const { privacyPolicyId } = useParams();

  const { allPolicies, isLoading, isError, setIsError } = useContext(
    PrivacyPoliciesContext
  );

  const [privacyPoliciesData, setPrivacyPoliciesData] =
    useState<IPrivacyPolicyValues>(defaultPrivacyPolicyValues);

  const getPrivacyPoliciesDataById = async (PrivacyPoliciesId: string) => {
    try {
      const policy = allPolicies.find(
        (policy) => policy.id === PrivacyPoliciesId
      );

      if (policy) {
        setIsError(false);
        setPrivacyPoliciesData(policy);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (privacyPolicyId) {
      getPrivacyPoliciesDataById(privacyPolicyId);
    }
  }, [allPolicies, privacyPolicyId]);

  if (isLoading) return <EditPrivacyPoliciesSkeleton />;

  if (isError) return <ArticleError />;

  return (
    <PrivacyPolicyFormProvider defaultValues={privacyPoliciesData}>
      <PrivacyPolicyPost
        getPrivacyPolicyDataById={getPrivacyPoliciesDataById}
      />
    </PrivacyPolicyFormProvider>
  );
};
