import { FormHelperText, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrivacyPolicyEditor } from '@/admin/components/';

export const PrivacyPolicyContentBody = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <PrivacyPolicyEditor />

      <StyledFormHelperText
        error={!!errors.policyTextEn || !!errors.policyTextFr}
      >
        {t(errors.policyTextEn?.message as string)}
      </StyledFormHelperText>
    </>
  );
};

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
