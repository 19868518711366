import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SidebarDetails } from '@/admin/components';
import { getLocalizedDate } from '@/admin/utils/helpers';

export const BlogInfoDetails = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { getValues } = useFormContext();

  const dateCreated = getLocalizedDate(
    language,
    getValues('dateCreated') || new Date().toString()
  );
  const dateUpdated = getValues('dateUpdated');
  const lastSaved = dateUpdated && getLocalizedDate(language, dateUpdated);
  const updatedBy = getValues('updatedBy');
  const status = getValues('status');

  return (
    <SidebarDetails
      dateCreated={dateCreated}
      dateUpdated={dateUpdated}
      lastSaved={lastSaved}
      status={status}
      title={t('blogDetails')}
      updatedBy={updatedBy}
    />
  );
};
