import { SelectChangeEvent, Stack, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, ToggleButtonGroup } from '@/admin/components';
import {
  languages,
  sortingOptionsSpecialties,
  statusFiltersSpecialties,
} from '@/admin/consts';
import { SpecialtiesContext } from '@/admin/providers';
import {
  SortingOrderSpecialties,
  SpecialtiesFilter,
  SpecialtyStatus,
} from '@/common/types';

type SpecialtiesFiltersProps = {
  language: string;
  order: SortingOrderSpecialties;
  status: SpecialtyStatus | '';
  handleFiltersChange: (filter: SpecialtiesFilter, value: string) => void;
  setOrder: (order: SortingOrderSpecialties) => void;
};

export const SpecialtiesFilters = ({
  language,
  order,
  status,
  handleFiltersChange,
  setOrder,
}: SpecialtiesFiltersProps) => {
  const { t } = useTranslation();
  const { setCurrentPage } = useContext(SpecialtiesContext);

  const handleOrderChange = (e: SelectChangeEvent) => {
    setOrder(e.target.value as SortingOrderSpecialties);
    // reset pages when order is changed
    setCurrentPage(1);
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <Stack direction="row" spacing={3}>
        <ToggleButtonGroup
          ariaLabel="status"
          buttons={statusFiltersSpecialties}
          title={`${t('status')}:`}
          value={status}
          onChange={(e, status) => handleFiltersChange('status', status)}
        />

        <ToggleButtonGroup
          ariaLabel="language"
          buttons={languages}
          title={`${t('language')}:`}
          value={language}
          onChange={(e, language) => handleFiltersChange('language', language)}
        />
      </Stack>

      <StyledSelect>
        <Select
          ariaLabel="Sort by"
          label={`${t('sortBy')}:`}
          options={sortingOptionsSpecialties}
          value={order}
          onChange={handleOrderChange}
        />
      </StyledSelect>
    </Stack>
  );
};

const StyledSelect = styled('div')({ width: 166 });
