import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SpecialtiesLayout, Top } from '@/admin/components';

type SpecialtiesTopProps = {
  lang: string;
};

export const SpecialtiesTop = ({ lang }: SpecialtiesTopProps) => {
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onLayoutClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <Top link={t('addSpecialty')} title={t('mortgageServices')}>
        <Button
          startIcon={<DashboardOutlinedIcon />}
          variant="outlined"
          onClick={onLayoutClick}
        >
          {t('layout')}
        </Button>
      </Top>

      {isDialogOpen && (
        <SpecialtiesLayout
          isDialogOpen={isDialogOpen}
          lang={lang}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
    </>
  );
};
