import { Paper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '@/admin/components';

type SpecialtiesEmptyStateProps = {
  handleAddSpecialty: () => void;
};

export const SpecialtiesEmptyState = ({
  handleAddSpecialty,
}: SpecialtiesEmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <EmptyState
        button={t('addSpecialty')}
        buttonVariant="outlined"
        icon="work"
        iconFill={0}
        title={t('noSpecialtiesDefined')}
        onButtonClick={handleAddSpecialty}
      />
    </StyledBox>
  );
};

const StyledBox = styled(Paper)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3),
  minHeight: 820,
}));
