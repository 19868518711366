import { Button, Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Header } from '@/admin/components';
import { BLOGS_PATH } from '@/admin/consts/paths';

type BlogLinkBackProps = {
  disabled: boolean;
  isNewBlog: boolean;
  handleDelete: () => void;
  handlePreview: () => void;
  handlePublish: () => void;
  handleSave: (sessionTimeout: boolean) => void;
};

export const BlogLinkBack = ({
  disabled,
  isNewBlog,
  handleDelete,
  handlePreview,
  handlePublish,
  handleSave,
}: BlogLinkBackProps) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();
  const status = getValues('status');

  return (
    <Header title={t('allBlogs')} to={BLOGS_PATH}>
      <Button disabled={disabled} onClick={handleDelete}>
        {t('delete')}
      </Button>

      <Divider orientation="vertical" flexItem />

      <Button onClick={handlePreview}>{t('preview')}</Button>

      <Divider orientation="vertical" flexItem />

      {status === 'draft' && (
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={() => handleSave(false)}
        >
          {isNewBlog ? t('save') : t('update')}
        </Button>
      )}

      <Button variant="contained" disabled={disabled} onClick={handlePublish}>
        {status === 'draft' ? t('publish') : t('update')}
      </Button>
    </Header>
  );
};
