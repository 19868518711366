import { styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select } from '@/admin/components';
import { languages } from '@/admin/consts/common';
import { IBlogData } from '@/common/types';

export const BlogContentHeaderLanguage = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext<IBlogData>();

  return (
    <StyledLanguageSelect>
      <Controller
        name="language"
        render={({ field: { ref, ...field } }) => (
          <Select
            ariaLabel="Language"
            error={!!errors.language}
            helperText={t(errors.language?.message as string)}
            label={`${t('language')}:`}
            options={languages}
            ref={ref}
            {...field}
          />
        )}
      />
    </StyledLanguageSelect>
  );
};

const StyledLanguageSelect = styled('div')(({ theme }) => ({
  width: 140,

  [theme.breakpoints.up('xl')]: { width: 160 },
}));
