import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import {
  FETCH_BLOGS_NAMES_URL,
  FETCH_BLOGS_URL,
  FETCH_BLOG_BY_ID_URL,
  FETCH_BLOG_SLUGS_URL,
} from '@/admin/consts';
import { getHeaders, getFiltersQuery } from '@/admin/utils/helpers-api';
import {
  IBlogData,
  IBlogsFilters,
  ISlugData,
  SortingOrderBlogs,
} from '@/common/types';

export const addBlogApi = async (data: IBlogData, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOGS_URL}/`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  const { status, data: blogData } = await res.json();

  return { status, blogId: blogData.id };
};

export const deleteBlogApi = async (blogId: string, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOGS_URL}/${blogId}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { method: 'DELETE', headers });
  const { status } = await res.json();

  return status;
};

export const getBlogsApi = async (
  {
    filters,
    limit,
    offset,
    order,
    token,
  }: {
    token: string;
    filters?: IBlogsFilters;
    limit?: number;
    offset?: number;
    order?: SortingOrderBlogs;
  },
  signal?: AbortSignal
) => {
  // optional queries
  const limitQuery = limit ? `&limit=${limit}` : '';
  const offsetQuery = offset ? `&offset=${offset}` : '';
  const filtersQuery = filters ? getFiltersQuery(filters) : '';
  const orderQuery = order ? `&order=${order}` : '';

  const queries = `${limitQuery}${offsetQuery}${orderQuery}${filtersQuery}`;
  const urlQueries = queries ? `?${queries.substring(1)}` : '';

  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOGS_URL}${urlQueries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { signal, headers });
  const { data }: { data: { blogs: IBlogData[]; total: number } } =
    await res.json();

  return data;
};

export const getBlogByIdApi = async (
  token: string,
  blogId: string,
  userId?: number
) => {
  const userIdIdQuery = userId ? `?userId=${userId}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOG_BY_ID_URL}/${blogId}${userIdIdQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { status, data } = await res.json();

  return { status, data };
};

export const getBlogsNamesApi = async (
  filters: IBlogsFilters,
  token: string,
  userId?: number
) => {
  const filtersQuery = getFiltersQuery(filters);
  const userIdQuery = userId ? `&userId=${userId}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOGS_NAMES_URL}?${filtersQuery}${userIdQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data } = (await res.json()) as { data: string[] };

  return data;
};

export const updateBlogApi = async (updatedBlog: IBlogData, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOGS_URL}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(updatedBlog),
  });
  const { status } = await res.json();

  return status;
};

export const getBlogSlugsApi = async (token: string, userId?: number) => {
  const userIdIdQuery = userId ? `?userId=${userId}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_BLOG_SLUGS_URL}${userIdIdQuery}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data } = (await res.json()) as {
    data: ISlugData[];
  };

  return data;
};
