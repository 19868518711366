import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import {
  $getRoot,
  EditorState,
  LexicalEditor,
  ParagraphNode,
  TextNode,
} from 'lexical';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EditorContainer,
  EditorContent,
  EditorScroller,
} from '@/admin/components';
import {
  CustomHeadingNode,
  CustomListItemNode,
  CustomParagraphNode,
  CustomTextNode,
  ImageNode,
  VideoNode,
} from '@/admin/components/editor/editorNodes';
import {
  AutoEmbedPlugin,
  ClickableLinkPlugin,
  DragDropPastePlugin,
  FloatingLinkEditorPlugin,
  ImagePlugin,
  ListMaxIndentLevelPlugin,
  OnLoadPlugin,
  TableCellActionMenuPlugin,
  TableCellResizerPlugin,
  ToolbarPlugin,
  // TreeViewPlugin,
  VideoPlugin,
} from '@/admin/components/editor/editorPlugins';
import { editorTheme } from '@/admin/components/editor/editorTheme';
import { HOMEPAGE_DESCRIPTION_MAX_LENGTH } from '@/admin/consts';
import { MATCHERS } from '@/admin/consts/editor';
import { SpecialtyContext } from '@/admin/providers';
import { parseDomFromString } from '@/admin/utils/helpers';
import { ISpecialtyValues } from '@/common/types';

export const SpecialtyEditor = () => {
  const { t } = useTranslation();
  const { isDefaultHomepageDescription } = useContext(SpecialtyContext);
  const {
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useFormContext<ISpecialtyValues>();

  const initialConfig = {
    namespace: 'Editor',
    nodes: [
      AutoLinkNode,
      CustomHeadingNode,
      CustomListItemNode,
      CustomParagraphNode,
      CustomTextNode,
      ImageNode,
      LinkNode,
      ListNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      VideoNode,
      {
        replace: HeadingNode,
        with: (node: HeadingNode) => {
          return new CustomHeadingNode(node.__tag, node.__key);
        },
      },
      {
        replace: ListItemNode,
        with: (node: ListItemNode) => {
          return new CustomListItemNode(
            node.__value,
            node.__checked,
            node.__key
          );
        },
      },
      {
        replace: ParagraphNode,
        with: (node: ParagraphNode) => {
          return new CustomParagraphNode(node.__key);
        },
      },
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return new CustomTextNode(node.__text, node.__key);
        },
      },
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: editorTheme,
    editorState: (editor: LexicalEditor) => {
      const dom = parseDomFromString(getValues('description'));
      const nodes = $generateNodesFromDOM(editor, dom);
      const rootNode = $getRoot();
      rootNode.append(...nodes);
    },
  };

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      // set blogContent form value
      const htmlString = $generateHtmlFromNodes(editor);

      setValue('description', htmlString, { shouldDirty: true });

      // clear required error manually when contents exist
      if (errors.description && htmlString) clearErrors('description');

      const paragraphText: string[] = [];

      editorState._nodeMap.forEach((node) => {
        const parent = node.getParent();
        if (
          node.__type === 'text' &&
          parent?.__type === 'paragraph' &&
          parent?.__parent === 'root'
        ) {
          paragraphText.push(node.getTextContent());
        }
      });

      const description = paragraphText.join(' ');

      if (getValues('isSEODefault')) {
        setValue(
          'metaDescription',
          description.slice(0, HOMEPAGE_DESCRIPTION_MAX_LENGTH)
        );
      }

      if (isDefaultHomepageDescription) {
        setValue(
          'homepageDescription',
          description.slice(0, HOMEPAGE_DESCRIPTION_MAX_LENGTH)
        );
      }
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorContainer>
        <ToolbarPlugin className={errors.description ? 'error' : ''} />

        <EditorContent className={errors.description ? 'error' : ''}>
          <RichTextPlugin
            contentEditable={
              <EditorScroller onRef={onRef}>
                <ContentEditable className="contentEditable" />
              </EditorScroller>
            }
            placeholder={
              <div className="editor-placeholder">
                {t('specialtyDescriptionPlaceholder')}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />

          <AutoFocusPlugin />
          <OnLoadPlugin />
          <OnChangePlugin onChange={handleChange} />

          <HistoryPlugin />

          <LinkPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <ClickableLinkPlugin />

          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />

          <ImagePlugin />
          <DragDropPastePlugin />

          <AutoEmbedPlugin />
          <VideoPlugin />

          <TablePlugin />

          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <TableCellResizerPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
        </EditorContent>
      </EditorContainer>
    </LexicalComposer>
  );
};
