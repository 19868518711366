import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlogsTableActions,
  Link,
  Pagination,
  StatusChip,
  TableEmptyState,
} from '@/admin/components';
import { ADD_PATH, ROWS_PER_PAGE, ACTIONS_COLUMN_WIDTH } from '@/admin/consts';
import { BlogsContext } from '@/admin/providers';
import { IBlogsPageColumn } from '@/admin/types/common';

type BlogsTableProps = {
  isNoBlogs: boolean;
  isNoBlogsByFilters: boolean;
  getBlogs: () => void;
  handleChangePage: (e: unknown, newPage: number) => void;
};

const columns: IBlogsPageColumn[] = [
  { id: 'blogName', label: 'title' },
  { id: 'dateUpdated', label: 'lastModified' },
  { id: 'status', label: 'status' },
  { id: 'actions', label: 'actions' },
];

export const BlogsTable = ({
  isNoBlogs,
  isNoBlogsByFilters,
  getBlogs,
  handleChangePage,
}: BlogsTableProps) => {
  const { t } = useTranslation();
  const { blogsList, currentPage, totalCount } = useContext(BlogsContext);

  const blogs = blogsList[currentPage];
  const pagesCount = Math.ceil(totalCount / ROWS_PER_PAGE);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(({ align, id, label }) => (
                <StyledTableCellHead key={id} align={align}>
                  {t(label)}
                </StyledTableCellHead>
              ))}
            </TableRow>
          </TableHead>

          {isNoBlogs && (
            <TableEmptyState
              linkText={t('newBlog')}
              linkTo={ADD_PATH}
              message={t('noBlogsToReviewMessage')}
              title={t('noBlogsToReview')}
            />
          )}

          {isNoBlogsByFilters && (
            <TableEmptyState
              message={t('pleaseTryAnotherSearchTerm')}
              title={t('noBlogsFound')}
            />
          )}

          {!isNoBlogs && !isNoBlogsByFilters && (
            <TableBody>
              {blogs.map((blog, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(({ align, id }) => {
                    if (id === 'blogName') {
                      return (
                        <TableCell key={id} align={align}>
                          <Link className="max-two-lines-text" to={blog.id}>
                            {blog[id]}
                          </Link>
                        </TableCell>
                      );
                    }

                    if (id === 'status') {
                      return (
                        <TableCell key={id} align={align}>
                          <StatusChip status={blog.status} />
                        </TableCell>
                      );
                    }

                    if (id === 'actions') {
                      return (
                        <StyledTableCell key={id} align={align}>
                          <BlogsTableActions blog={blog} getBlogs={getBlogs} />
                        </StyledTableCell>
                      );
                    }

                    return (
                      <TableCell key={id} align={align}>
                        <span>{blog[id] as string}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {pagesCount > 1 && (
        <StyledPagination>
          <Pagination
            page={currentPage}
            count={pagesCount}
            handleChangePage={handleChangePage}
          />
        </StyledPagination>
      )}
    </>
  );
};

const StyledTableCellHead = styled(TableCell)({
  width: `expression( 100% - ${ACTIONS_COLUMN_WIDTH}px) / 3)`, // 4 = columns.length - 1

  '&:last-child': { width: `${ACTIONS_COLUMN_WIDTH}px` },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
}));

const StyledPagination = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  paddingTop: theme.spacing(2),
}));
