import {
  FormControl,
  FormHelperText,
  TextField as MuiTextField,
  Typography,
  styled,
} from '@mui/material';
import {
  ChangeEvent,
  ClipboardEventHandler,
  FocusEvent,
  forwardRef,
} from 'react';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TextFieldProps = {
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  caption?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  name?: string;
  placeholder?: string;
  subText?: string;
  type?: string;
  value?: string;
  inputProps?: { [key: string]: string | boolean };
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onPaste?: ClipboardEventHandler<HTMLDivElement> | undefined;
};

export const TextField = forwardRef<RefCallBack, TextFieldProps>(
  (
    {
      caption,
      disabled,
      error,
      helperText,
      label,
      multiline = false,
      name,
      placeholder,
      subText,
      type,
      inputProps,
      value,
      onBlur,
      onChange,
      onPaste,
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <FormControl disabled={disabled} fullWidth size="small">
        <StyledTypography
          className={disabled ? 'disabled' : ''}
          variant="caption"
        >
          {`${label}:`}
        </StyledTypography>

        {subText && <StyledHelperTypography>{subText}</StyledHelperTypography>}

        {caption && (
          <StyledFormHelperCaption>{caption}</StyledFormHelperCaption>
        )}

        <MuiTextField
          error={error}
          fullWidth
          helperText={t(helperText as string)}
          inputProps={inputProps}
          inputRef={ref}
          multiline={multiline}
          name={name}
          placeholder={placeholder}
          rows={5}
          size="small"
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onPaste={onPaste}
        />
      </FormControl>
    );
  }
);

TextField.displayName = 'TextField';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),

  '&.disabled': {
    color: theme.palette.text.secondary,
  },
}));

const StyledHelperTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  overflowWrap: 'break-word',
  fontStyle: 'italic',
  fontSize: 14,
}));

const StyledFormHelperCaption = styled(FormHelperText)({
  position: 'absolute',
  right: 0,
});
