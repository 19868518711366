import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  styled,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectOption } from '@/admin/types/common';

type ToggleButtonGroupBgProps = {
  ariaLabel: string;
  buttons: ISelectOption[];
  value: string;
  onChange: (e: SyntheticEvent, newValue: string) => void;
};

export const ToggleButtonGroupBg = ({
  ariaLabel,
  buttons,
  value,
  onChange,
}: ToggleButtonGroupBgProps) => {
  const { t } = useTranslation();

  return (
    <StyledToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={onChange}
      aria-label={ariaLabel}
    >
      {buttons.map((button) => (
        <StyledToggleButton
          key={button.value}
          value={button.value}
          size="small"
        >
          {t(button.label)}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[50]}`,
  boxShadow: 'none',
  padding: theme.spacing(0.5),
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: '1px solid transparent !important',
  letterSpacing: 'normal',
  lineHeight: '24px',
  marginLeft: '0 !important',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1, 2),
  transition: 'all 0.3s',

  '&:last-of-type': { marginRight: 0 },

  '&.Mui-selected': {
    borderColor: `${theme.palette.primary.main} !important`,

    '&::after': { display: 'none' },
  },

  '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': { borderRadius: 4 },
  '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': { borderRadius: 4 },
}));
