import { createContext, useEffect, useState } from 'react';

import { ROWS_PER_PAGE } from '@/admin/consts';
import { FC } from '@/admin/types/common';
import { ISpecialtyValues } from '@/common/types';

interface ISpecialtiesByPage {
  [page: number]: ISpecialtyValues[];
}

interface ISpecialtiesContext {
  currentPage: number;
  currentSpecialties: ISpecialtyValues[];
  pagesCount: number;
  specialties: ISpecialtyValues[] | null;
  setCurrentPage: (currentPage: number) => void;
  setSpecialties: (specialties: ISpecialtyValues[]) => void;
}

const initialContext: ISpecialtiesContext = {
  currentPage: 1,
  currentSpecialties: [],
  pagesCount: 0,
  specialties: [],
  setCurrentPage: () => {},
  setSpecialties: () => {},
};

export const SpecialtiesContext =
  createContext<ISpecialtiesContext>(initialContext);

export const SpecialtiesProvider = ({ children }: FC) => {
  const [specialties, setSpecialties] = useState<ISpecialtyValues[] | null>(
    null
  );
  const [specialtiesByPage, setSpecialtiesByPage] =
    useState<ISpecialtiesByPage | null>(null);
  const [currentSpecialties, setCurrentSpecialties] = useState<
    ISpecialtyValues[]
  >([]);

  const [pagesCount, setPagesCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (specialties && specialties.length) {
      const pagesCount = Math.ceil(specialties.length / ROWS_PER_PAGE);
      const specialtiesByPage: ISpecialtiesByPage = {};

      for (let i = 0; i < pagesCount; i++) {
        specialtiesByPage[i + 1] = specialties.slice(
          ROWS_PER_PAGE * i,
          ROWS_PER_PAGE * (i + 1)
        );
      }

      setPagesCount(pagesCount);
      setSpecialtiesByPage(specialtiesByPage);
      setCurrentSpecialties(specialtiesByPage[currentPage]);
    }
  }, [specialties]);

  useEffect(() => {
    if (specialtiesByPage)
      setCurrentSpecialties(specialtiesByPage[currentPage]);
  }, [currentPage]);

  return (
    <SpecialtiesContext.Provider
      value={{
        currentPage,
        currentSpecialties,
        pagesCount,
        specialties,
        setCurrentPage,
        setSpecialties,
      }}
    >
      {children}
    </SpecialtiesContext.Provider>
  );
};
