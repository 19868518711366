import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SidebarDetails } from '@/admin/components';
import { getLocalizedDate } from '@/admin/utils/helpers';
import { ISpecialtyValues } from '@/common/types';

export const SpecialtySidebarDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getValues } = useFormContext<ISpecialtyValues>();

  const dateCreated = getLocalizedDate(
    language,
    getValues('dateCreated') || new Date().toString()
  );

  const dateUpdated = getValues('dateUpdated')
    ? getLocalizedDate(language, getValues('dateUpdated') as number)
    : null;

  return (
    <SidebarDetails
      dateCreated={dateCreated}
      dateUpdated={dateUpdated}
      lastSaved={dateUpdated}
      status={getValues('status')}
      title={t('details')}
      updatedBy={getValues('updatedBy')}
    />
  );
};
