import { Stack } from '@mui/material';
import { ChangeEventHandler } from 'react';

import {
  BlogContentBody,
  BlogContentHeader,
} from '@/admin/components/blogPost';

type BlogContentProps = {
  handleVisibilityChange: ChangeEventHandler;
};

export const BlogContent = ({ handleVisibilityChange }: BlogContentProps) => (
  <Stack spacing={3}>
    <BlogContentHeader handleVisibilityChange={handleVisibilityChange} />
    <BlogContentBody />
  </Stack>
);
