import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
} from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Dialog } from '@/admin/components';
import { SNACKBAR_TITLE_MAX_LENGTH } from '@/admin/consts/blogs';
import { useModal } from '@/admin/hooks';
import {
  AppContext,
  BlogsContext,
  ThemeContext,
  useStorageBlobCleanContext,
} from '@/admin/providers';
import { IAction } from '@/admin/types/common';
import { getShortenedText } from '@/admin/utils/helpers';
import { deleteBlogApi, updateBlogApi } from '@/admin/utils/helpers-api';
import { IBlogData } from '@/common/types';

type BlogsTableActionsProps = {
  blog: IBlogData;
  getBlogs: () => void;
};

export const BlogsTableActions = ({
  blog,
  getBlogs,
}: BlogsTableActionsProps) => {
  const { t } = useTranslation();
  const {
    userInfo: { name },
    getAccessToken,
  } = useContext(AppContext);
  const { clearBlogsList } = useContext(BlogsContext);
  const { openSnackbar } = useContext(ThemeContext);
  const { getImagesFromDom, removeAllImageSrc } = useStorageBlobCleanContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { modalContent, showModal, handleModalClose } = useModal();

  const { authorName, blogContent, blogName, id, imageURL, status } = blog;

  const trimmedBlogName =
    getShortenedText(blogName, SNACKBAR_TITLE_MAX_LENGTH, true) || t('blog');

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    handleModalClose();
    closePopover();
  };

  const handlePublishClick = () => {
    showModal({
      message: t('publishDialogMessage', { authorName }),
      title: t('publishDialogTitle'),
      handleAgree: agreePublishDialog,
      handleClose: closeDialog,
    });
  };

  const handleHideClick = () => {
    showModal({
      message: t('hideDialogMessage'),
      title: t('hideDialogTitle'),
      handleAgree: agreeHideDialog,
      handleClose: closeDialog,
    });
  };

  const handleUnhideClick = () => {
    showModal({
      message: t('unhideDialogMessage'),
      title: t('unhideDialogTitle'),
      handleAgree: agreeUnhideDialog,
      handleClose: closeDialog,
    });
  };

  const handleDeleteClick = () => {
    showModal({
      message: t('deleteDialogMessage'),
      title: t('deleteDialogTitle'),
      handleAgree: agreeDeleteDialog,
      handleClose: closeDialog,
    });
  };

  const agreePublishDialog = async () => {
    try {
      const updatedBlog: IBlogData = {
        ...blog,
        status: 'published',
        updatedBy: name,
      };
      const token = await getAccessToken();
      const status = await updateBlogApi(updatedBlog, token);

      if (status === 200) {
        handleSuccessAction(t('publishSnackbarMessage', { trimmedBlogName }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const agreeHideDialog = async () => {
    try {
      const updatedBlog: IBlogData = {
        ...blog,
        status: 'hidden',
        updatedBy: name,
      };
      const token = await getAccessToken();
      const status = await updateBlogApi(updatedBlog, token);

      if (status === 200) {
        handleSuccessAction(t('hideSnackbarMessage', { trimmedBlogName }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const agreeUnhideDialog = async () => {
    try {
      const updatedBlog: IBlogData = {
        ...blog,
        status: 'published',
        updatedBy: name,
      };
      const token = await getAccessToken();
      const status = await updateBlogApi(updatedBlog, token);

      if (status === 200) {
        handleSuccessAction(t('publishSnackbarMessage', { trimmedBlogName }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const agreeDeleteDialog = async () => {
    try {
      const token = await getAccessToken();
      const status = await deleteBlogApi(id, token);

      if (status === 200) {
        const imagesFromDom = getImagesFromDom(blogContent, imageURL);
        removeAllImageSrc(token, imagesFromDom);
        handleSuccessAction(t('deleteSnackbarMessage', { trimmedBlogName }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSuccessAction = (snackbarMessage: string) => {
    clearBlogsList();
    getBlogs();
    closePopover();
    handleModalClose();
    openSnackbar(snackbarMessage);
  };

  const [
    editAction,
    publishAction,
    hideAction,
    unhideAction,
    deleteAction,
  ]: IAction[] = [
    { id: 'edit', title: t('edit'), to: id },
    { id: 'publish', title: t('publish'), onClick: handlePublishClick },
    { id: 'hide', title: t('hide'), onClick: handleHideClick },
    { id: 'unhide', title: t('unhide'), onClick: handleUnhideClick },
    { id: 'delete', title: t('delete'), onClick: handleDeleteClick },
  ];
  let actions: IAction[] = [];

  if (status === 'draft') {
    actions = [editAction, publishAction, deleteAction];
  }

  if (status === 'published') {
    actions = [editAction, hideAction, deleteAction];
  }

  if (status === 'hidden') {
    actions = [editAction, unhideAction, deleteAction];
  }

  return (
    <>
      <div>
        <IconButton onClick={openPopover}>
          <MoreHorizIcon />
        </IconButton>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <StyledList>
            {actions.map(({ id, title, to, onClick }) => (
              <ListItem disablePadding key={id}>
                {/* link */}
                {to && (
                  <ListItemButton>
                    <ListItemText>
                      <Link to={to}>{title}</Link>
                    </ListItemText>
                  </ListItemButton>
                )}

                {/* button */}
                {!to && (
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={title} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </StyledList>
        </Popover>
      </div>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />
    </>
  );
};

const StyledList = styled(List)({ width: 120 });
