import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { SidebarSeoEditModal } from '@/admin/components';
import { metaDataSchema } from '@/admin/consts';
import { defaultMetaDataValues } from '@/admin/providers';
import { isSlugUnique } from '@/admin/utils/helpers';
import { ISEOData, ISlugData, ISpecialtyValues } from '@/common/types';

type SpecialtySidebarSeoEditModalProps = {
  open: boolean;
  slugsData: ISlugData[];
  handleCloseEditModal: () => void;
  handleSaveEditModal: (values: ISEOData) => void;
};

export const SpecialtySidebarSeoEditModal = ({
  open,
  slugsData,
  handleCloseEditModal,
  handleSaveEditModal,
}: SpecialtySidebarSeoEditModalProps) => {
  const { getValues } = useFormContext<ISpecialtyValues>();

  const [errors, setErrors] = useState<ISEOData>(defaultMetaDataValues);
  const [values, setValues] = useState<ISEOData>(defaultMetaDataValues);
  const [isSlugError, setIsSlugError] = useState<boolean>(false);

  const id = getValues('id');

  useEffect(() => {
    if (open) {
      setValues({
        slug: getValues('slug'),
        title: getValues('metaTitle'),
        description: getValues('metaDescription'),
      });
    }
  }, [open]);

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e);
    setIsSlugError(!isSlugUnique(slugsData, e.target.value, id));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validateInputValue(name, value);
  };

  const onClose = () => {
    handleCloseEditModal();
    setErrors(defaultMetaDataValues);
  };

  const onSave = () => {
    const hasNoError = !Object.values(errors).filter((err) => err).length;
    if (hasNoError) handleSaveEditModal(values);
  };

  const validateInputValue = (key: string, value: string) => {
    metaDataSchema
      .validate({ ...values, [key]: value }, { abortEarly: false })
      .then(() => {
        setErrors(defaultMetaDataValues);
      })
      .catch((err) => {
        const newErrors: ISEOData = { ...defaultMetaDataValues };

        err.inner.map((e: yup.ValidationError) => {
          if (e.path) {
            newErrors[e.path as keyof ISEOData] = e.message;
          }
        });

        setErrors(newErrors);
      });
  };

  return (
    <SidebarSeoEditModal
      errors={errors}
      isSlugError={isSlugError}
      language={getValues('language')}
      open={open}
      slugSubPath="mortgageServices"
      values={values}
      handleChangeDescription={handleInputChange}
      handleChangeSlug={handleChangeSlug}
      handleChangeTitle={handleInputChange}
      handleClose={onClose}
      handleSave={onSave}
    />
  );
};
