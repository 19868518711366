import * as yup from 'yup';

import {
  ISelectOption,
  InputFileError,
  UserNetworkId,
} from '@/admin/types/common';
import {
  IUser,
  SortingOrderBlogs,
  SortingOrderSpecialties,
  SortingOrderTestimonials,
} from '@/common/types';

export const META_DESCRIPTION_MAX_LENGTH = 150;
export const META_TITLE_MAX_LENGTH = 60;

export const ACCEPTED_IMAGE_TYPES: string = 'image/png, image/jpeg, image/jpg';

export const ADMIN_TOOLBAR_HEIGHT = 104;

export const SESSION_TIME_DETECT_START = 50 * 60 * 1000; // 50 minutes
export const SESSION_TIME_DETECT_END = 5 * 60 * 1000; // 5 minutes

export const ROWS_PER_PAGE = 10;

export const baseLinksByThemeName = {
  development: {
    invis: {
      en: 'https://develop.invis.ca/en/',
      fr: 'https://develop.invis.ca/fr/',
    },
    mortgagealliance: {
      en: 'https://dev.mortgagealliance.com/en/',
      fr: 'https://dev.mortgagealliance.com/fr/',
    },
    mortgageintelligence: {
      en: 'https://dev.migroup.ca/en/',
      fr: 'https://develop.intelligencehypothecaire.ca/fr/',
    },
    'multi-prets': {
      en: 'https://dev.multi-prets.com/en/',
      fr: 'https://dev.multi-prets.com/fr/',
    },
  },
  production: {
    invis: {
      en: 'https://www.invis.ca/en/',
      fr: 'https://www.invis.ca/fr/',
    },
    mortgagealliance: {
      en: 'https://www.mortgagealliance.com/en/',
      fr: 'https://www.mortgagealliance.com/fr/',
    },
    mortgageintelligence: {
      en: 'https://www.mortgageintelligence.ca/en/',
      fr: 'https://www.intelligencehypothecaire.ca/fr/',
    },
    'multi-prets': {
      en: 'https://www.multi-prets.com/en/',
      fr: 'https://www.multi-prets.com/fr/',
    },
  },
  staging: {
    invis: {
      en: 'https://staging.invis.ca/en/',
      fr: 'https://staging.invis.ca/fr/',
    },
    mortgagealliance: {
      en: 'https://staging.mortgagealliance.com/en/',
      fr: 'https://staging.mortgagealliance.com/fr/',
    },
    mortgageintelligence: {
      en: 'https://staging.mortgageintelligence.ca/en/',
      fr: 'https://staging.intelligencehypothecaire.ca/fr/',
    },
    'multi-prets': {
      en: 'https://staging1.multi-prets.com/en/',
      fr: 'https://staging1.multi-prets.com/fr/',
    },
  },
};

export const blogPath = {
  broker: {
    en: 'mortgage-broker/',
    fr: 'courtier-hypothecaire/',
  },
  office: {
    en: 'office/',
    fr: 'bureau/',
  },
  team: {
    en: 'team/',
    fr: 'equipe/',
  },
  franchise: {
    en: 'franchise/',
    fr: 'franchise/',
  },
};

export const languages: ISelectOption[] = [
  { label: 'english', value: 'en' },
  { label: 'french', value: 'fr' },
];

export const languagesWithAll: ISelectOption[] = [
  { label: 'all', value: '' },
  ...languages,
];

export const shortLanguages: ISelectOption[] = [
  { label: 'en', value: 'en' },
  { label: 'fr', value: 'fr' },
];

export const shortLanguagesWithAll: ISelectOption[] = [
  { label: 'all', value: '' },
  ...shortLanguages,
];

export const sortingOptionsBlogs: {
  label: string;
  value: SortingOrderBlogs;
}[] = [
  { label: 'mostRecent', value: 'dateDesc' },
  { label: 'oldest', value: 'dateAsc' },
  { label: 'azBlog', value: 'blogName' },
];

export const sortingOptionsTestimonials: {
  label: string;
  value: SortingOrderTestimonials;
}[] = [
  { label: 'mostRecent', value: 'dateDesc' },
  { label: 'oldest', value: 'dateAsc' },
  { label: 'highestRated', value: 'ratingDesc' },
  { label: 'lowestRated', value: 'ratingAsc' },
  ];

  export const sortingOptionsSpecialties: {
    label: string;
    value: SortingOrderSpecialties;
  }[] = [
    { label: 'mostRecent', value: 'dateDesc' },
    { label: 'oldest', value: 'dateAsc' },
    { label: 'azSpecialties', value: 'header' },
  ];

export const statusFiltersBlogs: ISelectOption[] = [
  { label: 'all', value: '' },
  { label: 'drafts', value: 'draft' },
  { label: 'published', value: 'published' },
  { label: 'hidden', value: 'hidden' },
];

export const statusFiltersTestimonials: ISelectOption[] = [
  { label: 'all', value: '' },
  { label: 'published', value: 'published' },
  { label: 'unpublished', value: 'unpublished' },
];

export const statusFiltersSpecialties: ISelectOption[] = [
  { label: 'all', value: '' },
  { label: 'drafts', value: 'draft' },
  { label: 'published', value: 'published' },
];

export const sourceFiltersTestimonials: ISelectOption[] = [
  { label: 'all', value: '' },
  { label: 'website', value: 'website' },
  { label: 'google', value: 'google' },
  { label: 'facebook', value: 'facebook' },
];

export const buttonStylesAnnounce: ISelectOption[] = [
  { label: 'outlined', value: 'outlined' },
  { label: 'contained', value: 'contained' },
  { label: 'text', value: 'text' },
];

export const inputFileErrorMessages: Record<InputFileError, string> = {
  fileSize: 'inputFileSizeErrorMessage',
  fileType: 'inputFileTypeErrorMessage',
  multipleFiles: 'inputFileMultipleFilesErrorMessage',
  removing: 'inputFileRemovingErrorMessage',
  uploading: 'inputFileUploadingErrorMessage',
};

export const userActivityEvents = ['mousedown', 'keydown', 'change', 'drop'];

// originalNetworkId is kept only for consistency. they don't need it
export const networksList: IUser[] = [
  {
    name: 'Multi-Prêts Mortgages',
    nameFr: 'Multi-Prêts Hypothèques',
    networkId: UserNetworkId.MultiPretsMortgages,
    originalNetworkId: UserNetworkId.MultiPretsMortgages,
    userType: 'network',
  },
  {
    name: 'Mortgage Alliance',
    nameFr: 'Alliance hypothécaire',
    networkId: UserNetworkId.MortgageAlliance,
    originalNetworkId: UserNetworkId.MortgageAlliance,
    userType: 'network',
  },
  {
    name: 'Mortgage Intelligence',
    nameFr: 'Intelligence Hypothécaire',
    networkId: UserNetworkId.MortgageIntelligence,
    originalNetworkId: UserNetworkId.MortgageIntelligence,
    userType: 'network',
  },
  {
    name: 'Invis',
    nameFr: 'Invis',
    networkId: UserNetworkId.Invis,
    originalNetworkId: UserNetworkId.Invis,
    userType: 'network',
  },
];

export const defaultSessionTimeoutModal = {
  open: false,
  path: null,
  autoSave: false,
  callback: () => {},
};

export const headerLogoByBrand = {
  invis: {
    en: 'logo_invis.png',
    fr: 'logo_invis.png',
  },
  mortgagealliance: {
    en: 'logo-ma-en.png',
    fr: 'logo-ma-fr.jpg',
  },
  mortgageintelligence: {
    en: 'logo-mi-en.png',
    fr: 'logo-mi-fr.png',
  },
  'multi-prets': {
    en: 'logo-mp-en.png',
    fr: 'logo-mp-fr.png',
  },
  m3tech: {
    en: 'logo-m3tech.png',
    fr: 'logo-m3tech.png',
  },
};

export const faviconByBrand = {
  invis: 'invis.ico',
  mortgagealliance: 'mortgagealliance.ico',
  mortgageintelligence: 'mortgageintelligence.ico',
  'multi-prets': 'multi-prets.ico',
  m3tech: 'm3tech.ico',
};

export const ACTIONS_COLUMN_WIDTH = 129;

export const metaDataSchema = yup.object().shape({
  description: yup
    .string()
    .max(META_DESCRIPTION_MAX_LENGTH, 'formDescriptionMax')
    .optional(),
  slug: yup
    .string()
    .max(150, 'formSlugMax')
    .matches(/^$|^[a-z0-9-]+$/, 'formSlugMatch')
    .optional(),
  title: yup.string().max(60, 'formTitleMax').optional(),
});
