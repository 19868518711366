import { IUserInfo, Lang } from '@/common/types';

export const DEFAULT_LANG: Lang = 'en';

export const DEFAULT_USER_INFO: IUserInfo = {
  appId: '',
  blogAccess: false,
  brand: '',
  brokerblogAccess: false,
  cmsId: '',
  companyName: '',
  displayTestimonials: false,
  eHeroImage: '',
  eCroppedHeroImage: '',
  email: '',
  employeeId: 0,
  employeeSecurityGroupMembers: [
    {
      employeeId: 0,
      securityGroupID: 0,
    },
  ],
  firstName: '',
  franchise: [],
  isActive: true,
  isTop: false,
  langId: 1,
  lastName: '',
  pageId: '',
  name: '',
  nameFr: '',
  networkId: 0,
  offices: [],
  originalNetworkId: 0,
  role: 'broker',
  securityGroups: [
    {
      groupName: '',
      groupNameFr: '',
      securityGroupID: 0,
    },
  ],
  siteName: '',
  siteNameFr: '',
  specialtiesAccess: false,
  status: '',
  statusFr: '',
  userLongToken: '',
  userType: 'broker',
};
