export const FETCH_BLOGS_URL = '/fetchBlog';
export const FETCH_BLOGS_NAMES_URL = `${FETCH_BLOGS_URL}/blogsNames`;
export const FETCH_BLOG_BY_ID_URL = `${FETCH_BLOGS_URL}/id`;
export const FETCH_BLOG_SLUGS_URL = `${FETCH_BLOGS_URL}/slugs`;

export const FETCH_USER_URL = '/fetchUser';
export const FETCH_TESTIMONIAL_URL = '/fetchTestimonial';
export const STORAGE_BLOB_URL = '/storageBlob';

export const FETCH_DISPLAY_TESTIMONIALS_URL = `${FETCH_USER_URL}/displayTestimonials`;

export const FETCH_ANNOUNCEMENTS_URL = '/fetchAnnouncements';
export const FETCH_EMPLOYEES_ALL_DETAILS = `${FETCH_USER_URL}/employees/allDetails`;

export const FETCH_HERO_IMAGE_URL = '/fetchHeroImage';
export const DELETE_HERO_IMAGE_URL = `${FETCH_HERO_IMAGE_URL}/deleteImage`;
export const FETCH_BLOG_ACCESS_URL = '/fetchBlogAccess';
export const FETCH_TESTIMONIAL_SUMMARY_URL = `${FETCH_TESTIMONIAL_URL}/summary`;

export const FETCH_POLICY_URL = '/fetchPolicy';

export const FETCH_DOMAIN_API = '/fetchDomain';

export const FETCH_SPECIALTIES_API = '/fetchSpecialties';
export const FETCH_SPECIALTIES_SLUGS_URL = `${FETCH_SPECIALTIES_API}/slugs`;

export const FETCH_SPECIALTIES_ACCESS_API = '/fetchSpecialtiesAccess';

export const FETCH_GOOGLE_REVIEWS_DATA_API = '/fetchGoogleReviewsData';
