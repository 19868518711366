import { Stack, styled } from '@mui/material';

import { FC } from '@/admin/types/common';

export const TableContainer = ({ children }: FC) => (
  <StyledStack spacing={2}>{children}</StyledStack>
);

const StyledStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(5, 8, 4, 6),
}));
