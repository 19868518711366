import { Box, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

type SidebarProps = { children: ReactNode };

export const Sidebar = ({ children }: SidebarProps) => (
  <StyledBox>
    <StyledStack direction={{ xs: 'row', lg: 'column' }} spacing={5}>
      {children}
    </StyledStack>
  </StyledBox>
);

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[50]}`,
  height: '100%',
  padding: theme.spacing(4, 5, 3, 3),
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.grey[100],
    border: 'none',
    padding: theme.spacing(4),
  },
}));

const StyledStack = styled(Stack)({
  '& > *': { flex: 1 },
});
