import { styled } from '@mui/material';

import { FC } from '@/admin/types/common';

export const EditorContainer = ({ children }: FC) => (
  <StyledContainer>{children}</StyledContainer>
);

const StyledContainer = styled('div')({
  fontWeight: 400,
  lineHeight: '1.7',
  minWidth: 500,
  position: 'relative',
});
