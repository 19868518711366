import { styled } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlogsFilters,
  BlogsTable,
  BlogFiltersSkeleton,
  BlogHeaderSkeleton,
  BlogsSkeleton,
  BlogsTop,
  Error,
  TableContainer,
} from '@/admin/components';
import { ROWS_PER_PAGE, DEFAULT_BLOGS_FILTERS } from '@/admin/consts';
import { useIsFirstRender } from '@/admin/hooks';
import { AppContext, BlogsContext } from '@/admin/providers';
import {
  getItemFromSessionStorage,
  getLocalizedDate,
} from '@/admin/utils/helpers';
import { getBlogsApi, getBlogsNamesApi } from '@/admin/utils/helpers-api';
import {
  IBlogsFilters,
  SortingOrderBlogs,
  BlogsFiltersNames,
} from '@/common/types';

export const Blogs = () => {
  const { selectedUser, selectedUserId, getAccessToken } =
    useContext(AppContext);

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const {
    blogsList,
    currentPage,
    totalCount,
    addBlogs,
    addTotalCount,
    changeCurrentPage,
    clearBlogsList,
    resetStoredBlogs,
  } = useContext(BlogsContext);

  const { isFirstRender } = useIsFirstRender();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNoBlogs, setIsNoBlogs] = useState<boolean>(false);
  const [isNoBlogsByFilters, setIsNoBlogsByFilters] = useState<boolean>(false);
  // data
  const [blogsNames, setBlogsNames] = useState<string[]>([]);
  // filters
  const [filters, setFilters] = useState<IBlogsFilters>(
    (getItemFromSessionStorage('blogFilters', true) as IBlogsFilters) ||
      DEFAULT_BLOGS_FILTERS
  );
  const [order, setOrder] = useState<SortingOrderBlogs>('dateDesc');

  const fetchBlogsByPage = async (page: number, signal?: AbortSignal) => {
    try {
      // set loading state
      setIsLoading(true);

      // fetch the blogs data
      const token = await getAccessToken();

      const { blogs, total } = await getBlogsApi(
        {
          filters: {
            ...filters,
            userId: selectedUserId,
            userType: selectedUser.userType,
          },
          limit: ROWS_PER_PAGE,
          offset: ROWS_PER_PAGE * (page - 1),
          order,
          token,
        },
        signal
      );

      // localize a blog's date
      blogs.forEach((blog) => {
        blog.dateUpdated = getLocalizedDate(language, blog.dateUpdated);
      });

      // set the blogs data
      addBlogs(blogs, page);
      addTotalCount(total);
      // remove loading state
      setIsLoading(false);
    } catch (error) {
      if (error instanceof window.Error && error.name === 'AbortError') {
        // eslint-disable-next-line no-console
        console.log('Fetch blogs aborted');
      } else {
        setIsLoading(false);
        setIsError(true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  // fetch the blogs data on the first load or if the filters changed
  useEffect(() => {
    // Added AbortController for BOSS CMP fingerprints
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      clearBlogsList();
      await fetchBlogsByPage(currentPage, signal);
      resetStoredBlogs();
    })();

    return () => {
      abortController.abort();
    };
  }, [filters, order, selectedUserId]);

  // fetch the blogs names
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessToken();

        const blogsNames = await getBlogsNamesApi(
          { ...filters, userType: selectedUser.userType },
          token,
          selectedUserId
        );

        setBlogsNames(blogsNames);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, [filters, selectedUserId]);

  // check if the user has no blogs or no blogs by filters
  useEffect(() => {
    const isDefaultFilters =
      Object.entries(filters).toString() ===
      Object.entries(DEFAULT_BLOGS_FILTERS).toString();

    if (!isLoading) {
      if (totalCount === 0) {
        if (isDefaultFilters || isNoBlogs) {
          setIsNoBlogs(true);
          setIsNoBlogsByFilters(false);
        } else {
          setIsNoBlogs(false);
          setIsNoBlogsByFilters(true);
        }
      } else {
        setIsNoBlogs(false);
        setIsNoBlogsByFilters(false);
      }
    }
  }, [filters, isLoading, totalCount, selectedUserId]);

  const handleFiltersChange = (
    filter: BlogsFiltersNames,
    newValue: string | null
  ) => {
    setFilters({ ...filters, [filter]: newValue });
  };

  const handleSearchChange = (
    e: SyntheticEvent,
    newBlogName: string | null
  ) => {
    handleFiltersChange('blogName', newBlogName || '');
  };

  const handlePageChange = (e: unknown, newPage: number) => {
    changeCurrentPage(newPage);
    if (!(newPage in blogsList)) {
      fetchBlogsByPage(newPage);
    }
  };

  if (isError)
    return (
      <Error
        message={t('errorOccurredMessage')}
        title={t('errorOccurredTitle')}
      />
    );

  return (
    <>
      {isLoading && isFirstRender ? (
        <BlogHeaderSkeleton />
      ) : (
        <BlogsTop
          blogName={filters.blogName || ''}
          blogsNames={blogsNames}
          handleChange={handleSearchChange}
        />
      )}

      <TableContainer>
        {isLoading && isFirstRender ? (
          <BlogFiltersSkeleton />
        ) : (
          <BlogsFilters
            language={filters.language || ''}
            order={order}
            status={filters.status || ''}
            handleFiltersChange={handleFiltersChange}
            setOrder={setOrder}
          />
        )}

        {isLoading ? (
          <BlogsSkeleton />
        ) : (
          <StyledTable>
            <BlogsTable
              isNoBlogs={isNoBlogs}
              isNoBlogsByFilters={isNoBlogsByFilters}
              getBlogs={() => fetchBlogsByPage(currentPage)}
              handleChangePage={handlePageChange}
            />
          </StyledTable>
        )}
      </TableContainer>
    </>
  );
};

const StyledTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
