import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@/admin/components';
import { META_DESCRIPTION_MAX_LENGTH } from '@/admin/consts';
import { AppContext } from '@/admin/providers';
import { ArticleType } from '@/admin/types/common';
import { getSlugText, getSlugURL } from '@/admin/utils/helpers';
import { ISEOData } from '@/common/types';

type SidebarSeoEditModalProps = {
  errors: ISEOData;
  isSlugError: boolean;
  language: string;
  open: boolean;
  slugSubPath: ArticleType;
  values: ISEOData;
  handleChangeDescription: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeSlug: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTitle: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  handleSave: () => void;
};

export const SidebarSeoEditModal = ({
  errors,
  isSlugError,
  language,
  open,
  slugSubPath,
  values,
  handleChangeDescription,
  handleChangeSlug,
  handleChangeTitle,
  handleClose,
  handleSave,
}: SidebarSeoEditModalProps) => {
  const { t } = useTranslation();

  const {
    displayedUserInfo: { networkId, siteName, siteNameFr, userType },
  } = useContext(AppContext);

  const slug = `${getSlugURL(
    language,
    networkId,
    userType,
    siteName,
    siteNameFr,
    getSlugText(t(slugSubPath, { lng: language }))
  )}${values.slug}`;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{t('editSeo')}</DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1.5}>
            <TextField
              error={!!errors.slug}
              helperText={errors.slug}
              label={t('slug')}
              name="slug"
              placeholder={t('slug')}
              subText={slug}
              value={values.slug}
              onChange={handleChangeSlug}
            />

            {isSlugError && <StyledError>{t('slugAlert')}</StyledError>}
          </Stack>

          <TextField
            error={!!errors.title}
            helperText={errors.title}
            label={t('metaTitle')}
            name="title"
            placeholder={t('metaTitle')}
            value={values.title}
            onChange={handleChangeTitle}
          />

          <TextField
            caption={t('maxCharacters', {
              number: `${values.description.length}/${META_DESCRIPTION_MAX_LENGTH}`,
            })}
            error={!!errors.description}
            helperText={errors.description}
            label={t('metaDescription')}
            multiline={true}
            name="description"
            placeholder={t('metaDescriptionPlaceholder')}
            value={values.description}
            onChange={handleChangeDescription}
          />
        </Stack>
      </DialogContent>

      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('cancel')}
        </Button>

        <Button variant="contained" onClick={handleSave}>
          {t('saveChanges')}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(3),
}));

const StyledError = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: 'Archivo',
  color: theme.palette.error.main,
  padding: theme.spacing(0.5, 0, 0, 2),
}));
