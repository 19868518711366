import { TableBody, TableCell, TableRow } from '@mui/material';

import { EmptyState } from '@/admin/components';

type TableEmptyStateProps = {
  message: string;
  title: string;
  colSpan?: number;
  linkText?: string;
  linkTo?: string;
};


export const TableEmptyState = ({
  colSpan = 4,
  linkText,
  linkTo,
  message,
  title,
}: TableEmptyStateProps) => (
  <TableBody>
    <TableRow tabIndex={-1}>
      <TableCell colSpan={colSpan}>
        <EmptyState
          button={linkText}
          to={linkTo}
          message={message}
          title={title}
        />
      </TableCell>
    </TableRow>
  </TableBody>
);
