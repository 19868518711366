import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Error } from '@/admin/components';

export const ArticleError = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Error title={t('errorOccurredTitle')}>
      <Typography variant="body1">
        {`${t('errorOccurredRefreshMessage')} ${t('or')} `}

        <span onClick={handleGoBack}>{t('errorOccurredBackMessage')}</span>
      </Typography>
    </Error>
  );
};
