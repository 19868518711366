import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';
import { LexicalEditor } from 'lexical';

import { Icon } from '@/admin/components';
import {
  ToolbarButton,
  embedConfigs,
} from '@/admin/components/editor/editorPlugins';

type ToolbarMediaProps = {
  editor: LexicalEditor;
  handleShowImageModal: () => void;
};

export const ToolbarMedia = ({
  editor,
  handleShowImageModal,
}: ToolbarMediaProps) => {
  return (
    <>
      <ToolbarButton ariaLabel="Insert Image" onClick={handleShowImageModal}>
        <Icon size="sm" symbol="image" />
      </ToolbarButton>

      {embedConfigs.map((embedConfig) => (
        <ToolbarButton
          key={embedConfig.type}
          ariaLabel={`Embed ${embedConfig.contentName}`}
          onClick={() => {
            editor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type);
          }}
        >
          <Icon size="sm" symbol={embedConfig.icon} />
        </ToolbarButton>
      ))}
    </>
  );
};
