import { Grid, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Dialog,
  SpecialtyHeader,
  SpecialtyMain,
  SpecialtySidebar,
} from '@/admin/components';
import { SPECIALTIES_PATH } from '@/admin/consts';
import { useCallbackPrompt, useModal } from '@/admin/hooks';
import {
  AppContext,
  SpecialtyContext,
  ThemeContext,
  defaultTextEditorContent,
  useStorageBlobCleanContext,
} from '@/admin/providers';
import {
  createSpecialtyApi,
  deleteSpecialtyApi,
  updateSpecialtyApi,
} from '@/admin/utils/helpers-api';
import { ISpecialtyValues } from '@/common/types';

export const Specialty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { openSnackbar } = useContext(ThemeContext);
  const { modalContent, showModal, handleModalClose } = useModal();
  const { isNewSpecialty } = useContext(SpecialtyContext);

  const {
    formState: { isDirty },
    trigger,
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useFormContext<ISpecialtyValues>();

  const {
    isTokenExpired,
    userInfo: { name },
    getAccessToken,
    setOpenSessionTimeoutModal,
  } = useContext(AppContext);

  const {
    getImagesFromDom,
    removeAddedImageSrc,
    removeAllImageSrc,
    removeUnusedImageSrc,
  } = useStorageBlobCleanContext();

  // detect the user leaving the page
  const [isLeavingDialogActive, setIsLeavingDialogActive] =
    useState<boolean>(false);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isLeavingDialogActive
  );

  useEffect(() => {
    isDirty !== isLeavingDialogActive && setIsLeavingDialogActive(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (isTokenExpired) {
      setIsLeavingDialogActive(false);

      if (getValues('status') === 'draft') {
        const formData = getValues();
        const defaultSaveData: Partial<ISpecialtyValues> = {
          description:
            '<p class="editor_paragraph" dir="ltr"><span></span></p>',
          header: t('newSpecialty'),
          homepageHeader: t('newSpecialty'),
        };

        Object.keys(defaultSaveData).forEach((key) => {
          const typedKey = key as keyof ISpecialtyValues;
          if (
            !formData[typedKey] ||
            (key === 'description' &&
              formData[key] === defaultTextEditorContent)
          ) {
            setValue(typedKey, formData[typedKey]);
          }
        });

        onSaveClick(true);
      } else {
        getSessionTimeoutModal();
      }
    }
  }, [isTokenExpired]);

  const onSaveClick = (sessionTimeout: boolean = false) => {
    submitSpecialty('save', sessionTimeout);
  };

  const onPublishClick = async () => {
    const hasNoError = await trigger();
    const isDraft = getValues('status') === 'draft';

    if (hasNoError) {
      showModal({
        message: isDraft
          ? t('publishSpecialtyMessage')
          : t('publishUpdateSpecialtyDialogMessage'),
        title: isDraft
          ? t('publishDialogTitle')
          : t('publishUpdateDialogTitle'),
        handleAgree: () => submitSpecialty('publish'),
      });
    }
  };

  const onDeleteClick = () => {
    showModal({
      message: t('specialtyDeleteConfirmMessage'),
      title: t('deleteDialogTitle'),
      handleAgree: deleteSpecialty,
    });
  };

  const createSpecialty = async (
    values: ISpecialtyValues,
    token: string,
    action: 'save' | 'publish',
    sessionTimeout?: boolean
  ) => {
    const { data, status } = await createSpecialtyApi(values, token);

    if (status === 201) {
      submitSpecialtySuccess(
        action,
        values.description,
        token,
        true,
        data,
        sessionTimeout
      );
    }
  };

  const updateSpecialty = async (
    values: ISpecialtyValues,
    token: string,
    action: 'save' | 'publish',
    sessionTimeout?: boolean
  ) => {
    const { data, status } = await updateSpecialtyApi(values, token);

    if (status === 200) {
      submitSpecialtySuccess(
        action,
        values.description,
        token,
        false,
        data,
        sessionTimeout
      );
    }
  };

  const submitSpecialty = async (
    action: 'save' | 'publish',
    sessionTimeout: boolean = false
  ) => {
    handleSubmit(async (data) => {
      try {
        const updatedData: ISpecialtyValues = {
          ...data,
          status: action === 'publish' ? 'published' : data.status,
          updatedBy: name,
        };

        setIsLeavingDialogActive(false);

        const token = await getAccessToken();

        if (isNewSpecialty) {
          await createSpecialty(updatedData, token, action, sessionTimeout);
        } else {
          await updateSpecialty(updatedData, token, action, sessionTimeout);
        }
      } catch (error) {
        handleActionError(sessionTimeout);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  };

  const submitSpecialtySuccess = (
    action: 'save' | 'publish',
    description: string,
    token: string,
    isNewSpecialty: boolean,
    values: ISpecialtyValues,
    sessionTimeout?: boolean
  ) => {
    const imagesFromDom = getImagesFromDom(description);
    const isSaveAction = action === 'save';

    reset(values);
    handleModalClose();
    removeUnusedImageSrc(token, imagesFromDom);
    openSnackbar(
      t(
        isSaveAction
          ? isNewSpecialty
            ? 'specialtySaveSnackbarMessage'
            : 'specialtyUpdateSnackbarMessage'
          : 'specialtyPublishSnackbarMessage'
      )
    );

    if (isSaveAction && sessionTimeout) {
      getSessionTimeoutModal(isSaveAction && sessionTimeout);
    }

    if (isNewSpecialty) navigate(`${SPECIALTIES_PATH}/${values.id}`);
  };

  const deleteSpecialty = async () => {
    try {
      setIsLeavingDialogActive(false);
      const token = await getAccessToken();

      if (isNewSpecialty) {
        deleteSpecialtySuccess(token);
      } else {
        const status = await deleteSpecialtyApi(id as string, token);
        if (status === 200) deleteSpecialtySuccess(token);
      }
    } catch (error) {
      handleActionError();
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const deleteSpecialtySuccess = (token: string) => {
    removeAllImageSrc(token);
    handleModalClose();
    openSnackbar(t('specialtySnackbarDeleteMessage'));
    navigate(SPECIALTIES_PATH);
  };

  const handleActionError = (sessionTimeout: boolean = false) => {
    if (sessionTimeout) {
      getSessionTimeoutModal();
      return;
    }
    setIsLeavingDialogActive(true);
    showModal(
      {
        message: t('errorDialogMessage'),
        title: t('errorDialogTitle'),
        handleAgree: handleModalClose,
      },
      true
    );
  };

  const getSessionTimeoutModal = (autoSave: boolean = false) => {
    setOpenSessionTimeoutModal({
      open: true,
      path: 'specialties',
      autoSave,
      callback: async () => {
        if (!autoSave) {
          const token = await getAccessToken();
          removeAddedImageSrc(token);
        }
      },
    });
  };

  const handleLeaveConfirm = () => {
    confirmNavigation(async () => {
      const token = await getAccessToken();
      removeAddedImageSrc(token);
    });
  };

  return (
    <>
      <SpecialtyHeader
        onDeleteClick={onDeleteClick}
        onPublishClick={onPublishClick}
        onSaveClick={onSaveClick}
      />

      <StyledGridContainer container>
        <StyledGrid item xs={12} lg={9}>
          <SpecialtyMain />
        </StyledGrid>

        <Grid item xs={12} lg={3}>
          <SpecialtySidebar />
        </Grid>
      </StyledGridContainer>

      <Dialog
        buttonAgree={modalContent.buttonAgree}
        buttonClose={modalContent.buttonClose}
        message={modalContent.message}
        title={modalContent.title}
        open={modalContent.open}
        handleAgree={modalContent.handleAgree}
        handleClose={modalContent.handleClose}
      />

      {/* the user leaving the page */}
      <Dialog
        buttonAgree={t('returnToSpecialties')}
        buttonClose={t('exitWithoutSaving')}
        message={t('unsavedChangesSpecialtiesMessage')}
        title={t('unsavedChanges')}
        open={showPrompt}
        handleAgree={cancelNavigation}
        handleClose={handleLeaveConfirm}
      />
    </>
  );
};

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: { height: '100%' },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 4, 3),
}));
