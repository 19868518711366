import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReadOnlyTextField } from '@/admin/components';

type SidebarSeoProps = {
  metaDescriptionLabel: string;
  metaDescriptionValue: string;
  metaTitleLabel: string;
  metaTitleValue: string;
  seoEditModal: ReactNode;
  slugLabel: string;
  slugValue: string;
  handleOpenEditModal: () => void;
};

export const SidebarSeo = ({
  metaDescriptionLabel,
  metaDescriptionValue,
  metaTitleLabel,
  metaTitleValue,
  seoEditModal,
  slugLabel,
  slugValue,
  handleOpenEditModal,
}: SidebarSeoProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">{t('seo')}</Typography>
          <Button onClick={handleOpenEditModal}>{t('edit')}</Button>
        </Stack>

        <ReadOnlyTextField label={slugLabel} value={slugValue} />

        <ReadOnlyTextField label={metaTitleLabel} value={metaTitleValue} />

        <StyledTextarea>
          <ReadOnlyTextField
            label={metaDescriptionLabel}
            placeholder={
              metaDescriptionValue ? '' : t('metaDescriptionEmptyState')
            }
            value={metaDescriptionValue}
          />
        </StyledTextarea>
      </Stack>

      {seoEditModal}
    </Box>
  );
};

const StyledTextarea = styled('div')({ minHeight: 162 });
