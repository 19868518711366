import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BackdropLoader, Dialog } from '@/admin/components';
import { INSERT_IMAGE_COMMAND } from '@/admin/components/editor/editorPlugins';
import { inputFileErrorMessages } from '@/admin/consts';
import { useImageUpload } from '@/admin/hooks';
import { AppContext, useStorageBlobCleanContext } from '@/admin/providers';
import { uploadImageApi } from '@/admin/utils/helpers-api';

export const DragDropPastePlugin = () => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();

  const { selectedUserId, getAccessToken } = useContext(AppContext);
  const { addImageSrc } = useStorageBlobCleanContext();

  const {
    isLoading,
    error,
    handleUploadImage,
    handleDeleteImage,
    verifyImageFormat,
  } = useImageUpload();

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        files.map(async (file) => {
          const isValidImage = verifyImageFormat(file);
          if (!isValidImage) return;

          const token = await getAccessToken();
          const src = await handleUploadImage(() =>
            uploadImageApi(file, token, selectedUserId)
          );
          if (!src) return;

          addImageSrc(src);
          editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
            altText: file.name,
            src,
          });
        });
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  return (
    <>
      <BackdropLoader open={isLoading} />
      <Dialog
        buttonAgree={t('okay')}
        message={error ? t(inputFileErrorMessages[error]) : ''}
        open={!!error}
        title={t('errorDialogTitle')}
        handleAgree={handleDeleteImage}
      />
    </>
  );
};
