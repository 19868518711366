import { FormHelperText, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SpecialtyEditor } from '@/admin/components';
import { ISpecialtyValues } from '@/common/types';

export const SpecialtyMainBody = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<ISpecialtyValues>();

  return (
    <Stack spacing={2}>
      <SpecialtyEditor />

      <FormHelperText error={!!errors.description}>
        {t(errors.description?.message as string)}
      </FormHelperText>
    </Stack>
  );
};
