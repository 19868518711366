import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import {
  $getRoot,
  EditorState,
  LexicalEditor,
  ParagraphNode,
  TextNode,
} from 'lexical';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  EditorContainer,
  EditorContent,
  EditorScroller,
} from '@/admin/components';
import {
  CustomHeadingNode,
  CustomListItemNode,
  CustomParagraphNode,
  CustomTextNode,
  ImageNode,
  MergeTagNode,
} from '@/admin/components/editor/editorNodes';
import {
  AutoEmbedPlugin,
  ClickableLinkPlugin,
  DragDropPastePlugin,
  FloatingLinkEditorPlugin,
  ImagePlugin,
  ListMaxIndentLevelPlugin,
  OnLoadPlugin,
  TableCellActionMenuPlugin,
  TableCellResizerPlugin,
  MergeTagPlugin,
  ToolbarPluginPolicy,
} from '@/admin/components/editor/editorPlugins';
import { editorTheme } from '@/admin/components/editor/editorTheme';
import { MATCHERS } from '@/admin/consts/editor';
import { PrivacyPoliciesContext } from '@/admin/providers';
import { parseDomFromString } from '@/admin/utils/helpers';

export const PrivacyPolicyEditor = () => {
  const {
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useFormContext();

  const { tabLanguage } = useContext(PrivacyPoliciesContext);

  const policyTextEn = getValues('policyTextEn');
  const policyTextFr = getValues('policyTextFr');
  const policyType = getValues('userType');

  const isCorporate = policyType === 'network';

  const initialConfig = {
    namespace: 'Editor',
    editable: !isCorporate,
    nodes: [
      AutoLinkNode,
      CustomHeadingNode,
      CustomListItemNode,
      CustomParagraphNode,
      CustomTextNode,
      ImageNode,
      LinkNode,
      ListNode,
      MergeTagNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      {
        replace: HeadingNode,
        with: (node: HeadingNode) => {
          return new CustomHeadingNode(node.__tag, node.__key);
        },
      },
      {
        replace: ListItemNode,
        with: (node: ListItemNode) => {
          return new CustomListItemNode(
            node.__value,
            node.__checked,
            node.__key
          );
        },
      },
      {
        replace: ParagraphNode,
        with: (node: ParagraphNode) => {
          return new CustomParagraphNode(node.__key);
        },
      },
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return new CustomTextNode(node.__text, node.__key);
        },
      },
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: editorTheme,
    editorState: (editor: LexicalEditor) => {
      const dom = parseDomFromString(
        tabLanguage === 0 ? policyTextEn : policyTextFr
      );

      const nodes = $generateNodesFromDOM(editor, dom);
      const rootNode = $getRoot();
      rootNode.append(...nodes);
    },
  };

  const LanguageChangePlugin = () => {
    const [editor] = useLexicalComposerContext();

    const update = () => {
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        const dom = parseDomFromString(
          tabLanguage === 0 ? policyTextEn : policyTextFr
        );
        const nodes = $generateNodesFromDOM(editor, dom);

        root.append(...nodes);
      });
    };

    update();

    return null;
  };

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      // set policy text form value
      const htmlString = $generateHtmlFromNodes(editor);

      tabLanguage === 0 && setValue('policyTextEn', htmlString);
      tabLanguage === 1 && setValue('policyTextFr', htmlString);

      // clear required error manually when contents exist
      if (errors.policyTextEn && htmlString) {
        clearErrors('policyTextEn');
      }
      if (errors.policyTextFr && htmlString) {
        clearErrors('policyTextFr');
      }

      // set description form value

      const paragraphText: string[] = [];

      editorState._nodeMap.forEach((node) => {
        const parent = node.getParent();
        if (
          node.__type === 'text' &&
          parent?.__type === 'paragraph' &&
          parent?.__parent === 'root'
        ) {
          paragraphText.push(node.getTextContent());
        }
      });
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorContainer>
        {!isCorporate && <ToolbarPluginPolicy />}

        <EditorContent
          className={`
          ${
            tabLanguage === 0
              ? errors.policyTextEn
                ? 'error'
                : ''
              : errors.policyTextFr
              ? 'error'
              : ''
          }  ${isCorporate ? 'readOnly' : ''}
          `}
        >
          <RichTextPlugin
            contentEditable={
              <EditorScroller onRef={onRef}>
                <ContentEditable
                  className={`${isCorporate ? 'readOnly ' : ''}contentEditable`}
                />
              </EditorScroller>
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />

          <AutoFocusPlugin />
          <OnLoadPlugin />
          <OnChangePlugin onChange={handleChange} />
          <LanguageChangePlugin />

          <HistoryPlugin />

          <LinkPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <ClickableLinkPlugin />

          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />

          <ImagePlugin />
          <DragDropPastePlugin />

          <AutoEmbedPlugin />

          <TablePlugin />

          <MergeTagPlugin />

          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <TableCellResizerPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
        </EditorContent>
      </EditorContainer>
    </LexicalComposer>
  );
};
