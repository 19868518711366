import {
  styled,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectOption } from '@/admin/types/common';

type ToggleButtonGroupProps = {
  ariaLabel: string;
  buttons: ISelectOption[];
  value: string;
  onChange: (e: SyntheticEvent, newValue: string) => void;
  tooltipTitle?: string;
  disabled?: boolean;
  disableTooltip?: boolean;
  title?: string;
};

export const ToggleButtonGroup = ({
  ariaLabel,
  buttons,
  title,
  value,
  onChange,
  disabled,
  tooltipTitle,
  disableTooltip,
}: ToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="flex-start" spacing={0.5}>
      {title && (
        <Tooltip
          arrow
          disableHoverListener={disableTooltip}
          placement="top"
          title={tooltipTitle || ''}
        >
          <StyledTypography
            className={disabled ? 'disabled' : ''}
            variant="caption"
          >
            {title}
          </StyledTypography>
        </Tooltip>
      )}

      <StyledMuiToggleButtonGroup
        aria-label={ariaLabel}
        className={disabled ? 'disabled' : ''}
        color="primary"
        exclusive
        disabled={disabled}
        value={disabled ? 'none' : value}
        onChange={onChange}
      >
        {buttons.map((button) => (
          <ToggleButton key={button.value} value={button.value} size="small">
            {t(button.label)}
          </ToggleButton>
        ))}
      </StyledMuiToggleButtonGroup>
    </Stack>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.disabled': { color: theme.palette.grey[400] },
}));

const StyledMuiToggleButtonGroup = styled(MuiToggleButtonGroup)({
  '&.disabled': { boxShadow: 'none' },
});
