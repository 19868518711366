import { ArrowBackIosNew } from '@mui/icons-material';
import { Button, Dialog, Slide, styled, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

import { PreviewBody, Slug } from '@/admin/components';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PreviewProps = {
  articleName: string;
  articleType: 'blog' | 'mortgageServices';
  content: string;
  date: number;
  isOpen: boolean;
  isSlugDirty: boolean;
  language: string;
  linkBack: string;
  sectionName: string;
  slug: string;
  status: string;
  title: string;
  handleClose: () => void;
};

export const Preview = ({
  articleName,
  articleType,
  content,
  date,
  isOpen,
  isSlugDirty,
  language,
  linkBack,
  sectionName,
  slug,
  status,
  title,
  handleClose,
}: PreviewProps) => (
  <Dialog
    fullScreen
    open={isOpen}
    onClose={handleClose}
    TransitionComponent={Transition}
  >
    <StyledHeader>
      <StyledHeaderContent>
        <StyledButton startIcon={<ArrowBackIosNew />} onClick={handleClose}>
          {linkBack}
        </StyledButton>

        <Typography variant="h1">{title}</Typography>

        <StyledBlogContentHeaderSlug>
          <Slug
            articleName={articleName}
            articleType={articleType}
            isSlugDirty={isSlugDirty}
            language={language}
            slug={slug}
            status={status}
          />
        </StyledBlogContentHeaderSlug>
      </StyledHeaderContent>
    </StyledHeader>

    <PreviewBody
      articleName={articleName}
      content={content}
      date={date}
      language={language}
      sectionName={sectionName}
    />
  </Dialog>
);

const StyledHeader = styled('div')(({ theme }) => ({
  boxShadow: `0px 4px 4px ${theme.palette.shadow?.dark}`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 4),
  position: 'relative',
}));

const StyledHeaderContent = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1218,
  width: '100%',
});

const StyledBlogContentHeaderSlug = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  left: theme.spacing(4),
  position: 'absolute',
  textDecoration: 'none',
  top: theme.spacing(4),

  '&:hover': { backgroundColor: 'inherit' },
}));
