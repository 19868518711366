import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SidebarImage } from '@/admin/components';
import { useImageUpload } from '@/admin/hooks';
import { AppContext, useStorageBlobCleanContext } from '@/admin/providers';
import { uploadImageApi } from '@/admin/utils/helpers-api';

type BlogInfoImageProps = {
  handleDisableAction: (disabled: boolean) => void;
};

export const BlogInfoImage = ({ handleDisableAction }: BlogInfoImageProps) => {
  const { t } = useTranslation();

  const { getAccessToken, selectedUserId } = useContext(AppContext);
  const { getValues, setValue } = useFormContext();
  const { addImageSrc, addInitialImageSrc } = useStorageBlobCleanContext();

  const {
    imageURL,
    isLoading,
    error,
    handleAddImageURL,
    handleDeleteImage,
    handleUploadImage,
    verifyImageFormat,
    verifySingleImage,
  } = useImageUpload();

  useEffect(() => {
    const imageSrc = getValues('imageURL');
    if (imageSrc) {
      handleAddImageURL(imageSrc);
      addInitialImageSrc(imageSrc);
    }
  }, []);

  const handleDeleteFile = async () => {
    handleDeleteImage();
    setValue('imageURL', '', { shouldDirty: true });
  };

  const handleUploadFile = async (files: FileList) => {
    handleDisableAction(true);

    const isSingleImage = verifySingleImage(files);
    if (!isSingleImage) return;

    const isValidImage = verifyImageFormat(files[0]);
    if (!isValidImage) return;

    const token = await getAccessToken();
    const src = await handleUploadImage(() =>
      uploadImageApi(files[0], token, selectedUserId)
    );
    if (!src) return;

    addImageSrc(src);
    setValue('imageURL', src, { shouldDirty: true });
    handleDisableAction(false);
  };

  return (
    <SidebarImage
      error={error}
      imageURL={imageURL}
      isLoading={isLoading}
      title={t('coverImage')}
      handleDeleteFile={handleDeleteFile}
      handleUploadFile={handleUploadFile}
    />
  );
};
