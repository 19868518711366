// import type { LexicalEditor } from 'lexical';
// import { $generateHtmlFromNodes } from '@lexical/html';
import { $isLinkNode } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import { styled } from '@mui/material';
import i18next from 'i18next';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import { ToolbarMergeTags } from './ToolbarMergeTags';
import { $isMergeTagNode } from '@/admin/components/editor/editorNodes';
import {
  ToolbarAlign,
  ToolbarBlockFormat,
  ToolbarHistory,
  ToolbarList,
  ToolbarTextFormat,
} from '@/admin/components/editor/editorPlugins';
import { BLOCK_TYPES, MERGE_TAG_TYPES } from '@/admin/consts';
import { BLOCKTYPE_TO_BLOCKNAME } from '@/admin/consts/editor';
import { getSelectedNode } from '@/admin/utils/helpers-editor';

const mergeTags = 'mergeTags';

export const ToolbarPluginPolicy = () => {
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof BLOCKTYPE_TO_BLOCKNAME>('paragraph');

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isBulletedList, setIsBulletedList] = useState(false);
  const [isNumberedList, setIsNumberedList] = useState(false);
  useState<keyof typeof BLOCK_TYPES>('paragraph');
  const [mergeTagType, setMergeTagType] = useState<
    keyof typeof MERGE_TAG_TYPES | null
  >(null);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });
      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      // Update lists and block type
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();

          if (type === 'bullet') {
            setIsBulletedList(true);
            setIsNumberedList(false);
            setBlockType('paragraph');
          } else if (type === 'number') {
            setIsNumberedList(true);
            setIsBulletedList(false);
            setBlockType('paragraph');
          }
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          if (type in BLOCKTYPE_TO_BLOCKNAME) {
            setBlockType(type as keyof typeof BLOCKTYPE_TO_BLOCKNAME);
          }

          setIsBulletedList(false);
          setIsNumberedList(false);
        }
        // Update merge tags
        const mergeTagType = $isMergeTagNode(anchorNode)
          ? findTranslationKeyByValue(
              removeSymbols(anchorNode.getTextContent())
            )
          : '';

        if (mergeTagType in MERGE_TAG_TYPES) {
          setMergeTagType(mergeTagType as keyof typeof MERGE_TAG_TYPES);
        } else {
          setMergeTagType(null);
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [activeEditor, editor, updateToolbar]);

  // Remove all symbols except english, french, numbers, apostrophe and space
  const removeSymbols = (value: string) =>
    value.replace(/[^a-zA-Z0-9À-ÿ\s']/g, '');

  const findTranslationKeyByValue = (value: string) => {
    const resourceKeys = Object.keys(i18next.store?.data || {});

    for (let i = 0; i < resourceKeys.length; i++) {
      const currentKey = resourceKeys[i];
      const translations = i18next.store.data[currentKey].translation;

      if (typeof translations === 'object') {
        const matchingKey = Object.keys(translations).find(
          (key) => translations[key] === value
        );

        if (matchingKey) {
          return matchingKey;
        }
      }
    }

    return '';
  };

  return (
    <StyledContainer>
      <ToolbarHistory
        editor={activeEditor}
        canUndo={canUndo}
        canRedo={canRedo}
      />

      <StyledDivider />

      <ToolbarBlockFormat blockType={blockType} editor={editor} />

      <StyledDivider />

      <ToolbarTextFormat
        editor={activeEditor}
        isBold={isBold}
        isItalic={isItalic}
        isLink={isLink}
        isUnderline={isUnderline}
      />

      <StyledDivider />

      <ToolbarAlign editor={activeEditor} />

      <StyledDivider />

      <ToolbarList
        editor={activeEditor}
        isBulletedList={isBulletedList}
        isNumberedList={isNumberedList}
      />

      <StyledDivider />

      <ToolbarMergeTags
        selectedType={mergeTagType}
        defaultType={mergeTags}
        editor={activeEditor}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  padding: '8px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  verticalAlign: 'middle',
  overflow: 'hidden',

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    borderBottom: 'none',
  },
}));

const StyledDivider = styled('div')(({ theme }) => ({
  width: '1px',
  backgroundColor: theme.palette.secondary.light,
  opacity: '75%',
  margin: '0 6px',
}));
