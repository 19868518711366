import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Stack, Tab, Tabs, Typography, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  SpecialtiesLayoutSkeleton,
  ToggleButtonGroup,
} from '@/admin/components';
import { languages } from '@/admin/consts';
import { AppContext, ThemeContext, theme } from '@/admin/providers';
import {
  getSpecialtiesApi,
  updateSpecialtiesApi,
} from '@/admin/utils/helpers-api';
import { ISpecialtiesFilters, ISpecialtyValues } from '@/common/types';

type SpecialtiesLayoutProps = {
  isDialogOpen: boolean;
  lang: string;
  setIsDialogOpen: (isOpen: boolean) => void;
};

export const SpecialtiesLayout = ({
  isDialogOpen,
  lang,
  setIsDialogOpen,
}: SpecialtiesLayoutProps) => {
  const { t } = useTranslation();
  const { openSnackbar } = useContext(ThemeContext);
  const {
    selectedUser: { userType },
    selectedUserId,
    getAccessToken,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(lang);
  const [disableAgreeButton, setDisableAgreeButton] = useState<boolean>(true);

  const [serverSpecialtiesEn, setServerSpecialtiesEn] = useState<
    ISpecialtyValues[]
  >([]);
  const [serverSpecialtiesFr, setServerSpecialtiesFr] = useState<
    ISpecialtyValues[]
  >([]);

  const [specialtiesEn, setSpecialtiesEn] = useState<ISpecialtyValues[]>([]);
  const [specialtiesFr, setSpecialtiesFr] = useState<ISpecialtyValues[]>([]);

  const [displayedSpecialties, setDisplayedSpecialties] = useState<
    ISpecialtyValues[]
  >(language === 'en' ? specialtiesEn : specialtiesFr);

  useEffect(() => {
    if (isDialogOpen) {
      (async () => {
        setIsLoading(true);

        try {
          const defaultFilters: ISpecialtiesFilters = {
            status: 'published',
            userId: selectedUserId,
            userType,
          };

          const token = await getAccessToken();

          const { data: specialtiesEn, status: statusEn } =
            await getSpecialtiesApi(
              token,
              {
                ...defaultFilters,
                language: 'en',
              },
              'position'
            );

          const { data: specialtiesFr, status: statusFr } =
            await getSpecialtiesApi(
              token,
              {
                ...defaultFilters,
                language: 'fr',
              },
              'position'
            );

          if (statusEn === 200) {
            setSpecialtiesEn(specialtiesEn);
            setServerSpecialtiesEn(specialtiesEn);
            if (language === 'en') setDisplayedSpecialties(specialtiesEn);
          }

          if (statusFr === 200) {
            setSpecialtiesFr(specialtiesFr);
            setServerSpecialtiesFr(specialtiesFr);
            if (language === 'fr') setDisplayedSpecialties(specialtiesFr);
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    setDisplayedSpecialties(language === 'en' ? specialtiesEn : specialtiesFr);
  }, [language]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return;

    const displayedSpecialtiesCopy = [...displayedSpecialties];
    const [removed] = displayedSpecialtiesCopy.splice(result.source.index, 1);
    displayedSpecialtiesCopy.splice(result.destination.index, 0, removed);

    setDisplayedSpecialties(displayedSpecialtiesCopy);

    if (language === 'en') {
      setSpecialtiesEn(displayedSpecialtiesCopy);
      setDisableAgreeButton(
        JSON.stringify(serverSpecialtiesEn) ===
          JSON.stringify(displayedSpecialtiesCopy)
      );
    }

    if (language === 'fr') {
      setSpecialtiesFr(displayedSpecialtiesCopy);
      setDisableAgreeButton(
        JSON.stringify(serverSpecialtiesFr) ===
          JSON.stringify(displayedSpecialtiesCopy)
      );
    }
  };

  const updateOrders = async () => {
    try {
      const token = await getAccessToken();

      const orderedSpecialtiesEn = specialtiesEn.map((specialty, index) => {
        return { ...specialty, position: index };
      });

      const orderedSpecialtiesFr = specialtiesFr.map((specialty, index) => {
        return { ...specialty, position: index };
      });

      const { status } = await updateSpecialtiesApi(
        [...orderedSpecialtiesEn, ...orderedSpecialtiesFr],
        token
      );

      closeDialog();
      openSnackbar(
        status === 200
          ? t('specialtiesOrderUpdatedSuccess')
          : t('specialtiesOrderUpdatedFailed')
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const closeDialog = () => {
    setLanguage(lang);
    setDisableAgreeButton(true);
    setIsDialogOpen(false);
  };

  return (
    <Dialog
      buttonAgree={t('confirm')}
      buttonClose={t('cancel')}
      disableAgreeButton={disableAgreeButton}
      maxWidth="md"
      message={t('orderSpecialtiesMessage')}
      title={t('orderSpecialtiesTitle')}
      open={isDialogOpen}
      handleAgree={updateOrders}
      handleClose={closeDialog}
    >
      {isLoading && <SpecialtiesLayoutSkeleton />}

      {!isLoading && (
        <>
          {displayedSpecialties.length ? (
            <StyledStack spacing={3}>
              {Boolean(
                serverSpecialtiesEn.length && serverSpecialtiesFr.length
              ) && (
                <ToggleButtonGroup
                  ariaLabel="language"
                  buttons={languages}
                  value={language}
                  onChange={(e, language) => {
                    setLanguage(language);
                  }}
                />
              )}

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided) => (
                    <StyledTabs
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      orientation="vertical"
                      value={0}
                    >
                      {displayedSpecialties.map((tab, index) => (
                        <Draggable
                          key={tab.id}
                          disableInteractiveElementBlocking
                          draggableId={tab.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <StyledTab
                              className={
                                snapshot.isDragging ? 'is-dragging' : ''
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                background: snapshot.isDragging
                                  ? theme.palette.primary.light
                                  : 'transparent',
                                ...provided.draggableProps.style,
                              }}
                              icon={
                                <StyledIcon>
                                  <DragIndicatorIcon />
                                </StyledIcon>
                              }
                              iconPosition="end"
                              label={
                                <StyledContent
                                  alignItems="center"
                                  className="tab-content"
                                  direction="row"
                                  spacing={2}
                                >
                                  <Typography
                                    color={theme.palette.text.secondary}
                                    variant="h4"
                                  >
                                    {index + 1}
                                  </Typography>

                                  <StyledTypography variant="subtitle1">
                                    {tab.header}
                                  </StyledTypography>
                                </StyledContent>
                              }
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </StyledTabs>
                  )}
                </Droppable>
              </DragDropContext>
            </StyledStack>
          ) : (
            <StyledEmptyState>
              <Typography align="center" variant="body1">
                {t('specialtiesLayoutEmptyState')}
              </Typography>
            </StyledEmptyState>
          )}
        </>
      )}
    </Dialog>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4, 0, 1),
}));

const StyledTabs = styled(Tabs)({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 4,
  boxShadow: 'none',

  '& .MuiTabs-indicator': { display: 'none' },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  justifyContent: 'space-between',
  opacity: 1,
  padding: theme.spacing(2),
  width: '100%',

  '&:last-of-type': { borderBottomColor: 'transparent' },

  '&.is-dragging .tab-content::after': {
    background: theme.palette.primary.light,
  },
}));

const StyledContent = styled(Stack)({
  flexGrow: 1,
  fontWeight: 600,
  overflow: 'hidden',
  position: 'relative',
  textAlign: 'left',
  whiteSpace: 'nowrap',

  '&::after': {
    background:
      'linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 195.83%)',
    bottom: 0,
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 36,
  },
});

const StyledTypography = styled(Typography)({ fontWeight: 600 });

const StyledIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],
  lineHeight: 0,
}));

const StyledEmptyState = styled('div')(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  display: 'flex',
  height: 260,
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));
