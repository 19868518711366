import { Stack } from '@mui/material';

import { SpecialtyMainBody, SpecialtyMainHeader } from '@/admin/components';

export const SpecialtyMain = () => (
  <Stack spacing={3}>
    <SpecialtyMainHeader />
    <SpecialtyMainBody />
  </Stack>
);
