import { Stack, styled } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  BlogContentHeaderBlogName,
  BlogContentHeaderLanguage,
  BlogContentHeaderVisibility,
  Slug,
} from '@/admin/components';

type BlogContentHeaderProps = {
  handleVisibilityChange: ChangeEventHandler;
};

export const BlogContentHeader = ({
  handleVisibilityChange,
}: BlogContentHeaderProps) => {
  const { formState, getFieldState, getValues, watch } = useFormContext();

  const status = getValues('status');

  return (
    <StyledContainer>
      <Stack spacing={1}>
        <Stack alignItems="flex-end" direction="row" spacing={3}>
          <BlogContentHeaderBlogName />

          <BlogContentHeaderLanguage />

          {status !== 'draft' && (
            <BlogContentHeaderVisibility
              handleVisibilityChange={handleVisibilityChange}
            />
          )}
        </Stack>

        <Slug
          articleName={watch('blogName')}
          articleType="blog"
          isSlugDirty={getFieldState('slug', formState).isDirty}
          language={watch('language')}
          slug={watch('slug')}
          status={status}
        />
      </Stack>
    </StyledContainer>
  );
};

const StyledContainer = styled(Stack)({ minWidth: 750 });
