import { REACT_APP_PROXY_API_URL } from '@/admin/config/variables';
import { FETCH_DOMAIN_API } from '@/admin/consts';
import { getHeaders } from '@/admin/utils/helpers-api';
import { IDomainData, IDomainValues, UserType } from '@/common/types';

export const createDomainApi = async (values: IDomainValues, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DOMAIN_API}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(values),
  });

  const { data, status } = (await res.json()) as {
    data: IDomainData;
    status: number;
  };

  return { data, status };
};

export const updateDomainApi = async (values: IDomainData, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DOMAIN_API}`;
  const headers = getHeaders(token);

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(values),
  });

  const { data, status } = (await res.json()) as {
    data: IDomainData;
    status: number;
  };

  return { data, status };
};

export const getDomainApi = async (
  token: string,
  userId?: number,
  userType?: UserType
) => {
  const queries = userId ? `?userId=${userId}&userType=${userType}` : '';
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DOMAIN_API}${queries}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const { data }: { data: IDomainData } = await res.json();

  return data;
};

export const checkDomainApi = async (
  domain: string,
  token: string,
) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DOMAIN_API}/checkDomain?domain=${domain}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { headers });
  const resJson = await res.json();

  return resJson;
};

export const deleteDomainApi = async (id: string, token: string) => {
  const url = `${REACT_APP_PROXY_API_URL}${FETCH_DOMAIN_API}/${id}`;
  const headers = getHeaders(token);

  const res = await fetch(url, { method: 'DELETE', headers });
  const { status } = await res.json();

  return status;
};
