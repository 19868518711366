import { Button, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Header } from '@/admin/components';
import { PRIVACY_POLICIES_PATH } from '@/admin/consts/paths';

type PrivacyPolicyLinkBackProps = {
  handleDelete: () => void;
  handlePublish: () => void;
  handleSave: () => void;
};

export const PrivacyPolicyLinkBack = ({
  handleDelete,
  handlePublish,
  handleSave,
}: PrivacyPolicyLinkBackProps) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();
  const status = getValues('status');
  const type = getValues('userType');

  return (
    <Header
      title={t('allPrivacyPolicies')}
      to={PRIVACY_POLICIES_PATH}
      languageTab
    >
      {type !== 'network' && (
        <>
          <StyledButton onClick={handleDelete}>{t('delete')}</StyledButton>

          {status === 'draft' && (
            <StyledButton variant="outlined" onClick={handleSave}>
              {t('save')}
            </StyledButton>
          )}

          <StyledButton variant="contained" onClick={handlePublish}>
            {status === 'draft' ? t('publish') : t('update')}
          </StyledButton>
        </>
      )}
    </Header>
  );
};

const StyledButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
}));
