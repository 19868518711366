import { styled } from '@mui/material';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';

type IconProps = {
  size: Size;
  symbol: string;
  className?: string;
  fill?: 0 | 1;
  wght?: 100 | 200 | 300 | 400 | 500 | 600 | 700;
};

const sizes: Record<Size, number> = {
  xs: 12,
  sm: 20,
  md: 24,
  lg: 32,
  xlg: 40,
  xxlg: 90,
};

export const Icon = ({
  className,
  fill = 0,
  size,
  symbol,
  wght = 300,
}: IconProps) => (
  <StyledIconContainer
    className={className}
    style={{
      fontVariationSettings: `"FILL" ${fill}, "wght" ${wght}, "GRAD" 0, "opsz" 48`,
    }}
  >
    <StyledIcon style={{ fontSize: `${sizes[size]}px` }}>{symbol}</StyledIcon>
  </StyledIconContainer>
);

const StyledIconContainer = styled('div')({
  lineHeight: 0,
});

const StyledIcon = styled('span')({
  color: 'currentColor',
  direction: 'ltr',
  display: 'inline-block',
  fontFamily: 'Material Symbols Outlined',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: 1,
  textTransform: 'none',
  wordWrap: 'normal',
  whiteSpace: 'nowrap',
});
