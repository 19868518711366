import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode';
import { addClassNamesToElement } from '@lexical/utils';
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical';

import { VideoComponent } from '@/admin/components/editor/editorNodes';

const convertVideoElement = (domNode: Node): null | DOMConversionOutput => {
  if (domNode instanceof HTMLIFrameElement) {
    const {
      src,
      style: { textAlign },
    } = domNode;

    const node = $createVideoNode(src);
    node.setFormat(textAlign as ElementFormatType);
    return { node };
  }

  return null;
};

export type SerializedVideoNode = Spread<
  {
    videoURL: string;
    type: 'video';
    version: 1;
  },
  SerializedDecoratorBlockNode
>;

export class VideoNode extends DecoratorBlockNode {
  __url: string;

  constructor(url: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__url = url;
  }

  static getType(): string {
    return 'video';
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(node.__url, node.__format, node.__key);
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    const node = $createVideoNode(serializedNode.videoURL);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedVideoNode {
    return {
      ...super.exportJSON(),
      type: 'video',
      version: 1,
      videoURL: this.__url,
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    addClassNamesToElement(element, 'embedBlock embedBlockFocus');

    const iframe: HTMLIFrameElement = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('src', this.__url);
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    );
    iframe.setAttribute('allowFullScreen', 'true');
    iframe.setAttribute('title', 'Embed video');
    iframe.setAttribute('style', `border: none; text-align: ${this.__format};`);
    element.appendChild(iframe);

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      iframe: () => ({
        conversion: convertVideoElement,
        priority: 0,
      }),
    };
  }

  updateDOM(): false {
    return false;
  }

  getURL(): string {
    return this.__url;
  }

  getTextContent(): string {
    return this.__url;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    };
    return (
      <VideoComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        videoURL={this.__url}
      />
    );
  }

  isInline(): false {
    return false;
  }
}

export const $createVideoNode = (videoURL: string): VideoNode => {
  return new VideoNode(videoURL);
};

export const $isVideoNode = (
  node: VideoNode | LexicalNode | null | undefined
): node is VideoNode => {
  return node instanceof VideoNode;
};
