import { createContext, useContext, useState, ReactNode } from 'react';

import {
  extractImgSrcFromDom,
  parseDomFromString,
} from '@/admin/utils/helpers';
import { deleteImageApi } from '@/admin/utils/helpers-api';

const initialContext: {
  addImageSrc: (src: string) => void;
  addInitialImageSrc: (src: string) => void;
  getImagesFromDom: (htmlString: string, imageURL?: string) => string[];
  removeAddedImageSrc: (token: string) => void;
  removeAllImageSrc: (token: string, images?: string[]) => void;
  removeUnusedImageSrc: (token: string, usedImages: string[]) => void;
} = {
  addImageSrc: () => {},
  addInitialImageSrc: () => {},
  getImagesFromDom: () => [],
  removeAddedImageSrc: () => {},
  removeAllImageSrc: () => {},
  removeUnusedImageSrc: () => {},
};

const Context = createContext(initialContext);

export const StorageBlobCleanProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [initialImages, setInitialImages] = useState<string[]>([]);
  const [addedImages, setAddedImages] = useState<string[]>([]);

  const addImageSrc = (newImageSrc: string) => {
    setAddedImages((prev) => [...prev, newImageSrc]);
  };

  const addInitialImageSrc = (initialImageSrc: string) => {
    setInitialImages((prev) => [...prev, initialImageSrc]);
  };

  const getImagesFromDom = (htmlString: string, imageURL?: string) => {
    const dom = parseDomFromString(htmlString);
    const images = extractImgSrcFromDom(dom);
    imageURL && images.push(imageURL);

    return images;
  };

  const removeStorageBlobs = (images: string[], token: string) => {
    images.forEach(async (img) => {
      const fileName = img.split('brokerAdmin/').at(-1) as string;
      await deleteImageApi(fileName, token);
    });
  };

  const removeAddedImageSrc = (token: string) => {
    if (addedImages.length) {
      removeStorageBlobs(addedImages, token);
    }
  };

  const removeAllImageSrc = (token: string, images?: string[]) => {
    const allImages = [...initialImages, ...addedImages];

    if (allImages.length) {
      removeStorageBlobs(allImages, token);
    } else if (images?.length) {
      removeStorageBlobs(images, token);
    }
  };

  const removeUnusedImageSrc = (token: string, usedImages: string[]) => {
    const allImages = [...initialImages, ...addedImages];

    const removedImages = allImages.filter((img) => !usedImages.includes(img));

    if (removedImages.length) {
      removeStorageBlobs(removedImages, token);
    }
  };

  return (
    <Context.Provider
      value={{
        addImageSrc,
        addInitialImageSrc,
        getImagesFromDom,
        removeAddedImageSrc,
        removeAllImageSrc,
        removeUnusedImageSrc,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStorageBlobCleanContext = () => {
  const {
    addImageSrc,
    addInitialImageSrc,
    getImagesFromDom,
    removeAddedImageSrc,
    removeAllImageSrc,
    removeUnusedImageSrc,
  } = useContext(Context);

  return {
    addImageSrc,
    addInitialImageSrc,
    getImagesFromDom,
    removeAddedImageSrc,
    removeAllImageSrc,
    removeUnusedImageSrc,
  };
};
