// import type { LexicalEditor } from 'lexical';
// import { $generateHtmlFromNodes } from '@lexical/html';
import { $isLinkNode } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import { Stack, styled } from '@mui/material';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import {
  ImageInsertModal,
  ToolbarAlign,
  ToolbarBlockFormat,
  ToolbarHistory,
  ToolbarList,
  ToolbarMedia,
  ToolbarTextFormat,
  ToolbarTable,
  TableInsertModal,
} from '@/admin/components/editor/editorPlugins';
import { BLOCKTYPE_TO_BLOCKNAME } from '@/admin/consts/editor';
import { getSelectedNode } from '@/admin/utils/helpers-editor';

type ToolbarPluginProps = {
  className: string;
};

export const ToolbarPlugin = ({ className }: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof BLOCKTYPE_TO_BLOCKNAME>('paragraph');

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isBulletedList, setIsBulletedList] = useState(false);
  const [isNumberedList, setIsNumberedList] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });
      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      // Update lists and block type
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();

          if (type === 'bullet') {
            setIsBulletedList(true);
            setIsNumberedList(false);
            setBlockType('paragraph');
          } else if (type === 'number') {
            setIsNumberedList(true);
            setIsBulletedList(false);
            setBlockType('paragraph');
          }
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          if (type in BLOCKTYPE_TO_BLOCKNAME) {
            setBlockType(type as keyof typeof BLOCKTYPE_TO_BLOCKNAME);
          }

          setIsBulletedList(false);
          setIsNumberedList(false);
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [activeEditor, editor, updateToolbar]);

  const handleShowImageModal = () => {
    setShowImageModal(!showImageModal);
  };

  const handleShowTableModal = () => {
    setShowTableModal(!showTableModal);
  };

  return (
    <StyledContainer className={`toolbar ${className}`}>
      <ToolbarHistory
        editor={activeEditor}
        canUndo={canUndo}
        canRedo={canRedo}
      />

      <StyledStack direction="row">
        <StyledDivider />
        <ToolbarBlockFormat blockType={blockType} editor={editor} />
      </StyledStack>

      <StyledStack direction="row">
        <StyledDivider />

        <ToolbarTextFormat
          editor={activeEditor}
          isBold={isBold}
          isItalic={isItalic}
          isLink={isLink}
          isUnderline={isUnderline}
        />
      </StyledStack>

      <StyledStack direction="row">
        <StyledDivider />
        <ToolbarAlign editor={activeEditor} />
      </StyledStack>

      <StyledStack direction="row">
        <StyledDivider />

        <ToolbarList
          editor={activeEditor}
          isBulletedList={isBulletedList}
          isNumberedList={isNumberedList}
        />
      </StyledStack>

      <StyledStack direction="row">
        <StyledDivider />
        <ToolbarMedia
          editor={activeEditor}
          handleShowImageModal={handleShowImageModal}
          // handleShowVideoModal={handleShowVideoModal}
        />

        <ToolbarTable handleShowTableModal={handleShowTableModal} />

        <ImageInsertModal
          editor={activeEditor}
          open={showImageModal}
          onClose={() => {
            setShowImageModal(false);
          }}
        />

        <TableInsertModal
          editor={activeEditor}
          open={showTableModal}
          onClose={() => {
            setShowTableModal(false);
          }}
        />
      </StyledStack>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  padding: theme.spacing(0.5, 1),
  verticalAlign: 'middle',

  '&.error': {
    border: `1px solid ${theme.palette.error.main}`,
    borderBottom: 'none',
  },
}));

const StyledDivider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: theme.spacing(0, 0.5),
  opacity: '75%',
  width: 1,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
}));
